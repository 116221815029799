import { MDSSystemAlert, TextOnly } from '@midwest/web/notifications';
import { useProdBannerFlag } from '@thrivent-web/servicing/feature-management';
import styled from 'styled-components';

export const StyledSystemAlert = styled(MDSSystemAlert)`
  z-index: inherit;
  grid-column: 2/3;
  box-shadow: none;
  background-color: inherit;
  padding: 1.5rem 0;
`;

const BannerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(0, 75rem) 1fr;
  background-color: ${(p) => p.theme.midwestColors.sentimentWarningMedium};
  box-shadow: 0 0.1875rem 0.5rem 0 rgba(21, 21, 21, 0.15);
  padding: 0 1rem;
`;

export const ProductionIssueBanner = () => {
  const { data: productionIssueMessage } = useProdBannerFlag();
  const isProductionIssueBannerEnabled = !!productionIssueMessage;

  return isProductionIssueBannerEnabled ? (
    <BannerContainer>
      <StyledSystemAlert type="warning">
        <TextOnly text={productionIssueMessage} />
      </StyledSystemAlert>
    </BannerContainer>
  ) : null;
};
