import {
  AdvisorBuilder,
  AllBillsStatusIndicator,
  NavMenuLink,
  NavMenuTriggerWithCaret,
} from '../shared/components';
import { NavMenuItemEnum } from '../shared/enums';
import {
  ContentSection,
  ContentSectionLabel,
  NavMenuContent,
  NavMenuContentSectionWithSeparator,
  NavMenuItem,
  SectionDivider,
} from '../shared/styles';
import { NavMenuProps } from '../shared/types';
import { useFinancialAdvisor } from '@servicing/data-queries';
import {
  COMMUNICATION_PREFERENCES_ROUTE,
  PAYMENT_CENTER_ADD_BANK_ACCOUNT_ROUTE,
  PAYMENT_CENTER_HISTORY_ROUTE,
  PAYMENT_CENTER_ROUTE,
} from '@servicing/util-routes';
import { useAuth } from '@thrivent-web/authentication';
import styled from 'styled-components';

const Advisor = () => {
  const { advisor } = useFinancialAdvisor();
  return advisor ? <AdvisorBuilder advisor={advisor} /> : null;
};

const NavMenuBillsLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const BillingsAndPaymentsMenu = ({
  currentMenuItem = '',
}: NavMenuProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <NavMenuItem
      value={NavMenuItemEnum.BillingsAndPaymentsMenu}
      open={currentMenuItem === NavMenuItemEnum.BillingsAndPaymentsMenu}
    >
      <NavMenuTriggerWithCaret>Billing &amp; payments</NavMenuTriggerWithCaret>
      <NavMenuContent>
        <ContentSection>
          <ContentSectionLabel>Billing</ContentSectionLabel>
          <>
            <NavMenuBillsLinkContainer>
              <NavMenuLink
                name="Payments"
                url={`${PAYMENT_CENTER_ROUTE}/overview`}
                target="_self"
              />
              {<AllBillsStatusIndicator />}
            </NavMenuBillsLinkContainer>
            <NavMenuLink
              name="History"
              url={PAYMENT_CENTER_HISTORY_ROUTE}
              target="_self"
            />
            <NavMenuLink
              name="Bank Accounts"
              url={PAYMENT_CENTER_ADD_BANK_ACCOUNT_ROUTE}
              target="_self"
            />
          </>
        </ContentSection>
        <SectionDivider />
        <NavMenuContentSectionWithSeparator>
          <ContentSectionLabel>Contact Information</ContentSectionLabel>
          <NavMenuLink
            name="Communication Preferences"
            url={COMMUNICATION_PREFERENCES_ROUTE}
            target="_self"
          />
        </NavMenuContentSectionWithSeparator>
        {isAuthenticated && (
          <>
            <SectionDivider />
            <NavMenuContentSectionWithSeparator>
              <Advisor />
            </NavMenuContentSectionWithSeparator>
            <SectionDivider />
          </>
        )}
      </NavMenuContent>
    </NavMenuItem>
  );
};
