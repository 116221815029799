import {
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogDescriptionText,
  AlertDialogHeaderAndDescription,
  AlertDialogOverlay,
  AlertDialogRoot,
  AlertDialogText,
  AlertDialogTitle,
  ButtonLayout,
  HeaderLayout,
  HelpCircleIcon,
  StyledCustomIcon,
  WarningStopIcon,
} from './AlertModalStyles';
import { MDSAlertModalProps } from './types';
import { MDSButton } from '@midwest/web/button';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { useState } from 'react';

/**
 * AlertModals are overlays that require users to take an action before they can continue. They are disruptive, so use them sparingly.
 * This was implemented using the [Radix AlertDialog Component](https://radix-ui.com/primitives/docs/components/AlertDialog), see their documentation for additional info.
 * There is also a `useMDSAlertModal` hook that can be used to open/close the AlertModal programmatically.
 */
export const MDSAlertModal = ({
  title,
  content,
  onClickPrimary,
  onClickSecondary,
  alertDialogTrigger,
  type,
  primaryButtonText,
  secondaryButtonText,
  isOpen = false,
  customIcon,
  showLoadingPrimary = false,
  showLoadingSecondary = false,
  showSecondaryButton,
  zIndex = 500,
  role,
}: MDSAlertModalProps) => {
  const [open, setOpen] = useState(isOpen);
  const [isLoadingPrimary, setIsLoadingPrimary] = useState(false);
  const [isLoadingSecondary, setIsLoadingSecondary] = useState(false);

  const handleClick = async (variant: string) => {
    if (variant === 'primary') {
      setIsLoadingPrimary(true);
      onClickPrimary && (await onClickPrimary());
      setIsLoadingPrimary(false);
    } else {
      setIsLoadingSecondary(true);
      onClickSecondary && (await onClickSecondary());
      setIsLoadingSecondary(false);
    }

    setOpen(false);
  };

  const preventDefault = (
    event: { preventDefault: () => void } | KeyboardEvent
  ) => {
    event.preventDefault();
  };

  return (
    <AlertDialogRoot open={open} onOpenChange={setOpen}>
      {!!alertDialogTrigger && (
        <AlertDialog.Trigger asChild>{alertDialogTrigger}</AlertDialog.Trigger>
      )}
      <AlertDialog.Portal>
        <AlertDialogOverlay />
        <AlertDialogContent onEscapeKeyDown={preventDefault} $zIndex={zIndex}>
          <AlertDialogText>
            <AlertDialogHeaderAndDescription>
              <HeaderLayout>
                {customIcon ? (
                  <StyledCustomIcon
                    as={customIcon.icon.type}
                    alt={customIcon.altText}
                    $type={type}
                  />
                ) : type === 'confirm' ? (
                  <HelpCircleIcon data-testid="help-circle" />
                ) : type === 'warn' ? (
                  <WarningStopIcon data-testid="warning-stop" />
                ) : null}
                <AlertDialog.Title>
                  <AlertDialogTitle>{title}</AlertDialogTitle>
                </AlertDialog.Title>
              </HeaderLayout>
              <AlertDialogDescription>
                {typeof content === 'string' ? (
                  <AlertDialogDescriptionText>
                    {content}
                  </AlertDialogDescriptionText>
                ) : (
                  content
                )}
              </AlertDialogDescription>
            </AlertDialogHeaderAndDescription>

            <ButtonLayout>
              <MDSButton
                variant={type === 'warn' ? 'destructive' : 'primary'}
                onClick={() => handleClick('primary')}
                data-testid="primary-button"
                loading={showLoadingPrimary && isLoadingPrimary}
              >
                {primaryButtonText}
              </MDSButton>
              {(showSecondaryButton ?? true) && (
                <MDSButton
                  weight="subtle"
                  onClick={() => handleClick('secondary')}
                  data-testid="secondary-button"
                  loading={showLoadingSecondary && isLoadingSecondary}
                >
                  {secondaryButtonText}
                </MDSButton>
              )}
            </ButtonLayout>
          </AlertDialogText>
        </AlertDialogContent>
      </AlertDialog.Portal>
    </AlertDialogRoot>
  );
};
