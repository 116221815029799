import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense, lazy, useEffect, useState } from 'react';

const ReactQueryDevtoolsProd = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({ default: d.ReactQueryDevtools })
  )
);

export const ReactQueryDevToolsLoader = () => {
  const [showDevTools, setShowDevTools] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).toggleDevtools = () => setShowDevTools((prev) => !prev);
    }
  }, []);

  return (
    <>
      <ReactQueryDevtools />
      {showDevTools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProd />
        </Suspense>
      )}
    </>
  );
};
