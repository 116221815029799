import {
  NavColumn,
  NavColumnHeader,
  NavList,
  NavListItem,
} from './shared/styles';
import { useFinancialAdvisor } from '@servicing/data-queries';
import { PreferencesBackPath } from '@servicing/molecules';
import { formatSecureEmail } from '@servicing/util-formatter';
import {
  COMMUNICATION_PREFERENCES_ROUTE,
  DASHBOARD_ROUTE,
  GENEROSITY_ROUTE,
  PAYMENT_CENTER_ADD_BANK_ACCOUNT_ROUTE,
  PAYMENT_CENTER_ROUTE,
  PROFILE_AND_SETTINGS_ROUTE,
} from '@servicing/util-routes';
import { useAuth } from '@thrivent-web/authentication';
import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useId } from 'react';

const FinancialAdvisorItem = () => {
  const { advisor } = useFinancialAdvisor();

  return (
    <NavListItem
      href={formatSecureEmail({ email: advisor?.email })}
      target="_blank"
      title="Secure messaging"
    />
  );
};

export const HelpfulLinks = () => {
  const { isAuthenticated } = useAuth();
  const helpfulLinksId = useId();

  const { asPath } = useRouter();
  const setPreferencesBackPath = useSetAtom(PreferencesBackPath);

  return (
    <NavColumn aria-labelledby={helpfulLinksId}>
      <NavColumnHeader id={helpfulLinksId}>Helpful links</NavColumnHeader>
      <NavList>
        <NavListItem
          href={`${PAYMENT_CENTER_ROUTE}/overview`}
          target="_self"
          title="Make a payment"
        />

        <NavListItem
          href={COMMUNICATION_PREFERENCES_ROUTE}
          target="_self"
          title="Go paperless"
          onClick={() => setPreferencesBackPath(asPath)}
        />
        <NavListItem
          href={GENEROSITY_ROUTE}
          target="_self"
          title="Direct Choice Dollars®"
        />
        <NavListItem
          href={PAYMENT_CENTER_ADD_BANK_ACCOUNT_ROUTE}
          target="_self"
          title="Add a bank account"
        />
        <NavListItem
          href={DASHBOARD_ROUTE}
          target="_self"
          title="Account Overview"
        />
        <NavListItem
          href={PROFILE_AND_SETTINGS_ROUTE}
          target="_self"
          title="Profile and settings"
        />
        {isAuthenticated && <FinancialAdvisorItem />}
      </NavList>
    </NavColumn>
  );
};
