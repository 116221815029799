const breakPoints = {
  mobile: { value: '375' },
  tablet: { value: '768' },
  desktop: { value: '1024' },
};

// Please do not create a `mobile = `@media ...` as you should always design mobile first 😊

export const tablet = `@media screen and (min-width: ${breakPoints['tablet'].value}px)`;
export const desktop = `@media screen and (min-width: ${breakPoints['desktop'].value}px)`;

// 375px
export const mobileBreakpoint = Number(breakPoints['mobile'].value);

// 768px
export const tabletBreakpoint = Number(breakPoints['tablet'].value);

// 1024px
export const desktopBreakpoint = Number(breakPoints['desktop'].value);

export const breakpoints = { tablet, desktop };
