import { getAuthorizationHeader } from './getAuthorizationHeader';
import { getConsumerIdFromAuthHeader } from './getConsumerIdFromAuthHeader';
import { getTestName } from './getTestName';
import { client } from './gqlClient';
import {
  isMissingAuthToken,
  isRequiredInteractionError,
  isUnauthorized,
} from './isMissingAuthToken';
import { logErrorValues } from './logErrorValues';
import { logVariables } from './logVariables';
import { sanitizeClientError } from './sanitizeClientError';
import { serviceName, serviceVersion } from './serviceIdentifier';
import { QueryFunctionContext } from '@tanstack/react-query';
import { logger } from '@thrivent-web/logging-utils';
import { secondsToMilliseconds } from 'date-fns';
import { ClientError, Variables } from 'graphql-request';
import { GraphQLError } from 'graphql/error';

const CLIENT_TIMEOUT = secondsToMilliseconds(90);
const SERVER_TIMEOUT = secondsToMilliseconds(25);

const TIMEOUT_MS =
  typeof window !== 'undefined' ? CLIENT_TIMEOUT : SERVER_TIMEOUT;

export type DataWithErrors<TData> = TData & {
  __errors?: readonly GraphQLError[];
};

export const fetchData = <TData, TVariables extends Variables>(
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers']
): ((context?: QueryFunctionContext) => Promise<DataWithErrors<TData>>) => {
  //@ts-expect-error - working around typescript typing
  return async (context) => {
    // takes "query Dashboard($consumerId: String!) { feed(consumerId: $consumerId) { ...."
    // and converts it to
    // "query Dashboard($consumerId: String!)"
    const queryName = query.split('{')[0].trim();

    const abortController = new AbortController();
    const abortTimeout = setTimeout(() => abortController.abort(), TIMEOUT_MS);

    let consumerId = '';

    try {
      const authorizationHeader = await getAuthorizationHeader(context);

      if (!authorizationHeader) {
        throw new Error('Authorization header has no value');
      }

      const consumerIdFromAuthHeader =
        getConsumerIdFromAuthHeader(authorizationHeader);

      // saving consumerId outside the scope of the try/catch for error logging in the catch block
      consumerId = consumerIdFromAuthHeader;

      const additionalHeaders: Record<string, string> = {};

      if (process.env['NEXT_PUBLIC_API_MOCKING'] === 'enabled') {
        const testName = getTestName(context);

        if (testName) {
          additionalHeaders['x-req-msw-test-id'] = testName;
        }
      }

      if (
        variables?.['consumerId'] &&
        variables?.['consumerId'] !== consumerIdFromAuthHeader
      ) {
        logger.warn(
          // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
          `ConsumerId mismatch. Variables:"${variables?.['consumerId']}". Auth:"${consumerIdFromAuthHeader}"`
        );

        // @ts-expect-error - working around a really weird edge case
        variables['consumerId'] = consumerIdFromAuthHeader;
      }

      const signal = abortController.signal;

      // X-REQ-TF-DEVICE header needed for retrieval ofCareForward data. Ref: https://jira.digital.thrivent.com/browse/EISENG-2962
      const requestHeaders: HeadersInit = {
        'apollographql-client-name': serviceName,
        'apollographql-client-version': serviceVersion,
        authorization: authorizationHeader ?? '',
        'X-REQ-TF-DEVICE': 'Web/NA/1.2.1/NA',
        'X-REQ-CONSUMER-ID': consumerIdFromAuthHeader,
        'X-REQ-TIMEOUT': `${TIMEOUT_MS * 0.75}`,
        'X-CLIENT-ID': 'Digital-Digital Servicing Web',
        ...(context?.meta?.['additionalHeaders'] ?? {}),
        ...additionalHeaders,
        ...headers,
      };

      const data = await client.request<TData>({
        document: query,
        variables,
        signal,
        requestHeaders,
      });

      logger.info(`servicing/fetcher:fetchData - success`, {
        query: queryName,
        variables: logVariables(context, variables),
        consumerId,
      });

      return data;
    } catch (error) {
      const sanitizedError = sanitizeClientError(error, queryName);
      if (
        error instanceof Error &&
        error.message.startsWith('Network request failed')
      ) {
        logger.warn('fetcher:fetchData - network request failed');
        throw error;
      } else if (abortController.signal.aborted) {
        logger.error(
          'fetcher:fetchData - request was aborted',
          sanitizedError,
          logErrorValues(error)
        );
      } else if (
        isMissingAuthToken(error) ||
        isUnauthorized(error) ||
        isRequiredInteractionError(error)
      ) {
        // I need to throw the error so the fetch stops,
        // but I don't need to log anything
        throw error;
      }

      /**
       * Prevent the query from failing.Return this partial response with
       * appended __errors array. To enable,
       * send {meta: {enablePartial: true}} in react-query options.
       *
       * Example:
       * return useDashboardQuery(
       *     { consumerId },
       *     {
       *       meta: {
       *         enablePartial: true,
       *       },
       *     }
       *   );
       * Note: by not throwing the error, the error prop in your useWhatever hook will not be set
       * https://github.com/dotansimha/graphql-code-generator/issues/6013
       */
      const partialSuccessEnabled = (context?.meta ?? {})['enablePartial'];

      if (error instanceof ClientError && partialSuccessEnabled) {
        const data = error.response.data ?? {};
        const partialSuccesses = Object.values(data).filter(
          (x) => x !== null
        ).length;

        logger.error(
          'fetcher:fetchData - partially successful response returned',
          sanitizedError,
          {
            query: queryName,
            variables: logVariables(context, variables),
            consumerId,
            partialSuccesses,
            ...logErrorValues(error),
          }
        );

        return {
          ...data,
          __errors: error.response.errors,
        };
      }

      // We did not handle the error, so log it before rethrowing
      logger.error('fetcher:fetchData - fetch data error', sanitizedError, {
        query: queryName,
        variables: logVariables(context, variables),
        consumerId,
        ...logErrorValues(error),
      });

      throw sanitizedError;
    } finally {
      clearTimeout(abortTimeout);
    }
  };
};
