import { NavMenuItemEnum } from '../shared/enums';
import { NavMenuItem } from '../shared/styles';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const THRIVENT_CLAIMS_URL = 'https://www.thrivent.com/claims';

const StyledClaimsLink = styled.a`
  display: flex;
  justify-content: left;
  width: 100%;
  font-weight: 400;
  color: ${(p) => p.theme.midwestColors.textPrimary};

  &:hover,
  &:focus {
    text-decoration: none;
  }

  ${tablet} {
    align-items: center;
    height: 100%;
  }
`;

export const ClaimsLink = () => {
  return (
    <NavMenuItem data-dd-action-name="Claims" value={NavMenuItemEnum.Claims}>
      <StyledClaimsLink href={THRIVENT_CLAIMS_URL} target={'_blank'}>
        Claims
      </StyledClaimsLink>
    </NavMenuItem>
  );
};
