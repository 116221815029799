import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// eslint-disable-next-line import/no-named-as-default
import { ReactNode } from 'react';

type Props = { children: ReactNode; queryClient: QueryClient };
const QueryClientProviderServicing = ({ children, queryClient }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryClientProviderServicing;
