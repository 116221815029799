import {
  deleteEphemeralRedirectCookie,
  deletePostLoginReturnToCookie,
  deleteThriventLoggedInCookie,
  getPostLoginReturnToCookie,
  setThriventLoggedInCookie,
} from './cookieHelpers';
import type { UserManager } from './imports';
import { Router } from './router';
import { logger } from '@thrivent-web/logging-utils';
import { FC, useCallback, useEffect, useRef } from 'react';

const isInIFrame =
  typeof window !== 'undefined' &&
  window?.location !== window?.parent?.location;

export const CallbackHandler: FC<{
  userManager: UserManager;
  defaultedLoginPageRoute: string;
  router: Router;
  onSigninCallbackError?: (error: Error) => void;
}> = ({
  userManager,
  defaultedLoginPageRoute,
  router,
  onSigninCallbackError,
}) => {
  const didInitialize = useRef(false);

  /**
   * Redirection after failed or successful signin allows for deep linking as
   * well as handling of invalid bookmarks. This functionality is disabled when
   * the app is running in an iframe. The iframe is used solely for checking
   * authentication state and should not load any application specific pages.
   */
  const push = useCallback(
    (url: string) => {
      if (isInIFrame) return;
      router.push(url);
    },
    [router]
  );

  useEffect(() => {
    if (!userManager || didInitialize.current) {
      return;
    }
    didInitialize.current = true;

    const queryString = window.location.search;
    const urlSearchParams = new URLSearchParams(queryString);
    const code = urlSearchParams.get('code');
    const state = urlSearchParams.get('state');

    if (!!code && !!state) {
      userManager
        .signinCallback(window.location.href)
        .then((user) => {
          if (!user) {
            deleteThriventLoggedInCookie();
            return;
          }

          // ephemeral cookie set in useAuth.tsx prior to signinRedirect call
          deleteEphemeralRedirectCookie();
          setThriventLoggedInCookie();

          const postLoginReturnTo = getPostLoginReturnToCookie();
          deletePostLoginReturnToCookie();
          if (
            typeof postLoginReturnTo === 'string' &&
            postLoginReturnTo !== defaultedLoginPageRoute
          ) {
            push(decodeURIComponent(postLoginReturnTo));
          } else {
            push('/');
          }
        })
        .catch((error) => {
          logger.error('Authentication Library | CallbackHandler', error);
          if (onSigninCallbackError) {
            onSigninCallbackError(
              error instanceof Error
                ? error
                : new Error('Unknown error occurred during signin callback.')
            );
          } else {
            push('/');
          }
        });
    }
  }, [userManager, defaultedLoginPageRoute, push, onSigninCallbackError]);
  return null;
};
