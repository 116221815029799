import * as tokens from './generatedTokens';

export type SpacingTypes = {
  xxxsm: string;
  xxsm: string;
  xsm: string;
  sm: string;
  md: string;
  lg: string;
  xlg: string;
  xxlg: string;
  xxxlg: string;
  buttonPadding: string;
  containerPaddingSm: string;
  containerPaddingMd: string;
  containerPaddingLg: string;
};

export const spacing: SpacingTypes = {
  xxxsm: `${tokens.baseSpacingXxxsm}px`,
  xxsm: `${tokens.baseSpacingXxsm}px`,
  xsm: `${tokens.baseSpacingXsm}px`,
  sm: `${tokens.baseSpacingSm}px`,
  md: `${tokens.baseSpacingMd}px`,
  lg: `${tokens.baseSpacingLg}px`,
  xlg: `${tokens.baseSpacingXlg}px`,
  xxlg: `${tokens.baseSpacingXxlg}px`,
  xxxlg: `${tokens.baseSpacingXxxlg}px`,
  buttonPadding: `${tokens.componentButtonPaddingDefault}px`,
  containerPaddingSm: `${tokens.componentContainerPaddingSm}px`,
  containerPaddingMd: `${tokens.componentContainerPaddingMd}px`,
  containerPaddingLg: `${tokens.componentContainerPaddingLg}px`,
} as const;

export const spacingValues = {
  xxxsm: tokens.baseSpacingXxxsm,
  xxsm: tokens.baseSpacingXxsm,
  xsm: tokens.baseSpacingXsm,
  sm: tokens.baseSpacingSm,
  md: tokens.baseSpacingMd,
  lg: tokens.baseSpacingLg,
  xlg: tokens.baseSpacingXlg,
  xxlg: tokens.baseSpacingXxlg,
  xxxlg: tokens.baseSpacingXxxlg,
  buttonPadding: tokens.componentButtonPaddingDefault,
  containerPaddingSm: tokens.componentContainerPaddingSm,
  containerPaddingMd: tokens.componentContainerPaddingMd,
  containerPaddingLg: tokens.componentContainerPaddingLg,
} as const;
