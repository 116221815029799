import {
  ButtonContent,
  DestructiveButtonStyle,
  PrimaryButtonStyle,
  SecondaryButtonStyle,
} from '../Button.styles';
import { Loader } from '../Loader';
import { renderButtonIcon, renderIconsAndText } from '../buttonHelpers';
import { ButtonProps, ButtonVariationProps } from '../types';
import { forwardRef } from 'react';
import styled from 'styled-components';

const PrimaryButton = styled.button<ButtonProps>`
  ${PrimaryButtonStyle}
`;

const SecondaryButton = styled.button<ButtonProps>`
  ${SecondaryButtonStyle}
`;

const DestructiveButton = styled.button<ButtonProps>`
  ${DestructiveButtonStyle}
`;

const buttonVariations = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  destructive: DestructiveButton,
};

/**
 * Buttons are used primarily for actions, such as “Add”, “Apply”, “Cancel”, or “Create”.
 */
export const MDSButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'primary',
      size = 'default',
      weight = 'strong',
      onClick,
      disabled,
      loading = false,
      type = 'button',
      fullWidth,
      iconOnly,
      iconAlignment = 'left',
      Icon,
      darkBg = false,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    const ButtonVariation: React.ComponentType<ButtonVariationProps> =
      buttonVariations[variant];

    return (
      <ButtonVariation
        disabled={disabled}
        size={size}
        weight={weight}
        ref={ref}
        iconOnly={iconOnly}
        fullWidth={fullWidth}
        onClick={onClick}
        aria-disabled={disabled}
        type={type}
        darkBg={darkBg}
        {...rest}
      >
        <ButtonContent>
          {iconOnly && Icon && renderButtonIcon(Icon, true)}
          {!iconOnly && renderIconsAndText(iconAlignment, children, Icon)}
        </ButtonContent>
        {loading && !!Loader && <Loader variant={variant} weight={weight} />}
      </ButtonVariation>
    );
  }
);

MDSButton.displayName = 'MDSButton';
