import Script from 'next/script';

export const BlueConicTracking = () => {
  return process.env['NEXT_PUBLIC_BLUECONIC'] === 'enabled' ? (
    <Script
      src={process.env['NEXT_PUBLIC_BLUECONIC_URL']}
      className="optanon-category-3"
      async
    />
  ) : null;
};
