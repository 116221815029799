import { useLicensePlate } from './useLicensePlate';
import { useEffect } from 'react';

export const DatadogLicensePlate = () => {
  const licensePlate = useLicensePlate();

  useEffect(() => {
    import('@datadog/browser-rum').then(({ datadogRum }) => {
      datadogRum.setGlobalContextProperty('licensePlate', licensePlate);
    });
  }, [licensePlate]);
  return null;
};
