import { LDClient as LDClientSSR } from '@launchdarkly/node-server-sdk';
import { GetServerSidePropsContext as SSRContext } from 'next/types';

export type ContextMetaBase = {
  getConsumerId?: () => Promise<string | undefined> | string | undefined;
  getAccessToken: () => Promise<string | undefined> | string | undefined;
  isLoggedIn?: () => boolean;
  getTestName: () => string;
  additionalHeaders?: Record<string, string>;
};

export type ContextMetaClient = ContextMetaBase & {
  launchDarklyClientBrowser: boolean;
};

export type ContextMetaServer = ContextMetaBase & {
  ssrContext: SSRContext;
};

export type ContextMetaServerLD = ContextMetaServer & {
  launchDarklyClientSSR?: LDClientSSR;
};

export type ContextMeta =
  | ContextMetaClient
  | ContextMetaServer
  | ContextMetaServerLD;

/**
 * Checks if has getAccessToken
 * @param contextMeta
 */
export const isContextGetAccessToken = (
  contextMeta: unknown
): contextMeta is ContextMeta =>
  (contextMeta as ContextMeta)?.getAccessToken !== undefined;

export const isContextGetTestName = (
  contextMeta: unknown
): contextMeta is ContextMeta =>
  (contextMeta as ContextMeta)?.getTestName !== undefined;

/**
 * Checks if native app is logged in
 * @param contextMeta
 */
export const isContextLoggedIn = (
  contextMeta: unknown
): contextMeta is ContextMeta =>
  (contextMeta as ContextMeta)?.isLoggedIn !== undefined;

/**
 * Checks if context is running client side
 * @param contextMeta
 */
export const isContextMetaClient = (
  contextMeta: unknown
): contextMeta is ContextMetaClient =>
  (contextMeta as ContextMetaClient)?.launchDarklyClientBrowser === true;

/**
 * Checks if context is running server side
 * @param contextMeta
 */
export const isContextMetaServer = (
  contextMeta: unknown
): contextMeta is ContextMetaServer =>
  (contextMeta as ContextMetaServer)?.ssrContext !== undefined;

export const isContextMetaServerLaunchDarkly = (
  contextMeta: unknown
): contextMeta is ContextMetaServerLD =>
  (contextMeta as ContextMetaServerLD)?.launchDarklyClientSSR !== undefined;
