import { useConsumerId } from '../consumerId/useConsumerId';
import { AllBillsQuery, useAllBillsQuery } from './allBills.generated';
import { UseQueryOptions } from '@tanstack/react-query';

export const useAllBills = (
  options?: Partial<UseQueryOptions<AllBillsQuery>>
) => {
  const consumerId = useConsumerId();
  return useAllBillsQuery(
    { consumerId },
    { enabled: !!consumerId, ...options, meta: options?.meta ?? {} }
  );
};
