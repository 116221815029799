import { FontLoader } from '@midwest/web/theme';
import { primaryFont } from '@thrivent/midwest-shared';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyleSheet = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  :root {
    --sans: 1em/1.6 ${primaryFont};
    --mono: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, /*  spellchecker: disable-line */
      'Courier New', monospace;
    --m1: 8px;
    --rc: 8px;
  }

  html {
    font-family: ${primaryFont};
    line-height: 1.5;
    tab-size: 4;
    scroll-behavior: smooth;
  }

  /* General settings */

  body {
    margin: 0;
    font: var(--sans);
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    background-color: ${(p) => p.theme.colors.light_weak};
    color: ${(p) => p.theme.colors.text_primary};
    font-family: inherit;
    line-height: inherit;
  }

  img,
  iframe {
    border: none;
    max-width: 100%;
  }

  a {
    font-family: ${primaryFont};
    font-size: 1rem;
    color: ${(p) => p.theme.colors.text_link_active};
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  pre {
    font: 1em/1.6 var(--mono);
    background: ${(p) => p.theme.colors.light_strong};
    padding: 1em;
    overflow: auto;
  }

  code {
    font: 1em/1.6 var(--mono);
  }

  blockquote {
    border-left: 5px solid ${(p) => p.theme.colors.light_strong};
    padding: 1em 1.5em;
    margin: 0;
  }

  hr {
    border: 0;
    border-bottom: 1px solid ${(p) => p.theme.colors.dark_heavy};
  }

  /* Headlines */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;  }

  input,
  select,
  textarea {
    font-size: 1em;
    color: ${(p) => p.theme.colors.text_primary};
    background: ${(p) => p.theme.colors.light_weak};
    border: 0;
    padding: 0.6em;
  }

  input[type='submit'],
  input[type='reset'],
  input[type='button'] {
    font-size: 1.2em;
    font-weight: 900;
    display: inline-block;
    color: ${(p) => p.theme.colors.text_primary_dark_bg};
    background: ${(p) => p.theme.colors.brand_primary_medium};
    border: 0;
    margin: 4px;
    padding: 1em;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;

    @media screen and (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }


  p {
    margin: 0;
  }
`;

/**
 * @deprecated Use GlobalStyle from Midwest-Web instead, e.g. import { GlobalStyle } from '@thrivent/midwest-web';.
 *
 * ```tsx
 *  import { GlobalStyle } from '@thrivent/midwest-web';
 * ```
 */
export const GlobalStyle = () => (
  <>
    <FontLoader />
    <GlobalStyleSheet />
  </>
);
