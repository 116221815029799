import { CorporateCenter } from './CorporateCenter';
import { FollowUs } from './FollowUs';
import { GetToKnowUs } from './GetToKnowUs';
import { HelpfulLinks } from './HelpfulLinks';
import { Legal } from './Legal';
import { LicensePlate } from './LicensePlate';
import { OperationsCenter } from './OperationsCenter';
import { Resources } from './Resources';
import { Logo } from './shared/styles';
import { DASHBOARD_ROUTE } from '@servicing/util-routes';
import { BodyText, Divider, DividerVariants } from '@thrivent-web/ui/atoms';
import { BrokerCheck, FullLogo } from '@thrivent-web/ui/icons';
import { desktop, tablet } from '@thrivent/midwest-shared';
import NextLink from 'next/link';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  grid-area: footer;
  background: ${(p) => p.theme.colors.dark_heavy};
  color: ${(p) => p.theme.colors.text_primary_dark_bg};
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr minmax(0, 75rem) 1fr;
  padding: 0 1rem;

  ${tablet} {
    padding: 0 2rem;
  }
`;

const FooterContent = styled.div`
  grid-column: 2/3;
  padding-top: 4.625rem;

  ${tablet} {
    padding-top: 3.75rem;
  }
`;

const FooterNav = styled.nav`
  display: grid;
  row-gap: 3rem;
  margin-top: 2rem;

  ${tablet} {
    column-gap: 1.5rem;
  }

  ${desktop} {
    column-gap: 5rem;
  }
`;

const StyledBlueLink = styled.a`
  color: ${(p) => p.theme.midwestColors.textLinkDarkBG};
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  text-decoration: underline;
`;

const BlueNavLink = ({
  href,
  children,
  target,
  ariaLabel,
}: {
  href: string;
  children: React.ReactNode;
  target?: string;
  ariaLabel?: string;
}) => (
  <NextLink href={href} passHref legacyBehavior>
    <StyledBlueLink aria-label={ariaLabel} target={target}>
      {children}
    </StyledBlueLink>
  </NextLink>
);

const TermsSection = styled.section`
  display: grid;
  column-gap: 1.5rem;
  grid-column: 2/3;
  grid-template-areas: 'item item';
  margin: 2.5rem 0;

  ${tablet} {
    margin: 2.5rem 0 4.125rem;
  }
`;

const TermsContent = styled.div`
  display: grid;
  grid-gap: 2.75rem;

  ${desktop} {
    grid-gap: 1rem;
    padding-bottom: 4rem;
  }
`;

const BrokerCheckContainer = styled.div`
  position: relative;
  display: flex;
  align-self: flex-end;
  width: 12.5rem;
  height: 3rem;
  margin: 1rem 0;
`;

const StyledDivider = styled(Divider)`
  margin: 4.5rem 0 0rem;

  ${tablet} {
    margin: 5rem 0 0rem;
  }
`;

export const FINRA_URL = 'https://www.finra.org/';
export const SPIC_URL = 'https://www.sipc.org/';
export const BROKER_CHECK_URL = 'https://brokercheck.finra.org/';
export const THRIVENT_DISCLOSURES_URL = 'https://www.thrivent.com/disclosures';

export const Footer = () => {
  return (
    <StyledFooter data-test="footer" id="footer">
      <FooterContent>
        <Logo href={DASHBOARD_ROUTE} aria-label="dashboard">
          <FullLogo width={140} height={30} role="img" aria-hidden />
        </Logo>

        <FooterNav>
          <HelpfulLinks />
          <Resources />
          <GetToKnowUs />
          <Legal />
          <FollowUs />
          <OperationsCenter />
          <CorporateCenter />
        </FooterNav>

        <StyledDivider variant={DividerVariants.secondary} />
      </FooterContent>
      <TermsSection>
        <TermsContent>
          <BodyText.RegSm>©2024 Thrivent. All rights reserved.</BodyText.RegSm>
          <BodyText.RegSm>
            Thrivent is the marketing name for Thrivent Financial for Lutherans.
            Insurance products issued by Thrivent. Not available in all states.
            Securities and investment advisory services offered through Thrivent
            Investment Management Inc., a registered investment adviser, member{' '}
            <BlueNavLink
              href={FINRA_URL}
              ariaLabel="FINRA - opens in a new tab"
              target="_blank"
            >
              FINRA
            </BlueNavLink>{' '}
            and{' '}
            <BlueNavLink
              ariaLabel="SIPC - opens in a new tab"
              href={SPIC_URL}
              target="_blank"
            >
              SIPC
            </BlueNavLink>
            , and a subsidiary of Thrivent. Licensed agent/producer of Thrivent.
            Registered representative of Thrivent Investment Management, Inc.{' '}
            <BlueNavLink
              href={THRIVENT_DISCLOSURES_URL}
              target="_blank"
              ariaLabel="Thrivent.com/disclosures - opens in a new tab"
            >
              Thrivent.com/disclosures
            </BlueNavLink>
            .
          </BodyText.RegSm>
          <BodyText.RegSm>
            Insurance products, securities and investment advisory services are
            provided by appropriately appointed and licensed financial advisors
            and professionals. Only individuals who are financial advisors are
            credentialed to provide investment advisory services. Visit
            Thrivent.com or FINRA&apos;s BrokerCheck for more information about
            our financial advisors.
          </BodyText.RegSm>
          <div>
            <BodyText.RegSm>
              Thrivent provides advice and guidance through its Financial
              Planning Framework that generally includes a review and analysis
              of a client&apos;s financial situation. A client may choose to
              further their planning engagement with Thrivent through its
              Dedicated Planning Services (an investment advisory service) that
              results in written recommendations for a fee.
            </BodyText.RegSm>
            <BrokerCheckContainer>
              <BrokerCheck layout="fill" />
            </BrokerCheckContainer>
            <BodyText.RegSm>
              Check the background of our professionals on{' '}
              <BlueNavLink href={BROKER_CHECK_URL} target="_blank">
                FINRA&apos;s
              </BlueNavLink>{' '}
              BrokerCheck.
            </BodyText.RegSm>
          </div>
          <LicensePlate />
        </TermsContent>
      </TermsSection>
    </StyledFooter>
  );
};
