import { ResponsiveTypographyTokens } from '@thrivent-web/ui/tokens';
import { breakpoints } from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';
import {
  ColorProps,
  LayoutProps,
  TypographyProps,
  color,
  layout,
  typography,
} from 'styled-system';

type TextProps = {
  uppercase?: boolean;
  color?: string;
} & TypographyProps &
  ColorProps &
  LayoutProps;

export const BaseTypography = styled.div<TextProps>`
  ${(p) =>
    p.uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${color}
  ${typography}
  ${layout}
`;

export const createTypography = (tokens: ResponsiveTypographyTokens) => css`
  font-family: ${tokens.mobile.font};
  font-size: ${tokens.mobile.rem};
  font-weight: ${tokens.mobile.weight};

  ${tokens?.tablet &&
  css`
    ${breakpoints.tablet} {
      font-family: ${tokens.tablet.font};
      font-size: ${tokens.tablet.rem};
      font-weight: ${tokens.tablet.weight};
    }
  `}
  ${tokens?.desktop &&
  css`
    ${breakpoints.desktop} {
      font-family: ${tokens.desktop.font};
      font-size: ${tokens.desktop.rem};
      font-weight: ${tokens.desktop.weight};
    }
  `};
`;
