import { ElementType, createContext, useContext } from 'react';

export type MDSConfig = {
  linkComp?: ElementType | null;
};

export const defaultMdsConfig: MDSConfig = {
  linkComp: null,
};

export const MDSConfigContext = createContext<MDSConfig>(defaultMdsConfig);

export const useMdsConfig = () => useContext(MDSConfigContext);
