import { NavMenuLink, NavMenuTriggerWithCaret } from '../shared/components';
import { MobileAppSection } from '../shared/components/MobileAppSection';
import { NavMenuItemEnum } from '../shared/enums';
import {
  ContentSection,
  ContentSectionLabel,
  LinkOutIcon,
  NavMenuContent,
  NavMenuContentSectionWithSeparator,
  NavMenuItem,
  SectionDivider,
} from '../shared/styles';
import { NavMenuProps } from '../shared/types';

const ADVICE_URL = 'https://www.thrivent.com/advice';
const BANKING_URL = 'https://www.thrivent.com/banking';
const CREDIT_UNION_URL = 'https://www.thriventcu.com';
const INSURANCE_URL = 'https://www.thrivent.com/insurance';
const INVESTING_URL = 'https://www.thrivent.com/investments';
const THRIVENT_MEMBERSHIP_URL = 'https://www.thrivent.com/about-us/membership';

export const ExploreMenu = ({ currentMenuItem = '' }: NavMenuProps) => {
  return (
    <NavMenuItem
      value={NavMenuItemEnum.ExploreMenu}
      open={NavMenuItemEnum.ExploreMenu === currentMenuItem}
    >
      <NavMenuTriggerWithCaret>Explore</NavMenuTriggerWithCaret>
      <NavMenuContent>
        <ContentSection key="Financial products">
          <ContentSectionLabel as={'h4'}>
            Financial products
          </ContentSectionLabel>
          <NavMenuLink
            name="Advice"
            url={ADVICE_URL}
            target="_blank"
            icon={<LinkOutIcon />}
          />
          <NavMenuLink
            name="Insurance"
            url={INSURANCE_URL}
            target="_blank"
            icon={<LinkOutIcon />}
          />
          <NavMenuLink
            name="Investing"
            url={INVESTING_URL}
            target="_blank"
            icon={<LinkOutIcon />}
          />
          <NavMenuLink
            name="Banking"
            url={BANKING_URL}
            target="_blank"
            icon={<LinkOutIcon />}
          />
        </ContentSection>
        <SectionDivider />
        <NavMenuContentSectionWithSeparator key="Member benefits">
          <ContentSection>
            <ContentSectionLabel as={'h4'}>Member benefits</ContentSectionLabel>
            <NavMenuLink
              name="Thrivent membership"
              url={THRIVENT_MEMBERSHIP_URL}
              target="_blank"
              icon={<LinkOutIcon />}
            />
            <NavMenuLink
              name="Thrivent Credit Union"
              url={CREDIT_UNION_URL}
              target="_blank"
              icon={<LinkOutIcon />}
            />
          </ContentSection>
        </NavMenuContentSectionWithSeparator>
        <SectionDivider />
        <NavMenuContentSectionWithSeparator>
          <MobileAppSection />
        </NavMenuContentSectionWithSeparator>
        <SectionDivider />
      </NavMenuContent>
    </NavMenuItem>
  );
};
