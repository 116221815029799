import { LogsEvent } from '@datadog/browser-logs';
import { RumEvent } from '@datadog/browser-rum';

type Error = {
  message?: string;
};

const messageTest = (message?: string) =>
  /Object Not Found Matching Id:\d, MethodName:update, ParamCount:4/.test(
    message ?? ''
  );

// Filters out a very specific error that outlook "safe links" can spit out
// Links below are for Sentry, but the issue is the exact same:
// https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
// https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/27
export const outlookSafeLinkFixBeforeSend = (event: RumEvent | LogsEvent) => {
  const error = event?.error as Error;
  if (
    (event?.type === 'error' || event?.status === 'error') &&
    messageTest(error?.message)
  ) {
    return false;
  }
  return true;
};
