import {
  InlineMessageText,
  SystemAlertText,
} from './NotificationContent.styles';
import { TextProps } from './types';

export const TextOnly = ({
  type,
  text,
  notificationType,
  subtle = false,
  ...rest
}: TextProps) => {
  const WrapperComponent =
    notificationType === 'system-alert' ? SystemAlertText : InlineMessageText;
  return (
    <WrapperComponent $type={type} $subtle={subtle} {...rest}>
      {text}
    </WrapperComponent>
  );
};
