import { NavColumn, NavColumnHeader, NavLink } from './shared/styles';
import { BodyText } from '@thrivent-web/ui/atoms';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

const AddressesDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const AddressNavColumn = styled(NavColumn)`
  ${tablet} {
    grid-column-start: 4;
  }
`;

export const CorporateCenter = () => (
  <AddressNavColumn>
    <AddressesDiv>
      <NavColumnHeader>Corporate Center</NavColumnHeader>
      <div>
        <BodyText.RegSm>600 Portland Avenue S.,</BodyText.RegSm>
        <BodyText.RegSm>Suite 100</BodyText.RegSm>
        <BodyText.RegSm>Minneapolis, MN</BodyText.RegSm>
        <BodyText.RegSm>55415-4402 USA</BodyText.RegSm>
      </div>
      <NavLink href="tel:8008474836">T: 800-847-4836</NavLink>
    </AddressesDiv>
  </AddressNavColumn>
);
