import { datadogRum } from '@datadog/browser-rum';
import { logger } from '@thrivent-web/logging-utils';
import {
  LDClient,
  LDContext,
  LDEvaluationDetail,
  LDOptions,
  initialize,
} from 'launchdarkly-js-client-sdk';

let _ldBrowserClient: LDClient;

export const ldInspector: LDOptions = {
  inspectors: [
    {
      type: 'flag-used',
      name: 'dd-inspector',
      method: (key: string, detail: LDEvaluationDetail) => {
        datadogRum.addFeatureFlagEvaluation(key, detail.value);
      },
    },
  ],
};

export const getLaunchDarklyBrowserClient = async (ldContext: LDContext) => {
  try {
    if (!_ldBrowserClient) {
      const ldClientSideId =
        process.env['LAUNCHDARKLY_SDK_CLIENT_SIDE_ID'] ?? '';
      _ldBrowserClient = initialize(ldClientSideId, ldContext, ldInspector);
    }

    await _ldBrowserClient.waitForInitialization();

    await _ldBrowserClient.identify(ldContext);

    return _ldBrowserClient;
  } catch (e) {
    logger.error('Error loading Launch Darkly browser client', e);
    return;
  }
};
