import { BillProductType, BillProps } from './utils/billTypes';
import { getBillStatus } from './utils/getBillStatus';
import { getBillStatuses } from './utils/getBillStatuses';
import { getTagLabel } from './utils/getTagLabel';
import {
  BillsDetailFragment,
  OutstandingClientGroupBillFragment,
  PaymentDetailFragment,
} from '@servicing/data-queries';

export function useBillStatusIndicator({
  bills,
  singleBill,
  groupBills,
  scheduledPayment,
  productType,
  truncateCount = true,
}: {
  bills?: BillProps[] | undefined;
  singleBill?: BillsDetailFragment;
  groupBills?: OutstandingClientGroupBillFragment[];
  scheduledPayment?: PaymentDetailFragment;
  productType?: BillProductType;
  truncateCount?: boolean;
}) {
  const statuses = getBillStatuses(
    bills,
    singleBill,
    scheduledPayment,
    groupBills
  );
  const { billsDueCount } = statuses;
  const billStatus = getBillStatus(statuses);
  const tag = getTagLabel(
    billsDueCount,
    productType,
    singleBill,
    scheduledPayment,
    truncateCount
  );

  return { billStatus, statusTag: tag, hasBillsDue: !!billsDueCount };
}
