import { Footer } from '../Footer/Footer';
import { Nav } from '../Nav/Nav';
import { SkipLink } from '../SkipLink/SkipLink';
import { ReactNode } from 'react';
import styled from 'styled-components';

const isStage = process.env['NEXT_PUBLIC_IS_STAGE'] === 'true';

const StageCircle = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${(p) => p.theme.colors.brand_primary_faint};
  border-radius: 50%;
`;

const LayoutContainer = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: min-content 1fr min-content;
  min-height: 100vh;
`;

interface Props {
  children: ReactNode;
}

export const Layout = ({ children }: Props) => {
  return (
    <LayoutContainer>
      <SkipLink contentTagName="#main" />
      {isStage ? <StageCircle data-testid="stage-true" /> : null}
      <Nav />
      {children}
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
