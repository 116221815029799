import { Logo, NavColumn, NavColumnHeader, NavList } from './shared/styles';
import {
  FacebookLogo,
  InstagramLogo,
  LinkedInLogo,
  TwitterLogo,
} from '@thrivent-web/ui/icons';
import { tablet } from '@thrivent/midwest-shared';
import { useId } from 'react';
import styled from 'styled-components';

const LogoNavListItem = styled.li`
  display: inline-flex;
  margin-right: 1rem;
  cursor: pointer;
`;

const FollowUsNavColumn = styled(NavColumn)`
  ${tablet} {
    grid-column-start: 2;
  }
`;

export const FACEBOOK_URL = 'https://www.facebook.com/thrivent';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/6233';
export const TWITTER_URL = 'https://twitter.com/thrivent';
export const INSTAGRAM_URL = 'https://www.instagram.com/thrivent';

export const FollowUs = () => {
  const followUsId = useId();

  return (
    <FollowUsNavColumn aria-labelledby={followUsId}>
      <NavColumnHeader id={followUsId}>Follow Us</NavColumnHeader>
      <NavList>
        <LogoNavListItem>
          <Logo
            href={FACEBOOK_URL}
            target="_blank"
            aria-label="facebook - opens in new tab"
          >
            <FacebookLogo width={30} height={30} role="img" aria-hidden />
          </Logo>
        </LogoNavListItem>
        <LogoNavListItem>
          <Logo
            href={LINKEDIN_URL}
            target="_blank"
            aria-label="linkedIn - opens in new tab"
          >
            <LinkedInLogo width={30} height={30} role="img" aria-hidden />
          </Logo>
        </LogoNavListItem>
        <LogoNavListItem>
          <Logo
            href={TWITTER_URL}
            target="_blank"
            aria-label="twitter - opens in new tab"
          >
            <TwitterLogo width={30} height={30} role="img" aria-hidden />
          </Logo>
        </LogoNavListItem>
        <LogoNavListItem>
          <Logo
            href={INSTAGRAM_URL}
            target="_blank"
            aria-label="instagram - opens in new tab"
          >
            <InstagramLogo width={30} height={30} role="img" aria-hidden />
          </Logo>
        </LogoNavListItem>
      </NavList>
    </FollowUsNavColumn>
  );
};
