import { AdobeWindow } from './types';
import { logger } from '@thrivent-web/logging-utils';

export function findAdobeWithRetry(timeout: number, callback: () => void) {
  const start = performance.now();

  function findWithRetry() {
    const time = performance.now() - start;
    const adobeWindow = window as AdobeWindow;
    const adobe = adobeWindow?.adobeDataLayer;

    if (adobe) {
      callback();
    } else if (time > timeout) {
      logger.warn('Failed to detect window.adobeDataLayer');
    } else {
      window.requestAnimationFrame(findWithRetry);
    }
  }

  findWithRetry();
}
