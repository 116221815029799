import { UserClientContextLdQuery } from './userContextForLaunchDarkly.generated';

export const hasTermExpiryNotification = (data?: UserClientContextLdQuery) => {
  const insurancePolicies =
    data?.client?.insurancePolicyDetail?.insurancePolicies ?? [];
  const hasNotifications =
    insurancePolicies.length > 0 &&
    insurancePolicies.some((policy) => {
      const notifications = policy?.notifications ?? [];
      return notifications.length > 0;
    });

  return hasNotifications;
};
