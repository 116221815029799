import { ClientError } from 'graphql-request';
import { err as errorSerializer } from 'pino-std-serializers';

// error values were removed downstream in logger.error, so this makes sure the important ones are logged
export const logErrorValues = (error: unknown) => {
  if (error instanceof ClientError) {
    const err = {
      name: error.name,
      // all graphql-request errors insert the response into the error message text in this format
      message: error.message.split(': {')?.[0],
      // @ts-expect-error types don't matter here
      status: error?.status,
      errors: error?.response?.errors?.map(({ path, ...rest }) => ({
        ...rest,
        // combine array into dot value so we can filter in datadog
        path: path?.join('.') ?? path,
      })),
    };

    return { err: errorSerializer(err) };
  } else if (error instanceof Error) {
    return { err: errorSerializer(error) };
  } else {
    return { err: error };
  }
};
