import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { ChevronDown } from '@thrivent-web/ui/icons';
import { tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledCaret = styled(ChevronDown)`
  align-content: center;
  path {
    stroke: ${(p) => p.theme.midwestColors.textPrimary};
  }
  @media (prefers-reduced-motion: no-preference) {
    transition: transform 250ms ease;
  }
`;

type Props = {
  children: ReactNode;
};

const StyledTrigger = styled(NavigationMenu.Trigger)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background: transparent;
  width: 100%;
  cursor: pointer;
  padding: 0;

  &[data-state='open'] {
    margin-bottom: 1.5rem;
    span {
      font-weight: 700;
    }
    svg {
      transform: rotate(180deg);
    }
  }

  ${tablet} {
    height: 5rem;
    &[data-state='open'] {
      margin-bottom: 0;
      border-top: 0;
      border-bottom: 0;
    }
  }
`;

const StyledCaretContainer = styled.div`
  width: 1.5rem;
  margin-left: 0.25rem;
`;

export const NavMenuTriggerWithCaret = ({ children }: Props) => {
  // The onPointerLeave / preventDefault technique is an undocumented
  // feature in Radix to prevent the menu from expanding on mouse hover.
  // Learned in the Radix Discord chat:
  // https://discord.com/channels/752614004387610674/961715478991110187/961912855505010739
  return (
    <StyledTrigger
      onPointerMove={(event) => event.preventDefault()}
      onPointerLeave={(event) => event.preventDefault()}
    >
      <MDSText.BodyRegDefault>{children}</MDSText.BodyRegDefault>
      <StyledCaretContainer>
        <StyledCaret aria-hidden role="img" />
      </StyledCaretContainer>
    </StyledTrigger>
  );
};
