import { BillProductType } from './billTypes';
import {
  BillsDetailFragment,
  PaymentDetailFragment,
} from '@servicing/data-queries';
import { formatDate, pluralize } from '@servicing/util-formatter';
import { MONTH_ABBREVIATION_DAY } from '@thrivent-web/ui/utils';

export const getTagLabel = (
  billCount: number,
  productType?: BillProductType,
  bill?: BillsDetailFragment,
  scheduledBill?: PaymentDetailFragment,
  truncateCount?: boolean
) => {
  if (bill) {
    const { billStatus } = bill;
    if (billStatus === 'PAST_DUE') {
      return `Past due ${formatDate(bill.dueDate, MONTH_ABBREVIATION_DAY)}`;
    }
    if (billStatus === 'DUE') {
      return `Due ${formatDate(bill.dueDate, MONTH_ABBREVIATION_DAY)}`;
    }
  }
  if (scheduledBill) {
    return `Scheduled for ${formatDate(
      scheduledBill.effectiveDate,
      MONTH_ABBREVIATION_DAY
    )}`;
  }

  if (!billCount) return '';

  if (!productType) {
    return `${billCount > 10 && truncateCount ? '10+' : billCount}`;
  }
  if (productType === BillProductType.annuity) {
    const displayPrefix =
      billCount === 1
        ? 'Contribution'
        : pluralize(billCount, 'contribution', 's');
    return `${displayPrefix} due`;
  }
  if (
    productType === BillProductType.insurance ||
    productType === BillProductType.allBills
  ) {
    const displayPrefix =
      billCount === 1
        ? 'Bill'
        : pluralize(billCount, 'bill', 's', truncateCount);
    return `${displayPrefix} due`;
  }
  return '';
};
