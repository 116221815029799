import { NavMenuLink, NavMenuTriggerWithCaret } from '../shared/components';
import { MobileAppSection } from '../shared/components/MobileAppSection';
import { NavMenuItemEnum } from '../shared/enums';
import {
  ContentSection,
  ContentSectionLabel,
  LinkOutIcon,
  NavMenuContent,
  NavMenuContentSectionWithSeparator,
  NavMenuItem,
  SectionDivider,
} from '../shared/styles';
import { NavMenuProps } from '../shared/types';
import {
  useAnnuitiesDetailQuery,
  useDashboard,
  useSettlementOptionDetailQuery,
} from '@servicing/data-queries';
import { getProtectUrlSegment } from '@servicing/util-formatter';
import { DASHBOARD_ROUTE } from '@servicing/util-routes';
import {
  SERVICING_THRIVENT_CHARITABLE_LINK_ENABLED,
  SERVICING_THRIVENT_TRUST_LINK_ENABLED,
  useFlag,
} from '@thrivent-web/servicing/feature-management';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

const AccountsContactLabel = styled(ContentSectionLabel)`
  ${tablet} {
    margin-top: 1rem;
  }
`;

export const AccountsMenu = ({ currentMenuItem = '' }: NavMenuProps) => {
  const isThriventCharitableEnabled = useFlag(
    SERVICING_THRIVENT_CHARITABLE_LINK_ENABLED
  );
  const isThriventTrustEnabled = useFlag(SERVICING_THRIVENT_TRUST_LINK_ENABLED);

  const { data } = useDashboard();
  const { data: annuitiesData } = useAnnuitiesDetailQuery() || [];
  const { data: settlementOptionData } = useSettlementOptionDetailQuery() || [];

  const policies = data?.client?.insurancePolicyDetail?.insurancePolicies
    ? data?.client?.insurancePolicyDetail?.insurancePolicies.map((policy) => {
        return {
          id: policy.id,
          description: policy.description,
          url: `/protect/${getProtectUrlSegment(policy.type)}/${
            policy.productTypeCode
          }/${policy.id}/details`,
        };
      })
    : [];

  const brokerages = data?.feed?.accounts?.investments?.brokerage?.accounts
    ? data?.feed?.accounts?.investments?.brokerage?.accounts.map(
        (brokerage) => {
          return {
            id: brokerage.id,
            description: brokerage.accountDescription,
            url: `/brokerage/${brokerage.id}/details`,
          };
        }
      )
    : [];

  const mutualFunds = data?.feed?.accounts?.investments?.mutualFund?.accounts
    ? data?.feed?.accounts?.investments?.mutualFund?.accounts.map(
        (mutualFund) => {
          return {
            id: mutualFund.id,
            description: mutualFund.accountDescription,
            url: `/mutual-funds/${mutualFund.id}/details`,
          };
        }
      )
    : [];

  const annuities = annuitiesData?.client?.annuitiesDetail?.accounts
    ? annuitiesData?.client?.annuitiesDetail?.accounts.map((annuity) => {
        return {
          id: annuity.id,
          description: annuity.description,
          url: `/annuities/${annuity.productTypeCode}/${annuity.id}/details`,
        };
      })
    : [];

  const settlementOptions = settlementOptionData?.client
    ?.settlementOptionsDetail?.settlementOptions
    ? settlementOptionData?.client?.settlementOptionsDetail?.settlementOptions.map(
        (settlementOption) => {
          return {
            id: settlementOption.id,
            description: settlementOption.description,
            url: `/settlement-options/${settlementOption.productTypeCode}/${settlementOption.id}/details`,
          };
        }
      )
    : [];

  // User Accounts
  const userAccounts = [
    ...policies,
    ...brokerages,
    ...mutualFunds,
    ...annuities,
    ...settlementOptions,
  ];

  // Page URLs
  const THRIVENT_TRUST_COMPANY_ROUTE =
    process.env['NEXT_PUBLIC_SERVICING_THRIVENT_TRUST_COMPANY_ROUTE'] || '';
  const CHARITABLE_ROUTE =
    process.env['NEXT_PUBLIC_SERVICING_CHARITABLE_ROUTE'] || '';

  return (
    <NavMenuItem
      value={NavMenuItemEnum.AccountsMenu}
      open={currentMenuItem === NavMenuItemEnum.AccountsMenu}
    >
      <NavMenuTriggerWithCaret>Accounts</NavMenuTriggerWithCaret>
      <NavMenuContent>
        <ContentSection>
          <NavMenuLink name="Overview" url={DASHBOARD_ROUTE} target="_self" />
          {userAccounts &&
            userAccounts.map((account, index) => {
              if (index >= 10) return null;
              return (
                <NavMenuLink
                  key={account.id || ''}
                  name={account.description || ''}
                  url={account.url || ''}
                  target="_self"
                />
              );
            })}
          {(isThriventTrustEnabled || isThriventCharitableEnabled) && (
            <>
              <SectionDivider />
              <AccountsContactLabel>Other Accounts</AccountsContactLabel>
            </>
          )}
          {isThriventTrustEnabled && (
            <NavMenuLink
              name="Thrivent Trust Company"
              icon={<LinkOutIcon />}
              url={`${THRIVENT_TRUST_COMPANY_ROUTE}`}
              target="_blank"
            />
          )}
          {isThriventCharitableEnabled && (
            <NavMenuLink
              name="Thrivent Charitable"
              icon={<LinkOutIcon />}
              url={CHARITABLE_ROUTE}
              target="_blank"
            />
          )}
        </ContentSection>
        <SectionDivider />
        <NavMenuContentSectionWithSeparator>
          <MobileAppSection />
        </NavMenuContentSectionWithSeparator>
      </NavMenuContent>
    </NavMenuItem>
  );
};
