export const pluralize = (
  count: number,
  noun: string,
  // we should consider taking an options object rather than many optional arguments
  suffix = 's',
  truncateCount = true,
  formatCount?: (count: number) => string
) => {
  if (truncateCount && count > 10) {
    return `10+ ${noun}${count !== 1 ? suffix : ''}`;
  }

  return `${formatCount ? formatCount(count) : count} ${noun}${
    count !== 1 ? suffix : ''
  }`;
};

export const pluralizedString = (
  singularString: string,
  pluralizedString: string,
  count: number | undefined
) => {
  if (count === undefined) {
    return '';
  }

  return count === 1 ? singularString : pluralizedString;
};
