import { BadgeType } from '@midwest/web/indicators';
import { BillStatus } from '@thrivent/supergraph-types';
import { DefaultTheme } from 'styled-components';

export type BillProps = {
  billStatus: BillStatus | undefined;
};

export const getBadgeColor = (billStatus: string | null): BadgeType => {
  if (billStatus === 'PAST_DUE') {
    return 'negative';
  }
  if (billStatus === 'DUE') {
    return 'warning';
  }
  if (billStatus === 'SCHEDULED') {
    return 'positive';
  }
  return 'neutral';
};

export const getBillContainerColor = (
  theme: DefaultTheme,
  billStatus: BillStatus | undefined
) => {
  if (billStatus === 'PAST_DUE') {
    return theme.midwestColors.sentimentDangerMedium;
  }
  if (billStatus === 'DUE') {
    return theme.midwestColors.sentimentWarningMedium;
  }
  return 'transparent';
};
