import {
  DATE_TIMEZONE_FORMAT,
  MONTH_ABBREVIATION_DAY_COMMA_YEAR,
  MONTH_ABBREVIATION_DOT_DAY_COMMA_YEAR,
  MONTH_NAME_DAY_YEAR,
} from '@thrivent-web/ui/utils';
import { format as dateFormat, getMonth, parseISO } from 'date-fns';
import { format as dateFormatTz } from 'date-fns-tz';

export const dateIsValid = (date: string | number | Date) => {
  return !Number.isNaN(new Date(date).getTime());
};

export const formatDate = (
  date: string | number | Date | null | undefined,
  format = MONTH_NAME_DAY_YEAR
) => {
  if (!date) date = new Date();

  let formattedDate =
    date instanceof Date || typeof date === 'number' ? date : parseISO(date);

  // this will return "May" instead of "May."
  if (
    format === MONTH_ABBREVIATION_DOT_DAY_COMMA_YEAR &&
    getMonth(formattedDate) === 4
  ) {
    return dateFormat(formattedDate, MONTH_ABBREVIATION_DAY_COMMA_YEAR);
  }

  return dateFormat(formattedDate, format);
};

export const formatDateTz = (
  date: string | number | Date | null | undefined,
  format = DATE_TIMEZONE_FORMAT
) => {
  if (!date) date = new Date();
  const parsedDate =
    date instanceof Date || typeof date === 'number' ? date : parseISO(date);

  return dateFormatTz(parsedDate, format);
};

// An ordinal is one of 'th', 'st', 'nd', 'rd'
export const formatDayWithOrdinal = (dayOfMonth: number): string => {
  const date = new Date(2024, 0, dayOfMonth);

  return dateFormat(date, 'do');
};
