'use client';

import { CallbackHandler } from './CallbackHandler';
import {
  deleteThriventLoggedInCookie,
  setThriventLoggedInCookie,
} from './cookieHelpers';
import { AuthProvider, Log, UserManager } from './imports';
import type { ILogger, UserManagerSettings } from './imports';
import { Router, windowRouter } from './router';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

type AuthConfigContextProps = UserManagerSettings & {
  loginPageRoute: string;
};
const AuthConfigContext = createContext<AuthConfigContextProps>({
  loginPageRoute: '/authentication/login',
  authority: '',
  client_id: '',
  redirect_uri: '',
});

export const useAuthConfig = () => useContext(AuthConfigContext);

/**
 * The OIDCProvider makes auth functions accessible for client side code.
 *
 * The provider manages post login effects on the client such as deleting
 * the cookie used for ephemeral environment logins and redirecting a user back
 * to page that initiated a login session used for deep linking.
 *
 * The provider provides access to the configuration used to generate the
 * context with `useAuthConfig` hook helper.
 */
export type OIDCProviderProps = UserManagerSettings & {
  loginPageRoute?: string;
  logLevel?: Log;
  logger?: ILogger;
  router?: Router;
  onSigninCallbackError?: (error: Error) => void;
};

/**
 * Context Provider for OIDC authentication data and methods.
 *
 * @param {string} [OIDCProviderProps.loginPageRoute='/authentication/login'] - The login page route.
 * @param {Log} [OIDCProviderProps.logLevel=Log.ERROR] - The log level.
 * @param {ILogger} [OIDCProviderProps.logger=console] - The logger.
 * @param {Router} [OIDCProviderProps.router=windowRouter] - The router.
 * @param {function} [OIDCProviderProps.onSigninCallbackError] - The callback function for signin errors. If not provided, the user will be routed to root.
 * @returns {ReactNode}
 */
export const OIDCProvider: FC<PropsWithChildren<OIDCProviderProps>> = ({
  children,
  loginPageRoute,
  logLevel = Log.ERROR,
  logger = console,
  router = windowRouter,
  onSigninCallbackError,
  ...oidcSettings
}) => {
  Log.setLogger(logger);
  Log.setLevel(logLevel);
  const userManager = useMemo(() => {
    const manager = new UserManager({
      automaticSilentRenew: false,
      ...oidcSettings,
    });
    /**
     * Handle setting/unsetting of legacy `thriventLoggedIn` cookie used by
     * legacy java apps and Brightspot for conditionally rendered content.
     */
    manager.events.addUserLoaded(() => {
      setThriventLoggedInCookie();
    });
    manager.events.addSilentRenewError(() => {
      deleteThriventLoggedInCookie();
    });
    manager.events.addUserUnloaded(() => {
      deleteThriventLoggedInCookie();
    });
    return manager;
  }, [oidcSettings]);

  const defaultedLoginPageRoute = loginPageRoute ?? '/authentication/login';

  return (
    <AuthProvider skipSigninCallback={true} userManager={userManager}>
      <AuthConfigContext.Provider
        value={{ loginPageRoute: defaultedLoginPageRoute, ...oidcSettings }}
      >
        <CallbackHandler
          userManager={userManager}
          defaultedLoginPageRoute={defaultedLoginPageRoute}
          router={router}
          onSigninCallbackError={onSigninCallbackError}
        />
        {children}
      </AuthConfigContext.Provider>
    </AuthProvider>
  );
};
