import { useConsumerId } from '../consumerId/useConsumerId';
import { DashboardQuery, useDashboardQuery } from './dashboard.generated';
import { QueryOptions } from '@tanstack/react-query';

export const useDashboard = (options?: QueryOptions<DashboardQuery>) => {
  const consumerId = useConsumerId();
  return useDashboardQuery(
    { consumerId },
    {
      enabled: !!consumerId,
      ...options,
      meta: {
        ...options?.meta,
        enablePartial: true,
      },
    }
  );
};
