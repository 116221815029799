import jwtDecode, { JwtDecodeOptions } from 'jwt-decode';

/**
 * returns memoized function of jwtDecode which will only memoize the most
 * recent token and only in the browser context
 */
export const memoizedJwtDecode = ((): typeof jwtDecode => {
  if (typeof window === 'undefined') {
    return jwtDecode;
  }

  let _lastToken: string;
  let _claims: unknown;

  return function memoizedJwtDecode<T = unknown>(
    token: string,
    options: JwtDecodeOptions
  ): T {
    if (token === _lastToken) return _claims as T;
    const claims = jwtDecode(token, options);
    _claims = claims;
    return _claims as T;
  } as typeof jwtDecode;
})();
