import { tablet } from '@thrivent/midwest-shared';
import { MDSLink, MDSText } from '@thrivent/midwest-web';
import { ReactElement } from 'react';
import styled from 'styled-components';

export type TargetProps = '_blank' | '_self';

type NavMenuProps = {
  analyticsId?: string;
  url: string;
  icon?: ReactElement;
  target: TargetProps;
  name: string;
};

const StyledLinkText = styled(MDSText.Subtitle)`
  color: ${(p) => p.theme.midwestColors.textPrimary};

  ${tablet} {
    :hover,
    :focus {
      text-decoration: underline;
      text-underline-offset: 7px;
    }
    text-align: left;
  }
`;

const StyledIcon = styled.span`
  margin-left: 0.5rem;
  svg {
    margin-top: 0.125rem;
    vertical-align: top;
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const NavMenuLink = ({
  analyticsId,
  icon,
  name,
  target,
  url,
}: NavMenuProps) => {
  return (
    <MDSLink
      data-analytics-id={analyticsId}
      data-dd-action-name={analyticsId}
      href={url}
      aria-label={target === '_blank' ? `${name} - opens in a new tab` : name}
      target={target}
    >
      <StyledLinkText>
        {name}
        {icon ? <StyledIcon>{icon}</StyledIcon> : null}
      </StyledLinkText>
    </MDSLink>
  );
};
