import {
  ClientProfileQuery,
  useClientProfileQuery,
} from './clientProfile.generated';
import { UseQueryOptions } from '@tanstack/react-query';

export const useClientProfile = (
  options?: Partial<UseQueryOptions<ClientProfileQuery>>
) =>
  useClientProfileQuery(undefined, {
    staleTime: Infinity,
    gcTime: Infinity,
    meta: {
      ...(options?.meta ?? {}),
      enablePartial: true,
    },
    ...options,
  });
