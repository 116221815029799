// Welcome to flags!
// To add a new feature flag:
// 1. Add a new flag key

export const SERVICING_DELETE_ACI_FUNDING_ACCOUNT_ENABLED =
  'SERVICING_DELETE_ACI_FUNDING_ACCOUNT_ENABLED';
export const SERVICING_FLAGS_PAGE_ENABLED = 'SERVICING_FLAGS_PAGE_ENABLED';
export const SERVICING_FLAGS_E2E_TEST_ENABLED =
  'SERVICING_FLAGS_E2E_TEST_ENABLED';
export const SERVICING_DASHBOARD_BILL_INDICATORS_ENABLED =
  'SERVICING_DASHBOARD_BILL_INDICATORS_ENABLED';
export const SERVICING_WEB_MF_AUTOMATIC_REDEMPTION_ENABLED =
  'SERVICING_WEB_MF_AUTOMATIC_REDEMPTION_ENABLED';
export const SERVICING_WEB_MF_SHEBANGS_PURCHASE_EXISTING_MUTATION_ENABLED =
  'SERVICING_WEB_MF_SHEBANGS_PURCHASE_EXISTING_MUTATION_ENABLED';
export const SERVICING_CHARITABLE_HISTORY_ENABLED =
  'SERVICING_CHARITABLE_HISTORY_ENABLED';
export const SERVICING_ADOBE_ENABLED = 'SERVICING_ADOBE_ENABLED';
export const SERVICING_VIEW_GROUP_BILLS_ENABLED =
  'SERVICING_VIEW_GROUP_BILLS_ENABLED';
export const SERVICING_SETTINGS_SPARKLE = 'SERVICING_SETTINGS_SPARKLE';
export const SERVICING_PAYMENT_HISTORY_DETAILS_ENABLED =
  'SERVICING_PAYMENT_HISTORY_DETAILS_ENABLED';
export const SERVICING_WEB_MF_BANK_INFORMATION_ENABLED =
  'SERVICING_WEB_MF_BANK_INFORMATION_ENABLED';
export const SERVICING_HOLIDAY_GIFT_GUIDE_ENABLED =
  'SERVICING_HOLIDAY_GIFT_GUIDE_ENABLED';
export const SERVICING_WEB_MF_DISTRIBUTION_OPTIONS_ENABLED =
  'SERVICING_WEB_MF_DISTRIBUTION_OPTIONS_ENABLED';
export const SERVICING_WEB_MF_HISTORICAL_BALANCE_ENABLED =
  'SERVICING_WEB_MF_HISTORICAL_BALANCE_ENABLED';
export const SERVICING_WEB_MF_COST_BASIS_ENABLED =
  'SERVICING_WEB_MF_COST_BASIS_ENABLED';
export const SERVICING_WEB_MF_BENEFICIARIES_ENABLED =
  'SERVICING_WEB_MF_BENEFICIARIES_ENABLED';
export const SERVICING_NAME_CHANGE_ENABLED = 'SERVICING_NAME_CHANGE_ENABLED';
export const SERVICING_DONOR_ADVISED_FUNDS_DONATIONS_ENABLED =
  'SERVICING_DONOR_ADVISED_FUNDS_DONATIONS_ENABLED';
export const SERVICING_HOLIDAY_CHOICE_DOLLARS_ENABLED =
  'SERVICING_HOLIDAY_CHOICE_DOLLARS_ENABLED';
export const SERVICING_CHOICE_DOLLARS_SIDE_NAV_ENABLED =
  'SERVICING_CHOICE_DOLLARS_SIDE_NAV_ENABLED';
export const SERVICING_GROUP_BILL_DETAILS_ENABLED =
  'SERVICING_GROUP_BILL_DETAILS_ENABLED';
export const SERVICING_GROUP_BILL_HISTORY_ENABLED =
  'SERVICING_GROUP_BILL_HISTORY_ENABLED';
export const TERM_EXPIRATION_COLOR_RED = 'term-expiration-color-red';
export const SERVICING_GROUP_BILL_PAYMENTS_ENABLED =
  'SERVICING_GROUP_BILL_PAYMENTS_ENABLED';
export const SERVICING_LIVE_GENEROUSLY_AWARD_ENABLED =
  'SERVICING_LIVE_GENEROUSLY_AWARD_ENABLED';
export const SERVICING_ORDER_VOLUNTEER_KIT_ENABLED =
  'SERVICING_ORDER_VOLUNTEER_KIT_ENABLED';
export const SERVICING_ENABLED_UNSCHEDULED_PAYMENT_QUERY_FIELDS =
  'SERVICING_ENABLED_UNSCHEDULED_PAYMENT_QUERY_FIELDS';
export const SERVICING_UNSCHEDULED_PAYMENTS_ENABLED =
  'SERVICING_UNSCHEDULED_PAYMENTS_ENABLED';
export const SERVICING_BENEFICIARIES_ROUTE_ENABLED =
  'SERVICING_BENEFICIARIES_ROUTE_ENABLED';
export const SERVICING_ACTION_TEAM_ANNIVERSARY_ENABLED =
  'SERVICING_ACTION_TEAM_ANNIVERSARY_ENABLED';
export const SERVICING_NEW_TOWN_SUITABILITY_UPDATE_ROUTE_ENABLED =
  'SERVICING_NEW_TOWN_SUITABILITY_UPDATE_ROUTE_ENABLED';
export const TMF_NEW_ACCOUNT_MANAGE_PREFERENCES_ENABLED =
  'TMF_NEW_ACCOUNT_MANAGE_PREFERENCES_ENABLED';
export const SERVICING_SSAT_PARTIAL_REALLOCATION_PERCENT_ENABLED =
  'SERVICING_SSAT_PARTIAL_REALLOCATION_PERCENT_ENABLED';
export const SERVICING_SSAT_PARTIAL_REALLOCATION_DOLLAR_ENABLED =
  'SERVICING_SSAT_PARTIAL_REALLOCATION_DOLLAR_ENABLED';
export const SERVICING_SSAT_TOTAL_REALLOCATION_PERCENT_ENABLED =
  'SERVICING_SSAT_TOTAL_REALLOCATION_PERCENT_ENABLED';
export const SERVICING_NATIVE_INVESTMENTS_YTD_ENABLED =
  'SERVICING_NATIVE_INVESTMENTS_YTD_ENABLED';
export const SERVICING_NATIVE_STORE_REVIEWS_ENABLED =
  'SERVICING_NATIVE_STORE_REVIEWS_ENABLED';
export const SERVICING_NATIVE_GRAPH_V2_ENABLED =
  'SERVICING_NATIVE_GRAPH_V2_ENABLED';
// eslint-disable-next-line @cspell/spellchecker
export const SERVICING_ENABLE_SHOW_NOBILLS =
  // eslint-disable-next-line @cspell/spellchecker
  'SERVICING_ENABLE_SHOW_NOBILLS';
export const SERVICING_THRIVENT_CHARITABLE_LINK_ENABLED =
  'SERVICING_THRIVENT_CHARITABLE_LINK_ENABLED';
export const SERVICING_THRIVENT_TRUST_LINK_ENABLED =
  'SERVICING_THRIVENT_TRUST_LINK_ENABLED';
export const SERVICING_IMMERSIVE_CHOICE_DOLLARS_ENABLED =
  'SERVICING_IMMERSIVE_CHOICE_DOLLARS_ENABLED';
export const SERVICING_ACTION_TEAMS_DASHBOARD_REDESIGN_ENABLED =
  'SERVICING_ACTION_TEAMS_DASHBOARD_REDESIGN_ENABLED';
export const SERVICING_CAUSE_KITS_ENABLED = 'SERVICING_CAUSE_KITS_ENABLED';
export const SERVICING_CAUSE_KIT_NOTIFICATION_ENABLED =
  'SERVICING_CAUSE_KIT_NOTIFICATION_ENABLED';
export const SERVICING_CAUSE_KIT_SOLAR_OUT_OF_STOCK_ENABLED =
  'SERVICING_CAUSE_KIT_SOLAR_OUT_OF_STOCK_ENABLED';
export const SERVICING_CAUSE_KIT_WATER_OUT_OF_STOCK_ENABLED =
  'SERVICING_CAUSE_KIT_WATER_OUT_OF_STOCK_ENABLED';
export const SERVICING_SSAT_PARTIAL_WITHDRAWAL_ENABLED =
  'SERVICING_SSAT_PARTIAL_WITHDRAWAL_ENABLED';
export const SERVICING_SSAT_ALLOCATE_FUTURE_PAYMENTS_ENABLED =
  'SERVICING_SSAT_ALLOCATE_FUTURE_PAYMENTS_ENABLED';
export const SERVICING_BENEFICIARIES_ELIGIBILITY_CHECK_ENABLED =
  'SERVICING_BENEFICIARIES_ELIGIBILITY_CHECK_ENABLED';
export const SERVICING_GENEROSITY_SHIRT_VOTE_ENABLED =
  'SERVICING_GENEROSITY_SHIRT_VOTE_ENABLED';
export const SERVICING_L1_ENHANCEMENTS = 'SERVICING_L1_ENHANCEMENTS';
export const TMF_NEW_ACCOUNT_RESUME_APPLICATION_ENABLED =
  'TMF_NEW_ACCOUNT_RESUME_APPLICATION_ENABLED';
export const SERVICING_RMD_INFO_ENABLED = 'SERVICING_RMD_INFO_ENABLED';

// 2. Add a flag
export const defaultFlags = {
  [SERVICING_UNSCHEDULED_PAYMENTS_ENABLED]: false,
  [SERVICING_ENABLED_UNSCHEDULED_PAYMENT_QUERY_FIELDS]: false,
  [SERVICING_DELETE_ACI_FUNDING_ACCOUNT_ENABLED]: false,
  [SERVICING_FLAGS_PAGE_ENABLED]: true,
  [SERVICING_FLAGS_E2E_TEST_ENABLED]: false,
  [SERVICING_DASHBOARD_BILL_INDICATORS_ENABLED]: false,
  [SERVICING_WEB_MF_AUTOMATIC_REDEMPTION_ENABLED]: false,
  [SERVICING_WEB_MF_SHEBANGS_PURCHASE_EXISTING_MUTATION_ENABLED]: false,
  [SERVICING_CHARITABLE_HISTORY_ENABLED]: false,
  [SERVICING_ADOBE_ENABLED]: false,
  [SERVICING_VIEW_GROUP_BILLS_ENABLED]: false,
  [SERVICING_SETTINGS_SPARKLE]: false,
  [SERVICING_PAYMENT_HISTORY_DETAILS_ENABLED]: false,
  [SERVICING_WEB_MF_BANK_INFORMATION_ENABLED]: false,
  [SERVICING_HOLIDAY_GIFT_GUIDE_ENABLED]: false,
  [SERVICING_WEB_MF_DISTRIBUTION_OPTIONS_ENABLED]: false,
  [SERVICING_WEB_MF_HISTORICAL_BALANCE_ENABLED]: false,
  [SERVICING_WEB_MF_COST_BASIS_ENABLED]: false,
  [SERVICING_WEB_MF_BENEFICIARIES_ENABLED]: false,
  [SERVICING_NAME_CHANGE_ENABLED]: false,
  [SERVICING_DONOR_ADVISED_FUNDS_DONATIONS_ENABLED]: false,
  [SERVICING_HOLIDAY_CHOICE_DOLLARS_ENABLED]: false,
  [SERVICING_CHOICE_DOLLARS_SIDE_NAV_ENABLED]: false,
  [SERVICING_GROUP_BILL_DETAILS_ENABLED]: false,
  [SERVICING_GROUP_BILL_HISTORY_ENABLED]: false,
  [TERM_EXPIRATION_COLOR_RED]: false,
  [SERVICING_GROUP_BILL_PAYMENTS_ENABLED]: false,
  [SERVICING_LIVE_GENEROUSLY_AWARD_ENABLED]: false,
  [SERVICING_ORDER_VOLUNTEER_KIT_ENABLED]: false,
  [SERVICING_BENEFICIARIES_ROUTE_ENABLED]: false,
  [SERVICING_ACTION_TEAM_ANNIVERSARY_ENABLED]: false,
  [SERVICING_NEW_TOWN_SUITABILITY_UPDATE_ROUTE_ENABLED]: false,
  [TMF_NEW_ACCOUNT_MANAGE_PREFERENCES_ENABLED]: false,
  [SERVICING_SSAT_PARTIAL_REALLOCATION_PERCENT_ENABLED]: false,
  [SERVICING_SSAT_PARTIAL_REALLOCATION_DOLLAR_ENABLED]: false,
  [SERVICING_SSAT_TOTAL_REALLOCATION_PERCENT_ENABLED]: false,
  [SERVICING_NATIVE_INVESTMENTS_YTD_ENABLED]: false,
  [SERVICING_NATIVE_STORE_REVIEWS_ENABLED]: false,
  [SERVICING_NATIVE_GRAPH_V2_ENABLED]: false,
  // eslint-disable-next-line @cspell/spellchecker
  [SERVICING_ENABLE_SHOW_NOBILLS]: false,
  [SERVICING_THRIVENT_CHARITABLE_LINK_ENABLED]: false,
  [SERVICING_THRIVENT_TRUST_LINK_ENABLED]: false,
  [SERVICING_IMMERSIVE_CHOICE_DOLLARS_ENABLED]: false,
  [SERVICING_ACTION_TEAMS_DASHBOARD_REDESIGN_ENABLED]: false,
  [SERVICING_CAUSE_KITS_ENABLED]: false,
  [SERVICING_CAUSE_KIT_NOTIFICATION_ENABLED]: false,
  [SERVICING_CAUSE_KIT_SOLAR_OUT_OF_STOCK_ENABLED]: false,
  [SERVICING_CAUSE_KIT_WATER_OUT_OF_STOCK_ENABLED]: false,
  [SERVICING_SSAT_PARTIAL_WITHDRAWAL_ENABLED]: false,
  [SERVICING_SSAT_ALLOCATE_FUTURE_PAYMENTS_ENABLED]: false,
  [SERVICING_GENEROSITY_SHIRT_VOTE_ENABLED]: false,
  [SERVICING_L1_ENHANCEMENTS]: false,
  [TMF_NEW_ACCOUNT_RESUME_APPLICATION_ENABLED]: false,
  [SERVICING_RMD_INFO_ENABLED]: false,
  [SERVICING_BENEFICIARIES_ELIGIBILITY_CHECK_ENABLED]: false,
};

export type Flags = keyof typeof defaultFlags;
export type FlagValues = Partial<typeof defaultFlags>;

// Can't import this from ./types as it creates a circular dependency and breaks on prod/stage build
const isFlagKey = (flagKey: string): flagKey is Flags =>
  flagKey in defaultFlags;
export const flagKeys = Object.keys(defaultFlags).filter(isFlagKey);

// 3. Add a description
export const flagMeta = {
  [SERVICING_DELETE_ACI_FUNDING_ACCOUNT_ENABLED]: {
    description: 'Allows a user to delete an ACI bank account.',
  },
  [SERVICING_FLAGS_PAGE_ENABLED]: {
    description: 'Show the flag page',
  },
  [SERVICING_FLAGS_E2E_TEST_ENABLED]: {
    description:
      'Flag explicitly for an e2e test to check that flags are working.',
  },
  [SERVICING_DASHBOARD_BILL_INDICATORS_ENABLED]: {
    description: 'Enable bill indicators on the dashboard',
  },
  [SERVICING_WEB_MF_AUTOMATIC_REDEMPTION_ENABLED]: {
    description:
      'Enable the "Set up automatic redemption" and "Manage automatic redemption" menu items in "Redeem" menu item',
  },
  [SERVICING_WEB_MF_SHEBANGS_PURCHASE_EXISTING_MUTATION_ENABLED]: {
    description:
      'Enable the new mutation from Shebangs for purchasing an existing fund',
  },
  [SERVICING_CHARITABLE_HISTORY_ENABLED]: {
    description:
      'Enable view "Charitable History" page and actions from the Generosity section',
  },
  [SERVICING_ADOBE_ENABLED]: {
    description: 'Adobe analytics for user tracking',
  },
  [SERVICING_VIEW_GROUP_BILLS_ENABLED]: {
    description:
      'View group bill payment history functionality. View due groups bills in billing and payment center',
  },
  [SERVICING_SETTINGS_SPARKLE]: {
    description: 'Adds a sparkle next to "Profile and Settings" for flag demo',
  },
  [SERVICING_GROUP_BILL_PAYMENTS_ENABLED]: {
    description: 'Enable payments for group bills feature',
  },
  [SERVICING_PAYMENT_HISTORY_DETAILS_ENABLED]: {
    description: 'Enable Payments History View Details button',
  },
  [SERVICING_WEB_MF_BANK_INFORMATION_ENABLED]: {
    description: 'Enable the Mutual Funds Bank Information link.',
  },
  [SERVICING_HOLIDAY_GIFT_GUIDE_ENABLED]: {
    description: 'Enables the Holiday Gift guide link out on the dashboard',
  },
  [SERVICING_WEB_MF_DISTRIBUTION_OPTIONS_ENABLED]: {
    description: 'Enable the Mutual Funds Distribution Options link.',
  },
  [SERVICING_WEB_MF_HISTORICAL_BALANCE_ENABLED]: {
    description: 'Enable the Mutual Funds Historical Balance link.',
  },
  [SERVICING_WEB_MF_COST_BASIS_ENABLED]: {
    description: 'Enable the Mutual Funds Cost Basis link.',
  },
  [SERVICING_WEB_MF_BENEFICIARIES_ENABLED]: {
    description: 'Enable the Mutual Funds beneficiaries feature.',
  },
  [SERVICING_NAME_CHANGE_ENABLED]: {
    description: 'allows users to edit last name',
  },
  [SERVICING_DONOR_ADVISED_FUNDS_DONATIONS_ENABLED]: {
    description: 'displays impact and giving funds on Generosity page',
  },
  [SERVICING_HOLIDAY_CHOICE_DOLLARS_ENABLED]: {
    description:
      'displays holiday choice dollars card which has 4 different variations',
  },
  [SERVICING_CHOICE_DOLLARS_SIDE_NAV_ENABLED]: {
    description: 'displays choice dollars card on side nav of dashboard',
  },
  [SERVICING_GROUP_BILL_DETAILS_ENABLED]: {
    description:
      'View group bill payment details. Some details included are outstandingGroupBills.',
  },
  [SERVICING_GROUP_BILL_HISTORY_ENABLED]: {
    description: 'View group bill payment history. Uses paidGroupBills.',
  },
  [TERM_EXPIRATION_COLOR_RED]: {
    description: 'Show Term expiration notification experiment in red',
  },
  [SERVICING_LIVE_GENEROUSLY_AWARD_ENABLED]: {
    description:
      'Displays Live Generously Award nomination card in dashboard side rail.',
  },
  [SERVICING_ORDER_VOLUNTEER_KIT_ENABLED]: {
    description:
      'Displays volunteer kit order CTA instead of nomination card in side rail for week of April 21-27.',
  },
  [SERVICING_UNSCHEDULED_PAYMENTS_ENABLED]: {
    description: 'Enables ability to make unscheduled payments',
  },
  [SERVICING_ENABLED_UNSCHEDULED_PAYMENT_QUERY_FIELDS]: {
    description: 'Enables fetching fields for unscheduled payment eligibility',
  },
  [SERVICING_BENEFICIARIES_ROUTE_ENABLED]: {
    description: 'Allows a user to access the Beneficiaries page in New Town.',
  },
  [SERVICING_ACTION_TEAM_ANNIVERSARY_ENABLED]: {
    description:
      'Displays action teams 10th anniversary card in dashboard side rail.',
  },
  [SERVICING_NEW_TOWN_SUITABILITY_UPDATE_ROUTE_ENABLED]: {
    description:
      'Allows a user to access the Suitability Update page in New Town.',
  },
  [TMF_NEW_ACCOUNT_MANAGE_PREFERENCES_ENABLED]: {
    description:
      'Displays manage preferences card for users that have a direct, complete mutual fund application',
  },
  [SERVICING_SSAT_PARTIAL_REALLOCATION_PERCENT_ENABLED]: {
    description:
      'Allow a user to access the SSAT Partial Reallocation by percent page in New Town.',
  },
  [SERVICING_SSAT_PARTIAL_REALLOCATION_DOLLAR_ENABLED]: {
    description:
      'Allow a user to access the SSAT Partial Reallocation by dollar page in New Town.',
  },
  [SERVICING_SSAT_TOTAL_REALLOCATION_PERCENT_ENABLED]: {
    description:
      'Allow a user to access the SSAT Total Reallocation by percent page in New Town.',
  },
  [SERVICING_NATIVE_INVESTMENTS_YTD_ENABLED]: {
    description:
      'Control the display of the YTD account value for the Grow card.',
  },
  [SERVICING_NATIVE_STORE_REVIEWS_ENABLED]: {
    description: 'Control the display of app store reviews',
  },
  [SERVICING_NATIVE_GRAPH_V2_ENABLED]: {
    description: 'Control the display of the new brokerage graph',
  },
  // eslint-disable-next-line @cspell/spellchecker
  [SERVICING_ENABLE_SHOW_NOBILLS]: {
    description:
      'Hides or shows products with no bills on billing and payments screen',
  },
  [SERVICING_THRIVENT_CHARITABLE_LINK_ENABLED]: {
    description:
      'Hides or shows external link to Thrivent Charitable, based on if the user has a Charitable account',
  },
  [SERVICING_THRIVENT_TRUST_LINK_ENABLED]: {
    description:
      'Hides or shows external link to Thrivent Trust, based on if the user has a Trust account',
  },
  [SERVICING_IMMERSIVE_CHOICE_DOLLARS_ENABLED]: {
    description: 'Enables the new out-of-drawer Choice Dollars experience',
  },
  [SERVICING_ACTION_TEAMS_DASHBOARD_REDESIGN_ENABLED]: {
    description:
      'Enables action team redesign for the action team dashboard and CTAs directing users to the action team dashboard',
  },
  [SERVICING_CAUSE_KITS_ENABLED]: {
    description: 'Enables Cause Kits feature',
  },
  [SERVICING_CAUSE_KIT_NOTIFICATION_ENABLED]: {
    description: 'Enables the Cause Kit notification on the dashboard',
  },
  [SERVICING_CAUSE_KIT_SOLAR_OUT_OF_STOCK_ENABLED]: {
    description:
      'Used to manually display if the Solar Cause Kit is out of stock',
  },
  [SERVICING_CAUSE_KIT_WATER_OUT_OF_STOCK_ENABLED]: {
    description:
      'Used to manually display if the Water Filter Cause Kit is out of stock',
  },
  [SERVICING_SSAT_PARTIAL_WITHDRAWAL_ENABLED]: {
    description:
      'Allow a user to access the SSAT Partial Withdrawal page in New Town.',
  },
  [SERVICING_SSAT_ALLOCATE_FUTURE_PAYMENTS_ENABLED]: {
    description:
      'Allow a user to access the SSAT Allocate Future Payments page in New Town.',
  },
  [SERVICING_GENEROSITY_SHIRT_VOTE_ENABLED]: {
    description:
      'Enables generosity shirt voting notification card on dashboard side nav.',
  },
  [SERVICING_L1_ENHANCEMENTS]: {
    description:
      'Enables L1 Enhancements for page layout and advisor card UI changes',
  },
  [TMF_NEW_ACCOUNT_RESUME_APPLICATION_ENABLED]: {
    description:
      'Displays card for users to resume their in-progress TMF NA application',
  },
  [SERVICING_RMD_INFO_ENABLED]: {
    description:
      'Hides or shows components related to RMD eligibility, withdrawal reminders, or RMD balances.',
  },
  [SERVICING_BENEFICIARIES_ELIGIBILITY_CHECK_ENABLED]: {
    description:
      'Enables eligibility call to conditionally render update beneficiary button.',
  },
};

// 4. Add your feature flag to launch darkly
// If you need access follow this guide:
// https://docs-main.icweb.stage.aws.tfcloud.corp/docs/guides/launchdarkly/useraccess/
