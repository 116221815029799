import { Flags, defaultFlags } from './flags';
import { QueryFunctionContext } from '@tanstack/react-query';

export type ContextWithMeta = Pick<QueryFunctionContext, 'meta'>;

export const FLAGS_QUERY = 'FLAGS_QUERY';

export const DEV_FLAGS_QUERY = 'DEV_FLAGS_QUERY';

export const isFlagKey = (flagKey: string): flagKey is Flags =>
  flagKey in defaultFlags;

export type ChannelContextKind =
  | {
      key: 'android' | 'ios';
      isWeb: false;
      isMobileApp: true;
    }
  | {
      key: 'web';
      isWeb: true;
      isMobileApp: false;
    };
