import { ClientError } from 'graphql-request';

export const ERROR_AUTH_TOKEN_NOT_PRESENT = 'Auth token not present';

export const isMissingAuthToken = (error: unknown) =>
  error instanceof Error && error.message === ERROR_AUTH_TOKEN_NOT_PRESENT;

export const isUnauthorized = (error: unknown) => {
  return error instanceof ClientError && error?.response?.status === 401;
};

export const isRequiredInteractionError = (error: unknown) =>
  error instanceof Error &&
  error.message.includes(
    'The request requires some interaction that is not allowed'
  );
