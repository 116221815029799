import { NavColumnWithLinks } from './shared/NavColumnWithLinks';
import { TargetProps } from './shared/styles';

const ABOUT_US_URL = 'https://www.thrivent.com/about-us';
const CONTACT_US_URL = 'https://www.thrivent.com/about-us/support';
const CAREERS_URL = 'https://careers.thrivent.com';
const THRIVENT_ACTION_TEAMS_URL =
  'https://www.thrivent.com/about-us/membership/thrivent-action-teams';
const NEWSROOM_URL = 'https://www.thrivent.com/newsroom';

export const getToKnowUsLinks: {
  name: string;
  url: string;
  target: TargetProps;
}[] = [
  { name: 'About us', url: ABOUT_US_URL, target: '_blank' },
  { name: 'Contact us', url: CONTACT_US_URL, target: '_blank' },
  { name: 'Careers', url: CAREERS_URL, target: '_blank' },
  {
    name: 'Thrivent Action Teams',
    url: THRIVENT_ACTION_TEAMS_URL,
    target: '_blank',
  },
  { name: 'Newsroom', url: NEWSROOM_URL, target: '_blank' },
];

export const GetToKnowUs = () => (
  <NavColumnWithLinks columnHeader="Get to know us" links={getToKnowUsLinks} />
);
