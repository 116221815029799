import {
  NavColumn,
  NavColumnHeader,
  NavList,
  NavListItem,
  TargetProps,
} from './styles';
import { useId } from 'react';

type ColumnLinks = {
  name: string;
  url: string;
  target: TargetProps;
};

export const NavColumnWithLinks = ({
  columnHeader,
  links,
  className,
}: {
  columnHeader: string;
  links: ColumnLinks[];
  className?: string;
}) => {
  const id = useId();

  return (
    <NavColumn className={className} aria-labelledby={id}>
      <NavColumnHeader id={id}>{columnHeader}</NavColumnHeader>
      <NavList>
        {links.map((link) => (
          <NavListItem
            key={link.url}
            href={link.url}
            target={link.target}
            title={link.name}
          />
        ))}
      </NavList>
    </NavColumn>
  );
};
