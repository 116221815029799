import { oidcClientSettings } from './config';
import { userUtilsFactory } from '@thrivent-web/authentication';

export const {
  fetchUserSessionInMiddleware,
  getMiddlewareRequestHeadersForUser,
  getUserAccessTokenFromBrowser,
  getConsumerIdFromBrowser: getConsumerIdFromBrowserOriginal,
  getUserAccessTokenFromRequestHeader,
  getUserInfoFromRequestHeader,
} = userUtilsFactory(oidcClientSettings) ?? {};

/**
 * Retrieves the access token for the user. Convert auth library error throwing logic to synchronous flow logic
 *
 * @async
 * @returns {Promise<string>} A promise that resolves with the access token.
 *          If the access token cannot be retrieved, an empty string is returned.
 */
export const getAccessToken = async () => {
  try {
    return await getUserAccessTokenFromBrowser();
  } catch {
    return '';
  }
};

let _consumerIdPromise: Promise<string> | undefined;

export const getConsumerIdFromBrowser = () => {
  if (_consumerIdPromise) return _consumerIdPromise;
  _consumerIdPromise = getConsumerIdFromBrowserOriginal();
  return _consumerIdPromise;
};
