// Dashboard Routes
export const DASHBOARD_ROUTE = '/dashboard';

// Generosity Routes
export const GENEROSITY_ROUTE = '/generosity';
export const GENEROSITY_DIRECT_CHOICE_DOLLARS_ROUTES = `/generosity?open=direct-choice-dollars`;
export const GENEROSITY_CHARITABLE_HISTORY_ROUTE =
  '/generosity/thrivent-charitable-history';
export const GENEROSITY_CHOICE_HISTORY_ROUTE = '/generosity/history';
export const GENEROSITY_IMMERSIVE_CHOICE_DOLLARS_ROUTE = `/choice-dollars`;

// Action Teams
export const ACTION_TEAMS_ROUTE = '/action-teams';
export const ACTION_TEAMS_APPLICATION_ROUTE = '/action-teams/application';
export const ACTION_TEAMS_REPORTING_ROUTE = '/action-teams/reporting';
export const ACTION_TEAMS_ERROR_ROUTE = '/action-teams/error';

// Billing & Payments Routes
export const PAYMENT_CENTER_ROUTE = '/billing-and-payments';
export const PAYMENT_CENTER_ADD_BANK_ACCOUNT_ROUTE = `${PAYMENT_CENTER_ROUTE}/accounts?open=add-bank-account`;
export const PAYMENT_CENTER_HISTORY_ROUTE = `${PAYMENT_CENTER_ROUTE}/history`;

// Profile Routes
export const INFORMATION_SHARING_ROUTE =
  '/profile-and-settings/information-sharing';
export const PERSONAL_INFORMATION_ROUTE = '/personal-information';
export const PROFILE_AND_SETTINGS_ROUTE = '/profile-and-settings';
export const COMMUNICATION_PREFERENCES_ROUTE =
  '/profile-and-settings/preferences';
export const INVESTOR_PROFILE_ROUTE = '/investor-profile';
export const INVESTOR_PROFILE_UPDATE_ROUTE = `${INVESTOR_PROFILE_ROUTE}/update-info`;

// Login Routes
export const LOGIN_ROUTE = '/authentication/login';

export * from './oldTownRoutes/index';

// Share Center Routes
export const SHARE_CENTER_ROUTE = '/share-center';
export const SHARE_CENTER_USER_INFO_ROUTE = '/share-center/user-info';

// Cause Kits Routes
export const CAUSE_KITS_ROUTE = '/cause-kits';
