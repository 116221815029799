import {
  enterFromLeft,
  enterFromRight,
  exitToLeft,
  exitToRight,
} from './animations';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { LinkOut } from '@thrivent-web/ui/icons';
import { colors, tablet } from '@thrivent/midwest-shared';
import { ArrowInclineRight, MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

export const NavMenuContent = styled(NavigationMenu.Content)`
  display: grid;
  grid-column-gap: 3.375rem;
  width: 100%;
  overflow: hidden;
  padding: 0 0.75rem;
  height: auto;
  gap: 1.5rem;

  @media (prefers-reduced-motion: no-preference) {
    transition: width 300ms, height 300ms, ease;
  }

  ${tablet} {
    gap: unset;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2.5rem 2.25rem 3.75rem 2.25rem;
    min-height: 18rem;
    grid-column-gap: 0.25rem;
    grid-template-columns: 30% 1fr 1fr;

    @media (prefers-reduced-motion: no-preference) {
      animation-duration: 250ms;
      animation-timing-function: ease;
      &[data-motion='from-start'] {
        animation-name: ${enterFromLeft};
      }
      &[data-motion='from-end'] {
        animation-name: ${enterFromRight};
      }
      &[data-motion='to-start'] {
        animation-name: ${exitToLeft};
      }
      &[data-motion='to-end'] {
        animation-name: ${exitToRight};
      }
    }
  }
`;

export const ContentSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.5rem;

  ${tablet} {
    gap: 1rem;
  }
`;

export const ContentSectionLabel = styled(MDSText.BodyMediumSm)`
  text-transform: uppercase;
  color: ${(p) => p.theme.midwestColors.textTertiary};
`;

export const NavMenuContentSectionWithSeparator = styled(ContentSection)`
  border: 0;
  margin: 0;
  padding-top: 0;

  ${tablet} {
    margin: 0;
    padding: 0 2rem 0 3.25rem;
    border-top: 0;
    border-left: solid 1px ${(p) => p.theme.midwestColors.neutralLightHeavy};
  }
`;

type NavMenuItemProps = {
  value: string;
  open?: boolean;
};

export const NavMenuItem = styled(NavigationMenu.Item)<NavMenuItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 3px
    ${(p) => (p.open ? p.theme.midwestColors.neutralDarkHeavy : 'transparent')};

  ${tablet} {
    padding-top: 0;
    margin-bottom: 0;
    height: 5rem;
    border-bottom: solid 3px
      ${(p) =>
        p.open ? p.theme.midwestColors.neutralDarkHeavy : 'transparent'};

    :hover,
    :focus {
      border-bottom: solid 3px
        ${(p) =>
          p.open === false
            ? colors.borderDefault
            : p.theme.midwestColors.neutralDarkHeavy};
    }
  }
`;

export const LinkOutIcon = styled((props) => <LinkOut {...props} />)`
  height: 1rem;
  width: 1rem;
  path {
    fill: ${(p) => p.theme.midwestColors.neutralDarkHeavy};
  }
`;

export const ArrowInclineRightIcon = styled((props) => (
  <ArrowInclineRight {...props} />
))`
  height: 1rem;
  width: 1rem;
  path {
    fill: #2453cc;
  }
`;

export const ContentLinkListItem = styled.li`
  display: flex;
  justify-content: left;
  align-items: center;
  list-style-type: none;
  padding: 0.25rem 0;
  margin-bottom: 1.5rem;

  ${tablet} {
    margin-bottom: 1rem;
  }
`;

export const NavMenuList = styled(NavigationMenu.List)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  margin: 0;

  ${tablet} {
    all: unset;
    display: flex;
    flex-direction: row;
    list-style: none;
    height: 5rem;
    gap: 1rem;
    > :last-child {
      margin-left: auto;
    }
  }
`;

export const SectionDivider = styled.hr`
  margin: 0;
  border-bottom: 1px solid ${(p) => p.theme.midwestColors.borderDefault};

  ${tablet} {
    display: none;
  }
`;
