import { NavColumnWithLinks } from './shared/NavColumnWithLinks';
import { TargetProps } from './shared/styles';

const FIND_AN_FA = 'https://local.thrivent.com';
const TAX_RESOURCE_CENTER_URL = 'https://www.thrivent.com/tax-resource-center';
const CLAIMS_URL = 'https://www.thrivent.com/claims';
const INSIGHTS_AND_GUIDANCE_URL = 'https://www.thrivent.com/insights';
const MOBILE_APP_URL = 'https://www.thrivent.com/mobile-app';
const THRIVENT_MAGAZINE_URL = 'https://www.thrivent.com/insights/magazine';
const MERCHANDISE_URL = 'https://www.mybrandmall.com/Thrivent';

export const resourceLinks: {
  name: string;
  url: string;
  target: TargetProps;
}[] = [
  { name: 'Find a financial advisor', url: FIND_AN_FA, target: '_blank' },
  {
    name: 'Tax Resource Center',
    url: TAX_RESOURCE_CENTER_URL,
    target: '_blank',
  },
  { name: 'Claims', url: CLAIMS_URL, target: '_blank' },
  {
    name: 'Insights and guidance',
    url: INSIGHTS_AND_GUIDANCE_URL,
    target: '_blank',
  },
  { name: 'Mobile app', url: MOBILE_APP_URL, target: '_blank' },
  { name: 'Thrivent Magazine', url: THRIVENT_MAGAZINE_URL, target: '_blank' },
  { name: 'Merchandise', url: MERCHANDISE_URL, target: '_blank' },
];

export const Resources = () => (
  <NavColumnWithLinks columnHeader="Resources" links={resourceLinks} />
);
