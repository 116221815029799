import {
  AccountsMenu,
  BillingsAndPaymentsMenu,
  ClaimsLink,
  ExploreMenu,
  GenerosityMenu,
  SupportMenu,
  UserMenu,
} from './Menus';
import { NavMenuItemEnum } from './shared/enums';
import { NavMenuList } from './shared/styles';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { ProductionIssueBanner } from '@servicing/molecules';
import { Logo } from '@thrivent-web/ui/icons';
import { tablet } from '@thrivent/midwest-shared';
import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const scaleIn = keyframes`
  from {
    transform: translateY(-2rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const scaleOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-2rem);
    opacity: 0;
  }
`;

const NavHeader = styled.header`
  display: none;
  border-bottom: 1px solid ${(p) => p.theme.midwestColors.borderDefault};
  grid-area: header;
  padding: 0 2rem;

  ${tablet} {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
  }
`;

const NavMenuRoot = styled(NavigationMenu.Root)`
  position: relative;
  grid-column: 2/3;
  z-index: 1;
  max-width: 75rem;
  width: 100%;
`;

const ViewportPosition = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  ${tablet} {
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: -1;
  }
`;

const StyledViewport = styled(NavigationMenu.Viewport)`
  border-bottom: solid 1px ${(p) => p.theme.midwestColors.neutralLightHeavy};
  position: relative;
  transform-origin: top center;
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.midwestColors.neutralLightStrong};
  overflow: hidden;
  background-color: ${(p) => p.theme.midwestColors.neutralLightWeak};
  height: var(--radix-navigation-menu-viewport-height);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.04), 0 1px 10px 0 rgba(0, 0, 0, 0.03),
    0 2px 4px -1px rgba(0, 0, 0, 0.05);

  @media (prefers-reduced-motion: no-preference) {
    transition: width 300ms ease, height 300ms ease;
    &[data-state='open'] {
      animation: 200ms ${scaleIn} ease;
    }
    &[data-state='closed'] {
      animation: 200ms ${scaleOut} ease;
    }
  }
`;

const UserMenuViewport = styled(StyledViewport)`
  width: 375px;
  border-bottom: none;
`;

const NavHeaderWithBanner = styled.div`
  display: none;
  ${tablet} {
    display: block;
  }
`;

const ThriventLogo = styled(Logo)`
  display: flex;
  align-self: center;
`;

const Viewport = ({ currentMenuItem }: { currentMenuItem: string }) => {
  if (currentMenuItem === NavMenuItemEnum.UserMenu) {
    return <UserMenuViewport />;
  }

  return <StyledViewport />;
};

export const Nav = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState('');

  const handleOnValueChange = (value: string) => {
    setCurrentMenuItem(value);
  };

  return (
    <NavHeaderWithBanner>
      <NavHeader>
        <NavMenuRoot onValueChange={handleOnValueChange}>
          <NavMenuList>
            <ThriventLogo title="logo" role="img" aria-hidden />
            <AccountsMenu currentMenuItem={currentMenuItem} />
            <BillingsAndPaymentsMenu currentMenuItem={currentMenuItem} />
            <GenerosityMenu currentMenuItem={currentMenuItem} />
            <ExploreMenu currentMenuItem={currentMenuItem} />
            <SupportMenu currentMenuItem={currentMenuItem} />
            <ClaimsLink />
            <UserMenu currentMenuItem={currentMenuItem} />
          </NavMenuList>
          <ViewportPosition>
            <Viewport currentMenuItem={currentMenuItem} />
          </ViewportPosition>
        </NavMenuRoot>
      </NavHeader>
      <ProductionIssueBanner />
    </NavHeaderWithBanner>
  );
};
