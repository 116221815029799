import jwtDecode from 'jwt-decode';

export type JWTForgerock = {
  exp: number;
  consumerId: string;
  sub: string;
  scope: Array<string>;
  auth_time: number;
  expires_in: number;
};

let _lastAccessToken: string;
let _currentJWT: JWTForgerock;

export const decodeAccessToken = (accessToken: string) => {
  if (accessToken === _lastAccessToken) return _currentJWT;

  _currentJWT = jwtDecode<JWTForgerock>(accessToken);
  _lastAccessToken = accessToken;

  return _currentJWT;
};
