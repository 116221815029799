import { keyframes } from 'styled-components';

export const enterFromRight = keyframes`
  from {
    transform: translateX(12.5rem);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const enterFromLeft = keyframes`
  from {
    transform: translateX(-12.5rem);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const exitToRight = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(12.5rem);
    opacity: 0;
  }
`;

export const exitToLeft = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-12.5rem);
    opacity: 0;
  }
`;
