import { MDSAlertModal } from './AlertModal';
import { MDSAlertModalProps } from './types';
import { useModal } from 'react-modal-hook';

export const useMDSAlertModal = ({
  onClickPrimary,
  onClickSecondary,
  ...rest
}: MDSAlertModalProps) => {
  const [showModal, hideModal] = useModal(() => (
    <MDSAlertModal
      isOpen={true}
      onClickPrimary={async () => {
        onClickPrimary && (await onClickPrimary());
        hideModal();
      }}
      onClickSecondary={async () => {
        onClickSecondary && (await onClickSecondary());
        hideModal();
      }}
      {...rest}
    />
  ));

  return { showModal, hideModal };
};
