export const formatPhoneNumber = (
  phoneNumber?: string | number | null
): string => {
  if (!phoneNumber) return '';
  const stringifiedPhoneNumber = phoneNumber.toString();

  if (stringifiedPhoneNumber.length === 11) {
    const firstPart = stringifiedPhoneNumber.substring(0, 1);
    const secondPart = stringifiedPhoneNumber.substring(1, 4);
    const thirdPart = stringifiedPhoneNumber.substring(4, 7);
    const fourthPart = stringifiedPhoneNumber.substring(7);

    return `+${firstPart} (${secondPart}) ${thirdPart}-${fourthPart}`;
  }

  if (stringifiedPhoneNumber.length === 10) {
    const firstPart = stringifiedPhoneNumber.substring(0, 3);
    const secondPart = stringifiedPhoneNumber.substring(3, 6);
    const thirdPart = stringifiedPhoneNumber.substring(6);

    return `(${firstPart}) ${secondPart}-${thirdPart}`;
  }

  if (stringifiedPhoneNumber.length === 7) {
    const firstPart = stringifiedPhoneNumber.substring(0, 3);
    const secondPart = stringifiedPhoneNumber.substring(3);

    return `${firstPart}-${secondPart}`;
  }

  return stringifiedPhoneNumber;
};
