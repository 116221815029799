// eslint-disable-next-line no-restricted-imports -- These are schema types for use in generated files. Don’t use these directly. Use @thrivent/supergraph-types, operations fragments, or resolver types instead.
import { Maybe } from '@thrivent/graphql';
import styled from 'styled-components';

const AdvisorImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

type AdvisorPictureAvatarProps = {
  url: string;
  plannerString: Maybe<string> | undefined;
};

export const AdvisorPictureAvatar = ({
  url,
  plannerString,
}: AdvisorPictureAvatarProps) => {
  return <AdvisorImg src={url} alt={plannerString || 'planner avatar'} />;
};
