import { getLaunchDarklyBrowserClient } from '../launchDarkly/getLaunchDarklyBrowserClient';
import { anonymousUserContext } from '../launchDarkly/useUserContextLD';
import { useQuery } from '@tanstack/react-query';

export const FLAGS_PROD_BANNER = 'FLAGS_PROD_BANNER';

export const useProdBannerFlag = () => {
  return useQuery({
    queryKey: [FLAGS_PROD_BANNER],
    queryFn: async () => {
      const client = await getLaunchDarklyBrowserClient(anonymousUserContext);

      if (!client) {
        return '';
      }

      return client.variation('SERVICING_PRODUCTION_ISSUE_BANNER_ENABLED', '');
    },
  });
};
