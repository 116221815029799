import { QueryCache, QueryClient } from '@tanstack/react-query';
import {
  ContextMetaBase,
  ContextMetaClient,
} from '@thrivent-web/logging-utils';
import { minutesToMilliseconds } from 'date-fns';

const STALE_TIME = 25;
const GC_TIME = 25;

let _client: QueryClient;

type QueryOptions = ContextMetaBase & {
  gcTime?: number;
  staleTime?: number;
  onQueryError: Required<
    ConstructorParameters<typeof QueryCache>
  >[0]['onError'];
};

export const makeQueryClient = (options: QueryOptions) => {
  const meta: ContextMetaClient = {
    launchDarklyClientBrowser: true,
    getConsumerId: options.getConsumerId,
    getAccessToken: options.getAccessToken,
    isLoggedIn: options.isLoggedIn,
    getTestName: options.getTestName,
    additionalHeaders: options.additionalHeaders,
  };

  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: minutesToMilliseconds(options?.staleTime ?? STALE_TIME),
        gcTime: minutesToMilliseconds(options?.gcTime ?? GC_TIME),
        meta,
        retry: 1, // default was 3
        refetchOnWindowFocus: false,
      },
      // not sure why this doesn't work :thinking:
      mutations: {
        meta,
      },
    },
    queryCache: new QueryCache({
      onError: options.onQueryError,
    }),
  });
};

/**
 * @deprecated Global singleton should not be used. Use makeQueryClient and persist with `const [queryClient] = useState(makeQueryClient(options))`
 */
export const getQueryClient = (
  options: QueryOptions,
  forceNewClient = false
) => {
  // Client is a singleton and should only be created once for actual code deploy
  // Allow new client to be created for jest unit tests (so mswTestId can be changed between tests)
  if (forceNewClient || !_client) {
    _client = makeQueryClient(options);
  }
  return _client;
};
