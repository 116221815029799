import { BillStatuses } from './billTypes';
import { BillStatus } from '@thrivent/supergraph-types';

export const getBillStatus = (
  statuses: BillStatuses
): BillStatus | undefined => {
  const { hasBillsPending, hasBillsScheduled, hasBillsDue, hasBillsPastDue } =
    statuses;
  if (hasBillsPastDue) {
    return 'PAST_DUE';
  }
  if (hasBillsDue) {
    return 'DUE';
  }
  if (hasBillsScheduled) {
    return 'SCHEDULED';
  }
  if (hasBillsPending) {
    return 'PENDING';
  }

  return;
};
