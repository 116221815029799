import { useLicensePlate } from '@thrivent-web/license-plate';

export const LicensePlate = () => {
  const licensePlate = useLicensePlate();
  const formattedLicensePlate = licensePlate?.replace(
    /(\w{1})(\w{3})(\w{3})/,
    '$1-$2-$3'
  );
  return <div>{formattedLicensePlate}</div>;
};
