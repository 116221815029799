import {
  CloseButton,
  CloseButtonIcon,
  ContentContainer,
  IconWrapper,
  SystemAlertRoot,
  getIconAndColor,
} from './SystemAlert.styles';
import { SystemAlertProps } from './types';
import { cloneElement, forwardRef } from 'react';

/**
 * System alerts are conspicuous. Use them to broadcast system-level changes or conditions.
 */
export const MDSSystemAlert = forwardRef<HTMLDivElement, SystemAlertProps>(
  (
    {
      children,
      type,
      sticky = false,
      closeButton = false,
      zIndex = 1300,
      closeOnClick,
      ...rest
    }: SystemAlertProps,
    ref
  ) => {
    const { Icon, color } = getIconAndColor(type);
    const ariaRole =
      type === 'informative' || type === 'warning'
        ? 'region'
        : type === 'positive'
        ? 'status'
        : 'alert';

    return (
      <SystemAlertRoot
        ref={ref}
        {...rest}
        role={ariaRole}
        $type={type}
        $sticky={sticky}
        $zIndex={zIndex}
      >
        <IconWrapper $color={color}>
          <Icon />
        </IconWrapper>

        <ContentContainer>
          {cloneElement(children, { type, notificationType: 'system-alert' })}
        </ContentContainer>

        {!!closeButton && (
          <CloseButton onClick={closeOnClick}>
            <CloseButtonIcon $type={type} />
          </CloseButton>
        )}
      </SystemAlertRoot>
    );
  }
);

MDSSystemAlert.displayName = 'MDSSystemAlert';
