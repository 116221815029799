import { ButtonIconContainer } from './Button.styles';
import { border, colors } from '@thrivent/midwest-shared';
import { FC, ReactNode, SVGProps } from 'react';
import { css } from 'styled-components';

export const renderButtonIcon = (
  Icon: FC<SVGProps<SVGSVGElement>>,
  large: boolean,
  iconOnly = false,
  anchor = false
) => {
  return (
    <ButtonIconContainer $large={large} iconOnly={iconOnly} $anchor={anchor}>
      <Icon role="presentation" />
    </ButtonIconContainer>
  );
};

export const renderIconsAndText = (
  iconAlignment: 'left' | 'right',
  children: ReactNode,
  Icon?: FC<SVGProps<SVGSVGElement>>,
  anchor = false
) => {
  return (
    <>
      {Icon &&
        iconAlignment === 'left' &&
        renderButtonIcon(Icon, false, false, anchor)}
      {children}
      {Icon &&
        iconAlignment === 'right' &&
        renderButtonIcon(Icon, false, false, anchor)}
    </>
  );
};
