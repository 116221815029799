import {
  EPHEMERAL_REDIRECT_COOKIE_NAME,
  USER_ACCESS_TOKEN_REQUEST_HEADER,
  USER_AUTHENTICATION_STATUS_REQUEST_HEADER,
  USER_CONSUMER_ID_REQUEST_HEADER,
  USER_INFO_REQUEST_HEADER,
  USER_SESSION_ERROR_REQUEST_HEADER,
} from '../constants';
import { memoizedJwtDecode } from '../lib/memoizeJwtDecode';
import { OAuthClient } from './OAuthClient';
import { OidcClientUser, UserManager } from './imports';
import type { OAuthClientSettings, UserProfile } from './types';
import { logger } from '@thrivent-web/logging-utils';
import { IncomingHttpHeaders } from 'http';
import type { GetServerSidePropsContext } from 'next';
import type { NextRequest } from 'next/server';

export type User = AuthenticatedUser | UnauthenticatedUser;

export type AuthenticatedUser = {
  accessToken: string;
  idToken: string;
  profile: UserProfile;
  isAuthenticated: true;
  consumerId: string;
};

export type UnauthenticatedUser = {
  authenticationError: string;
  isAuthenticated: false;
};

const sessionCookieKeys = [
  '1066bf681e55175', // dev
  'a6d03d08b8d2eb7', // stage
  'a2656c2f24435e8', // prod
  EPHEMERAL_REDIRECT_COOKIE_NAME,
];

/**
 * Uses "silent" signin method to check if passed SSO cookie is associated with an active Forgerock session
 *
 * @param cookieHeader string
 * @param client OAuthClient
 * @param url string
 * @returns Promise<User>
 */
const getUserSSR = async (
  cookieHeader: string,
  client: OAuthClient,
  requestOrigin: string
): Promise<User> => {
  const signInRequest = await client.createSigninRequest({
    response_type: 'code',
    response_mode: 'query',
    prompt: 'none',
  });

  const signinHostname = new URL(signInRequest.url).hostname;
  const isEphemeral = signinHostname.includes('oauth-redirect-handler');

  const sessionRequestCookiesObj = cookieHeader
    .split(';')
    .filter((cookie) => {
      const [key, value] = cookie.split('=');
      return !!key && !!value;
    })
    .reduce((agg, cookie) => {
      const [key, value] = cookie.split('=');
      return { ...agg, [key.trim()]: value.trim() };
    }, {} as Record<string, string>);

  if (
    isEphemeral &&
    !sessionRequestCookiesObj[EPHEMERAL_REDIRECT_COOKIE_NAME] &&
    requestOrigin
  ) {
    sessionRequestCookiesObj[EPHEMERAL_REDIRECT_COOKIE_NAME] = requestOrigin;
  }

  const sessionRequestCookie = Object.entries(sessionRequestCookiesObj)
    .filter(([key]) => sessionCookieKeys.includes(key))
    .map(([key, value]) => `${key}=${value}`)
    .join('; ');

  if (!sessionRequestCookie) {
    return {
      isAuthenticated: false,
      authenticationError: 'no session found',
    };
  }

  try {
    const resp = await fetch(signInRequest.url, {
      headers: {
        cookie: sessionRequestCookie,
      },
      redirect: 'manual',
    });

    if (resp.ok) {
      throw new Error(
        'Something is not configured correctly as this should not respond with a 200.'
      );
    }

    const redirectLocationHeader = resp.headers.get('location');
    if (redirectLocationHeader) {
      const { searchParams } = new URL(redirectLocationHeader);

      const error_description = searchParams.get('error_description');
      if (error_description) {
        return {
          authenticationError: error_description,
          isAuthenticated: false,
        };
      }
      try {
        const user = await client.processSigninResponse(redirectLocationHeader);
        return {
          accessToken: user.access_token,
          idToken: user.id_token as string,
          profile: user.profile,
          isAuthenticated: true,
          consumerId: memoizedJwtDecode<ForgerockAccessTokenClaims>(
            user.access_token
          ).consumerId,
        };
      } catch (error) {
        logger.debug('Authentication Library | getUserSSR', error);
        return {
          authenticationError:
            error instanceof Error ? error.message : JSON.stringify(error),
          isAuthenticated: false,
        };
      }
    }

    return {
      authenticationError: '',
      isAuthenticated: false,
    };
  } catch (error) {
    logger.debug('Authentication Library | getUserSSR', error);
    throw error;
  }
};

/**
 * Many oauth flow functions will not work in the server context but library
 * logging does not yet make that clear. This function used as a warning when
 * browser functions are inadvertently called in the server context.
 */
const ensureBrowserContext = () => {
  if (typeof window === 'undefined') {
    throw new Error('This function must only be run in browser context');
  }
};

type ForgerockAccessTokenClaims = {
  consumerId: string;
};

const promiseWithRetry = async <T>(
  fn: () => Promise<T>,
  retryCount = 0,
  maxRetries = 2
): Promise<T> => {
  try {
    const result = await fn();
    return result;
  } catch (error) {
    logger.error(
      `Authentication Library | promiseWithRetry failure | ${fn.name} | retry ${retryCount} of ${maxRetries}`,
      error
    );
    if (retryCount >= maxRetries) {
      return Promise.reject(error);
    }
    return promiseWithRetry(fn, retryCount + 1, maxRetries);
  }
};

/**
 * Factory function to create helper functions based on the passed OIDC Config
 */
export function userUtilsFactory(config: OAuthClientSettings) {
  const client = new OAuthClient(config);
  const userManager = new UserManager(config);

  let _getUserPromise: ReturnType<typeof userManager.getUser> | null;
  const getUser = async () => {
    if (_getUserPromise) return _getUserPromise;
    _getUserPromise = userManager.getUser().finally(() => {
      setTimeout(() => {
        _getUserPromise = null;
      }, 5000);
    });
    return _getUserPromise;
  };

  let _signinSilentPromise: ReturnType<typeof userManager.signinSilent> | null;

  const signinSilentWrapper = async () => {
    try {
      const user = await userManager.signinSilent();
      return user;
    } catch (error: unknown) {
      if (
        error instanceof Error &&
        error.message.includes(
          'The request requires some interaction that is not allowed.'
        )
      ) {
        // ignore auth check
        return null;
      }
      logger.debug('Authentication Library | signinSilent', error);
      throw error;
    }
  };

  const signinSilent = async () => {
    if (_signinSilentPromise) return _signinSilentPromise;
    // Allow for retry on failures to account for potential network issues
    _signinSilentPromise = promiseWithRetry<OidcClientUser | null>(
      signinSilentWrapper,
      0,
      2
    ).finally(() => {
      setTimeout(() => {
        _signinSilentPromise = null;
      }, 5000);
    });
    return _signinSilentPromise;
  };

  const returnObj = {
    /**
     * Used in NextJS middleware to fetch authentication information based on
     * current session if one exists.
     *
     * @param request NextRequest
     * @returns User
     */
    fetchUserSessionInMiddleware: async (
      request: NextRequest
    ): Promise<User> => {
      const cookieHeader = request.headers.get('cookie');
      if (!cookieHeader) {
        return {
          isAuthenticated: false,
          authenticationError: 'no session found',
        };
      }
      const origin = new URL(
        request.url ?? '/',
        `https://${request.headers.get('host') ?? 'localhost'}`
      ).origin;
      return getUserSSR(cookieHeader, client, origin);
    },

    /**
     * Generates an object containing all headers needed for get*FromRequestHeader functions.
     *
     *
     * @param user User
     * @returns Record<string, string>
     */
    getMiddlewareRequestHeadersForUser: (user: User) => {
      return {
        [USER_AUTHENTICATION_STATUS_REQUEST_HEADER]: JSON.stringify(
          user.isAuthenticated
        ),
        [USER_CONSUMER_ID_REQUEST_HEADER]: user.isAuthenticated
          ? memoizedJwtDecode<ForgerockAccessTokenClaims>(user.accessToken)
              .consumerId
          : '',
        [USER_INFO_REQUEST_HEADER]: JSON.stringify(user),
        [USER_ACCESS_TOKEN_REQUEST_HEADER]: user.isAuthenticated
          ? user.accessToken
          : '',
        [USER_SESSION_ERROR_REQUEST_HEADER]: user.isAuthenticated
          ? ''
          : user.authenticationError,
      };
    },

    /**
     * Used in NextJS getServerSideProps functions to fetch authentication
     * information based on current session if one exists.
     *
     * @param request NextRequest
     * @returns User
     */
    fetchUserSessionInGetServerSideProps: async (
      context: GetServerSidePropsContext
    ): Promise<User> => {
      const cookieHeader = context.req.headers['cookie'];
      if (!cookieHeader) {
        return {
          isAuthenticated: false,
          authenticationError: 'no session found',
        };
      }
      const origin = new URL(
        context.req.url ?? '/',
        `https://${context.req.headers.host ?? 'localhost'}`
      ).origin;
      return getUserSSR(cookieHeader, client, origin);
    },

    /**
     * Wrapper function for getting user information outside of react context
     * See: https://github.com/authts/react-oidc-context/tree/v2.3.1#call-a-protected-api
     *
     * @returns User
     */
    getUserInfoFromBrowser: async (): Promise<User> => {
      ensureBrowserContext();
      const user = await getUser();
      if (!user) {
        return {
          isAuthenticated: false,
          authenticationError: '',
        };
      }
      if (!user.id_token || !user.access_token) {
        const error = new Error('Invalid auth');
        logger.debug('Authentication Library | getUserInfoFromBrowser', error);
        throw error;
      }

      const accessTokenClaims = memoizedJwtDecode<ForgerockAccessTokenClaims>(
        user.access_token
      );
      return {
        ...user,
        isAuthenticated: true,
        idToken: user.id_token,
        accessToken: user.access_token,
        consumerId: accessTokenClaims.consumerId,
      };
    },

    /**
     * Get consumerId for user with current session.
     * If local authentication is valid, returns current local state.
     * If local authentication is expired, attempts silent login to check for current session.
     * Consumer ID returned is the consumerId accessToken claim.
     *
     * @returns string
     */
    getConsumerIdFromBrowser: async (): Promise<string> => {
      ensureBrowserContext();
      const accessToken =
        (await getUser())?.access_token ?? (await signinSilent())?.access_token;
      if (accessToken) {
        return memoizedJwtDecode<ForgerockAccessTokenClaims>(accessToken)
          ?.consumerId;
      }

      const error = new Error('No active user session found');
      logger.debug('Authentication Library | getConsumerIdFromBrowser', error);
      throw error;
    },

    /**
     * Get accessToken for user with current session.
     * If local authentication is valid, returns current local state.
     * If local authentication is expired, attempts silent login to check for current session.
     *
     * @returns string
     */
    getUserAccessTokenFromBrowser: async (): Promise<string> => {
      ensureBrowserContext();
      const accessToken =
        (await getUser())?.access_token ?? (await signinSilent())?.access_token;
      if (accessToken) return accessToken;

      const error = new Error('No active user session found');
      logger.debug(
        'Authentication Library | getUserAccessTokenFromBrowser',
        error
      );
      throw error;
    },

    /**
     * Used in server render context to extract value from specific header.
     *
     * @returns string
     */
    getUserAccessTokenFromRequestHeader: (
      headers: IncomingHttpHeaders
    ): string => {
      const token = headers[USER_ACCESS_TOKEN_REQUEST_HEADER];
      if (!token || typeof token !== 'string') {
        const error = new Error('User token not found in headers');
        logger.debug(
          'Authentication Library | getUserAccessTokenFromRequestHeader',
          error
        );
        throw error;
      }
      return token;
    },

    /**
     * Used in server render context to extract value from specific header.
     *
     * @returns User
     */
    getUserInfoFromRequestHeader: (headers: IncomingHttpHeaders): User => {
      const userInfo = headers[USER_INFO_REQUEST_HEADER];
      if (typeof userInfo !== 'string') {
        const error = new Error('User info not found in headers');
        logger.debug(
          'Authentication Library | getUserInfoFromRequestHeader',
          error
        );
        throw error;
      }
      const info: User = JSON.parse(userInfo);
      if (info.isAuthenticated) {
        const accessTokenClaims = memoizedJwtDecode<ForgerockAccessTokenClaims>(
          info.accessToken
        );
        const consumerId = accessTokenClaims.consumerId;
        return { ...info, consumerId };
      }
      return info;
    },

    /**
     * Used in server render context to extract value from specific header.
     *
     * @returns boolean
     */
    getUserAuthenticationStateFromRequestHeader: (
      headers: IncomingHttpHeaders
    ) => {
      const userAuthenticationStatus =
        headers[USER_AUTHENTICATION_STATUS_REQUEST_HEADER];
      if (typeof userAuthenticationStatus !== 'string') {
        const error = new Error(
          'User authentication status not found in headers'
        );
        logger.debug(
          'Authentication Library | getUserAuthenticationStateFromRequestHeader',
          error
        );
        throw error;
      }
      return userAuthenticationStatus === 'true';
    },
  };
  return returnObj;
}
