import { fontFamilyFallback, system } from '@thrivent/midwest-shared';

/**
 * A note on terms:
 * auth - intended for post login experience i.e. servicing
 * brand - intended for pre login experiences i.e. exploration, discovery applications
 * mc - intended for money canvas
 */

export const primaryFont = `${system.fontFamily['primary'].value}, ${fontFamilyFallback.primary}`;
export const secondaryFont = `${system.fontFamily['secondary'].value}, ${fontFamilyFallback.secondary}`;

export interface MidwestTypographyToken {
  font: string;
  rem: string;
  weight: string;
  lineHeight: string;
}

export const textBodyHeavyDefault: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const textBodyHeavySm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['sm']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const textBodyMediumDefault: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['md']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const textBodyMediumSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['sm']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const textBodyMediumXs: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['sm']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const textBodyRegDefault: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['reg'].value,
  rem: system.size['md']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const textBodyRegSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['reg'].value,
  rem: system.size['sm']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const textMiniDefault: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['reg'].value,
  rem: system.size['sm']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextDesktopHeroXl: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['xxl']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextDesktopHero: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['xl']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextDesktopTitleXl: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['xl']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextDesktopTitleLg: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextDesktopTitleMd: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextDesktopTitleSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextDesktopDataLg: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['xl']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextDesktopDataMd: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextDesktopDataSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextMobileHeroXl: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['lg']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextMobileHero: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['lg']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextMobileTitleXl: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextMobileTitleLg: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextMobileTitleMd: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextMobileTitleSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextMobileDataLg: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['lg']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextMobileDataMd: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['lg']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const authTextMobileDataSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['md']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextDesktopHeroLg: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['xxl']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextDesktopHeroMd: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['xl']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextDesktopHeroSm: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['lg']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextDesktopTitleLg: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextDesktopTitleMd: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextDesktopTitleSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextDesktopSubtitle: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextDesktopSubtitleMd: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['md']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextDesktopRegularDefault: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['reg'].value,
  rem: system.size['md']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextMobileHeroLg: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['xl']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextMobileHeroMd: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['lg']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextMobileHeroSm: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['lg']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextMobileTitleLg: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextMobileTitleMd: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextMobileTitleSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const brandTextMobileSubtitle: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['md']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextDataXl: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['xxl']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextDataLg: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['xxl']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextDataMd: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['xl']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextDataSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['lg']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextDataXs: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['lg']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextHeadingHeroLg: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['xxl']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextHeadingHeroMd: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['xl']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextHeadingHeroSm: MidwestTypographyToken = {
  font: system.fontFamily['secondary'].value,
  weight: system.fontWeight['secondary']['reg'].value,
  rem: system.size['lg']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextHeadingTitleXl: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['04'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextHeadingTitleLg: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextHeadingTitleMd: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['lg']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextHeadingTitleSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextHeadingSubtitle: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['reg'].value,
  rem: system.size['md']['03'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextBodyHeavyDefault: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextBodyHeavySm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['heavy'].value,
  rem: system.size['md']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextBodyMediumDefault: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['md']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextBodyMediumSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['medium'].value,
  rem: system.size['md']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextBodyRegDefault: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['reg'].value,
  rem: system.size['md']['02'].value,
  lineHeight: system.lineHeight['md'].value,
};

export const mcTextBodyRegSm: MidwestTypographyToken = {
  font: system.fontFamily['primary'].value,
  weight: system.fontWeight['primary']['reg'].value,
  rem: system.size['md']['01'].value,
  lineHeight: system.lineHeight['md'].value,
};
