import { NavMenuLink, NavMenuTriggerWithCaret } from '../shared/components';
import { NavMenuItemEnum } from '../shared/enums';
import {
  ContentSection,
  ContentSectionLabel,
  LinkOutIcon,
  NavMenuContent,
  NavMenuContentSectionWithSeparator,
  NavMenuItem,
  SectionDivider,
} from '../shared/styles';
import { NavMenuProps } from '../shared/types';
import { MDSAnchorButton } from '@midwest/web/button';
import {
  useActionTeamsQuery,
  useCauseKitEligibilityQuery,
  useDashboard,
} from '@servicing/data-queries';
import { isMemberCauseKitsEligible } from '@servicing/molecules';
import {
  ACTION_TEAMS_ROUTE,
  CAUSE_KITS_ROUTE,
  GENEROSITY_ROUTE,
} from '@servicing/util-routes';
import {
  SERVICING_CAUSE_KITS_ENABLED,
  SERVICING_CAUSE_KIT_SOLAR_OUT_OF_STOCK_ENABLED,
  SERVICING_CAUSE_KIT_WATER_OUT_OF_STOCK_ENABLED,
  useFlag,
} from '@thrivent-web/servicing/feature-management';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

export const THRIVENT_ACTION_TEAMS_URL =
  'https://www.thrivent.com/about-us/membership/thrivent-action-teams';
export const THRIVENT_CHARITABLE_URL = 'https://www.thriventcharitable.com/';
export const THRIVENT_CHOICE_URL =
  'https://www.thrivent.com/what-we-offer/generosity/thrivent-choice.html';
export const HABITAT_FOR_HUMANITY_URL =
  'https://www.thrivent.com/about-us/membership/habitat-for-humanity';

const DonateButton = styled(MDSAnchorButton).attrs({
  variant: 'secondary',
  weight: 'subtle',
})`
  width: 7.5rem;
`;

export const GenerosityMenu = ({ currentMenuItem = '' }: NavMenuProps) => {
  const actionTeam = useActionTeamsQuery({ input: { pageCount: 1 } });
  const actionTeamHistory =
    actionTeam?.data?.client?.membershipDetail?.actionTeamSearch?.actionTeams ??
    [];
  const hasActionTeamHistory = actionTeamHistory.length > 0;

  const { data } = useDashboard();
  const membershipType =
    data?.client?.profile?.membershipDetail?.membershipType;
  const isCauseKitEligible = isMemberCauseKitsEligible(membershipType);
  const isCauseKitsEnabled = useFlag(SERVICING_CAUSE_KITS_ENABLED);
  const isSolarOutOfStock = useFlag(
    SERVICING_CAUSE_KIT_SOLAR_OUT_OF_STOCK_ENABLED
  );
  const isWaterOutOfStock = useFlag(
    SERVICING_CAUSE_KIT_WATER_OUT_OF_STOCK_ENABLED
  );
  const areBothOutOfStock = isSolarOutOfStock && isWaterOutOfStock;

  return (
    <NavMenuItem
      value={NavMenuItemEnum.GenerosityMenu}
      open={NavMenuItemEnum.GenerosityMenu === currentMenuItem}
    >
      <NavMenuTriggerWithCaret>Generosity</NavMenuTriggerWithCaret>
      <NavMenuContent>
        <ContentSection>
          <ContentSectionLabel>Your impact</ContentSectionLabel>
          <NavMenuLink
            name="Generosity Overview"
            url={GENEROSITY_ROUTE}
            target="_self"
          />
          {hasActionTeamHistory ? (
            <NavMenuLink
              name="Thrivent Action Teams"
              url={ACTION_TEAMS_ROUTE}
              target="_self"
            />
          ) : null}
          {isCauseKitsEnabled && isCauseKitEligible && !areBothOutOfStock && (
            <CauseKitLink />
          )}
          <NavMenuLink
            name="Thrivent Choice&reg;"
            icon={<LinkOutIcon />}
            url={THRIVENT_CHOICE_URL}
            target="_blank"
          />
        </ContentSection>
        <SectionDivider />
        <NavMenuContentSectionWithSeparator>
          <ContentSectionLabel>how we give back</ContentSectionLabel>
          <ContentSection>
            {!hasActionTeamHistory && (
              <NavMenuLink
                name="Thrivent Action Teams"
                icon={<LinkOutIcon />}
                url={THRIVENT_ACTION_TEAMS_URL}
                target="_blank"
              />
            )}
            <NavMenuLink
              name="Thrivent Charitable Impact and Investing&reg;"
              icon={<LinkOutIcon />}
              url={THRIVENT_CHARITABLE_URL}
              target="_blank"
            />
            <NavMenuLink
              name="Habitat for Humanity partnership"
              icon={<LinkOutIcon />}
              url={HABITAT_FOR_HUMANITY_URL}
              target="_blank"
            />
          </ContentSection>
        </NavMenuContentSectionWithSeparator>
        <SectionDivider />
        <NavMenuContentSectionWithSeparator>
          <div>
            <MDSText.TitleMd>Support your favorite cause</MDSText.TitleMd>
            <MDSText.BodyRegDefault>
              Search 45,000+ organizations in our directory, or recommend a new
              one.
            </MDSText.BodyRegDefault>
          </div>
          <DonateButton
            aria-label="Donate - opens in a new tab"
            target="_blank"
            href={
              process.env['NEXT_PUBLIC_SERVICING_MAKE_A_PERSONAL_DONATION'] ||
              ''
            }
          >
            Donate
          </DonateButton>
        </NavMenuContentSectionWithSeparator>
        <SectionDivider />
      </NavMenuContent>
    </NavMenuItem>
  );
};

const CauseKitLink = () => {
  const { data: causeKitData } = useCauseKitEligibilityQuery();
  const hasCauseKitsRemaining =
    causeKitData?.client?.membershipDetail?.isEligibleForCauseKit;

  if (!hasCauseKitsRemaining) return null;

  return (
    <NavMenuLink
      analyticsId="L1-Cause-kit-MM-Link"
      name="Thrivent Cause Kit™"
      url={CAUSE_KITS_ROUTE}
      target="_self"
    />
  );
};
