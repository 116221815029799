// eslint-disable-next-line no-restricted-imports -- These are schema types for use in generated files. Don’t use these directly. Use @thrivent/supergraph-types, operations fragments, or resolver types instead.
import { CurrentSettings } from '@thrivent/graphql';

export type AdobeDataLayer = {
  _thrivent: {
    client: {
      id: {
        consumer: string;
        thrivent?: string;
      };
    };
  };
  identityMap: {
    ECID: [{ id: string | undefined }];
  };
  mobilePhone?: {
    number: string;
  };
  person?: {
    name: {
      firstName: string;
      lastName: string;
    };
  };
  personalEmail?: {
    address: string;
  };
};

type AdobeConfig = {
  consumerId: string;
  memberId?: string | null;
  ecId?: string;
  settings?: CurrentSettings;
};
export function getAdobeDataLayerData({
  consumerId,
  ecId,
}: AdobeConfig): AdobeDataLayer {
  return {
    _thrivent: {
      client: {
        id: {
          consumer: consumerId,
          /* PII data is being used blueConic and not needed for adobe at this time
          thrivent: memberId,
          */
        },
      },
    },
    identityMap: {
      ECID: [{ id: ecId }],
    },
    /* PII data is being used in blueConic and not needed at this time.
    // Relevant slack thread: https://thrivent-engineering.slack.com/archives/C052HN8PNUB/p1682957183167749
    mobilePhone: {
      number: settings.contactInfo?.mobilePhone || '',
    },
    person: {
      name: {
        firstName: settings.firstName || '',
        lastName: settings.legalSurname || '',
      },
    },
    personalEmail: {
      address: settings.contactInfo?.email || '',
    },
  */
  };
}
