import { InsuranceType, Maybe } from '@thrivent/supergraph-types';

const healthTypes: InsuranceType[] = ['LTC', 'DI', 'MEDICARE'];
const careForwardTypes: InsuranceType[] = ['WLLTC'];

const URL_SEGMENTS = ['health', 'careforward', 'life'] as const;
type ProtectUrlSegment = (typeof URL_SEGMENTS)[number];

export const getProtectUrlSegment = (
  type: Maybe<InsuranceType> | undefined
): ProtectUrlSegment => {
  // default to 'health' if undefined 🤷
  if (!type || healthTypes.includes(type)) {
    return 'health';
  } else if (careForwardTypes.includes(type)) {
    return 'careforward';
  }
  return 'life';
};
