import { system } from '@thrivent/midwest-shared';

// Dynamically create the url to load the fonts from Google Fonts from all the fonts and weights in system
// Example: https://fonts.googleapis.com/css2?family=Inter:wght@400;530;630&family=Libre%20Baskerville:wght@400&display=swap
const fontFamilyString = Object.keys(system.fontWeight)
  .map((type) => {
    const family = system.fontFamily[type].value;
    // To load correctly weights must be unique and sorted
    const weightSet = [
      ...new Set(
        Object.values(system.fontWeight[type])
          .map((weight) => parseInt(weight.value))
          .sort((a, b) => a - b)
      ),
    ];
    return (
      'family=' +
      encodeURIComponent(family).concat(`:wght@${weightSet.join(';')}`)
    );
  })
  .join('&');

/**
 * Shared NextJS component to load required fonts from Google Fonts CDN
 * Note: next/head not used due to https://nextjs.org/docs/messages/no-stylesheets-in-head-component
 */
export const FontLoader = () => {
  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href={`https://fonts.googleapis.com/css2?${fontFamilyString}&display=swap`}
        rel="stylesheet"
      />
    </>
  );
};
