import * as tokens from './generatedTokens';

export type MidwestColors = {
  black: string;
  white: string;

  identityBrandPrimaryFaint: string;
  identityBrandPrimaryWeak: string;
  identityBrandPrimaryMedium: string;
  identityBrandPrimaryStrong: string;
  identityBrandPrimaryHeavy: string;
  identityBrandPrimaryDense: string;
  identityBrandSecondary: string;

  identityBrandTertiary: string;
  identityBrandTertiaryFaint: string;
  identityBrandTertiaryStrong: string;
  identityBrandTertiaryHeavy: string;

  sentimentNegativeFaint: string;
  sentimentNegativeWeak: string;
  sentimentNegativeMedium: string;
  sentimentNegativeStrong: string;
  sentimentNegativeHeavy: string;
  sentimentNegativeDense: string;

  sentimentSuccessFaint: string;
  sentimentSuccessWeak: string;
  sentimentSuccessMedium: string;
  sentimentSuccessStrong: string;
  sentimentSuccessHeavy: string;
  sentimentSuccessDense: string;

  sentimentWarningFaint: string;
  sentimentWarningWeak: string;
  sentimentWarningMedium: string;
  sentimentWarningStrong: string;
  sentimentWarningHeavy: string;
  sentimentWarningDense: string;

  neutralLightWeak: string;
  neutralLightMedium: string;
  neutralLightStrong: string;
  neutralLightHeavy: string;

  neutralDarkWeak: string;
  neutralDarkMedium: string;
  neutralDarkStrong: string;
  neutralDarkHeavy: string;

  textPrimary: string;
  textSecondary: string;
  textTertiary: string;
  textDisabled: string;
  textLink: string;
  textPrimaryDarkBG: string;
  textSecondaryDarkBG: string;
  textTertiaryDarkBG: string;
  textLinkDarkBG: string;

  backgroundPrimary: string;
  backgroundSecondary: string;
  backgroundDisabled: string;

  borderDefault: string;
  borderDisabled: string;
  borderFocus: string;
  borderHover: string;
  borderFocusDarkBg: string;

  formBorderDefault: string;
  formBorderInfo: string;
  formBorderPositive: string;
  formBorderNegative: string;
  formBorderWarning: string;
  formBackgroundDefault: string;
  formBackgroundDisabled: string;
  formLabelDefault: string;
  formColorLabelHover: string;
  formColorLabelActive: string;
  formColorInvalid: string;
  formColorDisabled: string;

  backgroundDefault: string;
  backgroundHover: string;
  backgroundInfoEmphasis: string;
  backgroundInfoSubtle: string;
  backgroundPositiveEmphasis: string;
  backgroundPositiveSubtle: string;
  backgroundNegativeEmphasis: string;
  backgroundNegativeSubtle: string;
  backgroundWarningEmphasis: string;
  backgroundWarningSubtle: string;

  containerTextHeadingSubtle: string;
  containerTextInfoEmphasis: string;
  containerTextNegativeEmphasis: string;
  containerTextPositiveEmphasis: string;
  containerTextWarningEmphasis: string;
  containerTextParagraphSubtle: string;
  containerTextParagraphInfoEmphasis: string;
  containerTextParagraphNegativeEmphasis: string;
  containerTextParagraphPositiveEmphasis: string;
  containerTextParagraphWarningEmphasis: string;
  containerTextBorderDefault: string;
  containerTextBorderInfo: string;
  containerTextBorderPositive: string;
  containerTextBorderNegative: string;
  containerTextBorderWarning: string;

  componentButtonColorBackgroundStrongPrimaryDefault: string;
  componentButtonColorBackgroundStrongPrimaryHover: string;
  componentButtonColorBackgroundStrongPrimaryPressed: string;
  componentButtonColorBackgroundStrongSecondaryDefault: string;
  componentButtonColorBackgroundStrongSecondaryHover: string;
  componentButtonColorBackgroundStrongSecondaryPressed: string;
  componentButtonColorBackgroundStrongDestructiveDefault: string;
  componentButtonColorBackgroundStrongDestructiveHover: string;
  componentButtonColorBackgroundStrongDestructivePressed: string;
  componentButtonColorBackgroundSubtleDefault: string;
  componentButtonColorBackgroundSubtlePrimaryHover: string;
  componentButtonColorBackgroundSubtlePrimaryPressed: string;
  componentButtonColorBackgroundSubtleSecondaryHover: string;
  componentButtonColorBackgroundSubtleSecondaryPressed: string;
  componentButtonColorBackgroundSubtleDestructiveHover: string;
  componentButtonColorBackgroundSubtleDestructivePressed: string;
  componentButtonColorBorderSubtlePrimaryDefault: string;
  componentButtonColorBorderSubtlePrimaryHover: string;
  componentButtonColorBorderSubtlePrimaryPressed: string;
  componentButtonColorBorderSubtleSecondaryDefault: string;
  componentButtonColorBorderSubtleSecondaryHover: string;
  componentButtonColorBorderSubtleSecondaryPressed: string;
  componentButtonColorBorderSubtleDestructiveDefault: string;
  componentButtonColorBorderSubtleDestructiveHover: string;
  componentButtonColorBorderSubtleDestructivePressed: string;
  componentButtonColorTextSubtlePrimary: string;
  componentButtonColorTextSubtleSecondary: string;
  componentButtonColorTextSubtleDestructive: string;
  componentButtonColorTextGhostPrimary: string;
  componentButtonColorTextGhostDestructive: string;
  componentButtonColorTextStrongPrimary: string;
  componentButtonColorTextStrongSecondary: string;
  componentButtonColorTextStrongDestructive: string;
  componentContainerColorBackgroundDefault: string;
  componentContainerColorBackgroundHover: string;
  componentContainerColorBackgroundInfoEmphasis: string;
  componentContainerColorBackgroundInfoSubtle: string;
  componentContainerColorBackgroundPositiveEmphasis: string;
  componentContainerColorBackgroundPositiveSubtle: string;
  componentContainerColorBackgroundNegativeEmphasis: string;
  componentContainerColorBackgroundNegativeSubtle: string;
  componentContainerColorBackgroundWarningEmphasis: string;
  componentContainerColorBackgroundWarningSubtle: string;
  componentContainerColorTextHeadingSubtle: string;
  componentContainerColorTextHeadingInfoEmphasis: string;
  componentContainerColorTextHeadingNegativeEmphasis: string;
  componentContainerColorTextHeadingPositiveEmphasis: string;
  componentContainerColorTextHeadingWarningEmphasis: string;
  componentContainerColorTextParagraphSubtle: string;
  componentContainerColorTextParagraphInfoEmphasis: string;
  componentContainerColorTextParagraphNegativeEmphasis: string;
  componentContainerColorTextParagraphPositiveEmphasis: string;
  componentContainerColorTextParagraphWarningEmphasis: string;
  componentContainerColorBorderDefault: string;
  componentContainerColorBorderInfo: string;
  componentContainerColorBorderPositive: string;
  componentContainerColorBorderNegative: string;
  componentContainerColorBorderWarning: string;
  componentFormColorTextLabelDefault: string;
  componentFormColorTextLabelHover: string;
  componentFormColorTextLabelActive: string;
  componentFormColorTextInvalid: string;
  componentFormColorTextDisabled: string;
  componentFormColorTextPlaceholder: string;
  componentFormColorBackgroundDefault: string;
  componentFormColorBackgroundDisabled: string;
  componentFormColorBorderDefault: string;
  componentFormColorBorderDisabled: string;
  componentFormColorBorderFocus: string;
  componentFormColorBorderActive: string;
  componentFormColorBorderHover: string;
  componentLinkDefault: string;
  componentLinkHover: string;
  componentLinkVisited: string;

  semanticColorShadowWeak: string;
  semanticColorShadowMedium: string;
  semanticColorShadowStrong: string;

  componentTableBackgroundDefault: string;
  componentTableBackgroundHover: string;
  componentTableBackgroundChecked: string;
  componentTableBackgroundCheckedHover: string;
  componentTableBackgroundDefault2: string;
  componentTableBackgroundHover2: string;
  componentTableBackgroundChecked2: string;
  componentTableBackgroundCheckedHover2: string;

  /** @deprecated Use textPrimary instead */
  functionalTextPrimary: string;
  /** @deprecated Use textSecondary instead */
  functionalTextSecondary: string;
  /** @deprecated Use textDisabled instead */
  functionalTextInactive: string;
  /** @deprecated Use textLink instead */
  functionalTextLink: string;
  /** @deprecated Use textPrimaryDarkBG instead */
  functionalTextPrimaryDarkBG: string;
  /** @deprecated Use textSecondaryDarkBG instead */
  functionalTextSecondaryDarkBG: string;
  /** @deprecated Use textDisabled instead */
  functionalTextInactiveDarkBG: string;
  /** @deprecated Use textLinkDarkBG instead */
  functionalTextLinkDarkBG: string;
  /** @deprecated Use backgroundPrimary instead */
  functionalBgPrimary: string;
  /** @deprecated Use backgroundSecondary instead */
  functionalBgSecondary: string;
  /** @deprecated Use borderDefault instead */
  functionalBorderPrimary: string;

  /** @deprecated Use sentimentNegativeFaint instead */
  sentimentDangerFaint: string;
  /** @deprecated Use sentimentNegativeWeak instead */
  sentimentDangerWeak: string;
  /** @deprecated Use sentimentNegativeMedium instead */
  sentimentDangerMedium: string;
  /** @deprecated Use sentimentNegativeStrong instead */
  sentimentDangerStrong: string;
  /** @deprecated Use sentimentNegativeHeavy instead */
  sentimentDangerHeavy: string;
  /** @deprecated Use sentimentNegativeDense instead */
  sentimentDangerDense: string;
};

export const colors: MidwestColors = {
  black: tokens.baseColorBlack,
  white: tokens.baseColorWhite,

  identityBrandPrimaryFaint: tokens.semanticColorIdentityBrandPrimaryFaint,
  identityBrandPrimaryWeak: tokens.semanticColorIdentityBrandPrimaryWeak,
  identityBrandPrimaryMedium: tokens.semanticColorIdentityBrandPrimaryMedium,
  identityBrandPrimaryStrong: tokens.semanticColorIdentityBrandPrimaryStrong,
  identityBrandPrimaryHeavy: tokens.semanticColorIdentityBrandPrimaryHeavy,
  identityBrandPrimaryDense: tokens.semanticColorIdentityBrandPrimaryDense,

  identityBrandSecondary: tokens.semanticColorIdentityBrandSecondary,

  identityBrandTertiary: tokens.semanticColorIdentityBrandTertiary,
  identityBrandTertiaryFaint: tokens.baseColorGold3,
  identityBrandTertiaryStrong: tokens.semanticColorIdentityBrandTertiaryStrong,
  identityBrandTertiaryHeavy: tokens.semanticColorIdentityBrandTertiaryHeavy,

  sentimentNegativeFaint: tokens.semanticColorSentimentNegativeFaint,
  sentimentNegativeWeak: tokens.semanticColorSentimentNegativeWeak,
  sentimentNegativeMedium: tokens.semanticColorSentimentNegativeMedium,
  sentimentNegativeStrong: tokens.semanticColorSentimentNegativeStrong,
  sentimentNegativeHeavy: tokens.semanticColorSentimentNegativeHeavy,
  sentimentNegativeDense: tokens.semanticColorSentimentNegativeDense,

  sentimentSuccessFaint: tokens.semanticColorSentimentPositiveFaint,
  sentimentSuccessWeak: tokens.semanticColorSentimentPositiveWeak,
  sentimentSuccessMedium: tokens.semanticColorSentimentPositiveMedium,
  sentimentSuccessStrong: tokens.semanticColorSentimentPositiveStrong,
  sentimentSuccessHeavy: tokens.semanticColorSentimentPositiveHeavy,
  sentimentSuccessDense: tokens.semanticColorSentimentPositiveDense,

  sentimentWarningFaint: tokens.semanticColorSentimentWarningFaint,
  sentimentWarningWeak: tokens.semanticColorSentimentWarningWeak,
  sentimentWarningMedium: tokens.semanticColorSentimentWarningMedium,
  sentimentWarningStrong: tokens.semanticColorSentimentWarningStrong,
  sentimentWarningHeavy: tokens.semanticColorSentimentWarningHeavy,
  sentimentWarningDense: tokens.semanticColorSentimentWarningDense,

  neutralLightWeak: tokens.semanticColorNeutralLightWeak,
  neutralLightMedium: tokens.semanticColorNeutralLightMedium,
  neutralLightStrong: tokens.semanticColorNeutralLightStrong,
  neutralLightHeavy: tokens.semanticColorNeutralLightHeavy,

  neutralDarkWeak: tokens.semanticColorNeutralDarkWeak,
  neutralDarkMedium: tokens.semanticColorNeutralDarkMedium,
  neutralDarkStrong: tokens.semanticColorNeutralDarkStrong,
  neutralDarkHeavy: tokens.semanticColorNeutralDarkHeavy,

  textPrimary: tokens.semanticColorTextPrimary,
  textSecondary: tokens.semanticColorTextSecondary,
  textTertiary: tokens.semanticColorTextTertiary,
  textDisabled: tokens.semanticColorTextDisabled,
  textLink: tokens.semanticColorTextLink,
  textPrimaryDarkBG: tokens.semanticColorTextPrimaryDarkBg,
  textSecondaryDarkBG: tokens.semanticColorTextSecondaryDarkBg,
  textTertiaryDarkBG: tokens.semanticColorTextTertiaryDarkBg,
  textLinkDarkBG: tokens.semanticColorTextLinkDarkBg,

  backgroundPrimary: tokens.semanticColorBackgroundPrimary,
  backgroundSecondary: tokens.semanticColorBackgroundSecondary,
  backgroundDisabled: tokens.semanticColorBackgroundDisabled,

  borderDefault: tokens.semanticColorBorderDefault,
  borderDisabled: tokens.semanticColorBorderDisabled,
  borderFocus: tokens.semanticColorBorderFocus,
  borderHover: tokens.semanticColorBorderHover,
  borderFocusDarkBg: tokens.semanticColorBorderFocusDarkbg,

  //form colors
  formBorderDefault: tokens.componentContainerColorBorderDefault,
  formBorderInfo: tokens.componentContainerColorBorderInfo,
  formBorderPositive: tokens.componentContainerColorBorderPositive,
  formBorderNegative: tokens.componentContainerColorBorderNegative,
  formBorderWarning: tokens.componentContainerColorBorderWarning,
  formBackgroundDefault: tokens.componentFormColorBackgroundDefault,
  formBackgroundDisabled: tokens.componentFormColorBackgroundDisabled,
  formLabelDefault: tokens.componentFormColorTextLabelDefault,
  formColorLabelHover: tokens.componentFormColorTextLabelHover,
  formColorLabelActive: tokens.componentFormColorTextLabelActive,
  formColorInvalid: tokens.componentFormColorTextInvalid,
  formColorDisabled: tokens.componentFormColorTextDisabled,

  backgroundDefault: tokens.componentContainerColorBackgroundDefault,
  backgroundHover: tokens.componentContainerColorBackgroundHover,
  backgroundInfoEmphasis: tokens.componentContainerColorBackgroundInfoEmphasis,
  backgroundInfoSubtle: tokens.componentContainerColorBackgroundInfoSubtle,
  backgroundPositiveEmphasis:
    tokens.componentContainerColorBackgroundPositiveEmphasis,
  backgroundPositiveSubtle:
    tokens.componentContainerColorBackgroundPositiveSubtle,
  backgroundNegativeEmphasis:
    tokens.componentContainerColorBackgroundNegativeEmphasis,
  backgroundNegativeSubtle:
    tokens.componentContainerColorBackgroundNegativeSubtle,
  backgroundWarningEmphasis:
    tokens.componentContainerColorBackgroundWarningEmphasis,
  backgroundWarningSubtle:
    tokens.componentContainerColorBackgroundWarningSubtle,

  containerTextHeadingSubtle: tokens.componentContainerColorTextHeadingSubtle,
  containerTextInfoEmphasis:
    tokens.componentContainerColorTextHeadingInfoEmphasis,
  containerTextNegativeEmphasis:
    tokens.componentContainerColorTextHeadingNegativeEmphasis,
  containerTextPositiveEmphasis:
    tokens.componentContainerColorTextHeadingPositiveEmphasis,
  containerTextWarningEmphasis:
    tokens.componentContainerColorTextHeadingWarningEmphasis,
  containerTextParagraphSubtle:
    tokens.componentContainerColorTextParagraphSubtle,
  containerTextParagraphInfoEmphasis:
    tokens.componentContainerColorTextParagraphInfoEmphasis,
  containerTextParagraphNegativeEmphasis:
    tokens.componentContainerColorTextParagraphNegativeEmphasis,
  containerTextParagraphPositiveEmphasis:
    tokens.componentContainerColorTextParagraphPositiveEmphasis,
  containerTextParagraphWarningEmphasis:
    tokens.componentContainerColorTextParagraphWarningEmphasis,
  containerTextBorderDefault: tokens.componentContainerColorBorderDefault,
  containerTextBorderInfo: tokens.componentContainerColorBorderInfo,
  containerTextBorderPositive: tokens.componentContainerColorBorderPositive,
  containerTextBorderNegative: tokens.componentContainerColorBorderNegative,
  containerTextBorderWarning: tokens.componentContainerColorBorderWarning,

  //button colors
  componentButtonColorBackgroundStrongPrimaryDefault:
    tokens.componentButtonColorBackgroundStrongPrimaryDefault,
  componentButtonColorBackgroundStrongPrimaryHover:
    tokens.componentButtonColorBackgroundStrongPrimaryHover,
  componentButtonColorBackgroundStrongPrimaryPressed:
    tokens.componentButtonColorBackgroundStrongPrimaryPressed,
  componentButtonColorBackgroundStrongSecondaryDefault:
    tokens.componentButtonColorBackgroundStrongSecondaryDefault,
  componentButtonColorBackgroundStrongSecondaryHover:
    tokens.componentButtonColorBackgroundStrongSecondaryHover,
  componentButtonColorBackgroundStrongSecondaryPressed:
    tokens.componentButtonColorBackgroundStrongSecondaryPressed,
  componentButtonColorBackgroundStrongDestructiveDefault:
    tokens.componentButtonColorBackgroundStrongDestructiveDefault,
  componentButtonColorBackgroundStrongDestructiveHover:
    tokens.componentButtonColorBackgroundStrongDestructiveHover,
  componentButtonColorBackgroundStrongDestructivePressed:
    tokens.componentButtonColorBackgroundStrongDestructivePressed,
  componentButtonColorBackgroundSubtleDefault:
    tokens.componentButtonColorBackgroundSubtleDefault,
  componentButtonColorBackgroundSubtlePrimaryHover:
    tokens.componentButtonColorBackgroundSubtlePrimaryHover,
  componentButtonColorBackgroundSubtlePrimaryPressed:
    tokens.componentButtonColorBackgroundSubtlePrimaryPressed,
  componentButtonColorBackgroundSubtleSecondaryHover:
    tokens.componentButtonColorBackgroundSubtleSecondaryHover,
  componentButtonColorBackgroundSubtleSecondaryPressed:
    tokens.componentButtonColorBackgroundSubtleSecondaryPressed,
  componentButtonColorBackgroundSubtleDestructiveHover:
    tokens.componentButtonColorBackgroundSubtleDestructiveHover,
  componentButtonColorBackgroundSubtleDestructivePressed:
    tokens.componentButtonColorBackgroundSubtleDestructivePressed,
  componentButtonColorBorderSubtlePrimaryDefault:
    tokens.componentButtonColorBorderSubtlePrimaryDefault,
  componentButtonColorBorderSubtlePrimaryHover:
    tokens.componentButtonColorBorderSubtlePrimaryHover,
  componentButtonColorBorderSubtlePrimaryPressed:
    tokens.componentButtonColorBorderSubtlePrimaryPressed,
  componentButtonColorBorderSubtleSecondaryDefault:
    tokens.componentButtonColorBorderSubtleSecondaryDefault,
  componentButtonColorBorderSubtleSecondaryHover:
    tokens.componentButtonColorBorderSubtleSecondaryHover,
  componentButtonColorBorderSubtleSecondaryPressed:
    tokens.componentButtonColorBorderSubtleSecondaryPressed,
  componentButtonColorBorderSubtleDestructiveDefault:
    tokens.componentButtonColorBorderSubtleDestructiveDefault,
  componentButtonColorBorderSubtleDestructiveHover:
    tokens.componentButtonColorBorderSubtleDestructiveHover,
  componentButtonColorBorderSubtleDestructivePressed:
    tokens.componentButtonColorBorderSubtleDestructivePressed,
  componentButtonColorTextSubtlePrimary:
    tokens.componentButtonColorTextSubtlePrimary,
  componentButtonColorTextSubtleSecondary:
    tokens.componentButtonColorTextSubtleSecondary,
  componentButtonColorTextSubtleDestructive:
    tokens.componentButtonColorTextSubtleDestructive,
  componentButtonColorTextGhostPrimary:
    tokens.componentButtonColorTextGhostPrimary,
  componentButtonColorTextGhostDestructive:
    tokens.componentButtonColorTextGhostDestructive,
  componentButtonColorTextStrongPrimary:
    tokens.componentButtonColorTextStrongPrimary,
  componentButtonColorTextStrongSecondary:
    tokens.componentButtonColorTextStrongSecondary,
  componentButtonColorTextStrongDestructive:
    tokens.componentButtonColorTextStrongDestructive,

  //Container colors
  componentContainerColorBackgroundDefault:
    tokens.componentContainerColorBackgroundDefault,
  componentContainerColorBackgroundHover:
    tokens.componentContainerColorBackgroundHover,
  componentContainerColorBackgroundInfoEmphasis:
    tokens.componentContainerColorBackgroundInfoEmphasis,
  componentContainerColorBackgroundInfoSubtle:
    tokens.componentContainerColorBackgroundInfoSubtle,
  componentContainerColorBackgroundPositiveEmphasis:
    tokens.componentContainerColorBackgroundPositiveEmphasis,
  componentContainerColorBackgroundPositiveSubtle:
    tokens.componentContainerColorBackgroundPositiveSubtle,
  componentContainerColorBackgroundNegativeEmphasis:
    tokens.componentContainerColorBackgroundNegativeEmphasis,
  componentContainerColorBackgroundNegativeSubtle:
    tokens.componentContainerColorBackgroundNegativeSubtle,
  componentContainerColorBackgroundWarningEmphasis:
    tokens.componentContainerColorBackgroundWarningEmphasis,
  componentContainerColorBackgroundWarningSubtle:
    tokens.componentContainerColorBackgroundWarningSubtle,
  componentContainerColorTextHeadingSubtle:
    tokens.componentContainerColorTextHeadingSubtle,
  componentContainerColorTextHeadingInfoEmphasis:
    tokens.componentContainerColorTextHeadingInfoEmphasis,
  componentContainerColorTextHeadingNegativeEmphasis:
    tokens.componentContainerColorTextHeadingNegativeEmphasis,
  componentContainerColorTextHeadingPositiveEmphasis:
    tokens.componentContainerColorTextHeadingPositiveEmphasis,
  componentContainerColorTextHeadingWarningEmphasis:
    tokens.componentContainerColorTextHeadingWarningEmphasis,
  componentContainerColorTextParagraphSubtle:
    tokens.componentContainerColorTextParagraphSubtle,
  componentContainerColorTextParagraphInfoEmphasis:
    tokens.componentContainerColorTextParagraphInfoEmphasis,
  componentContainerColorTextParagraphNegativeEmphasis:
    tokens.componentContainerColorTextParagraphNegativeEmphasis,
  componentContainerColorTextParagraphPositiveEmphasis:
    tokens.componentContainerColorTextParagraphPositiveEmphasis,
  componentContainerColorTextParagraphWarningEmphasis:
    tokens.componentContainerColorTextParagraphWarningEmphasis,
  componentContainerColorBorderDefault:
    tokens.componentContainerColorBorderDefault,
  componentContainerColorBorderInfo: tokens.componentContainerColorBorderInfo,
  componentContainerColorBorderPositive:
    tokens.componentContainerColorBorderPositive,
  componentContainerColorBorderNegative:
    tokens.componentContainerColorBorderNegative,
  componentContainerColorBorderWarning:
    tokens.componentContainerColorBorderWarning,
  componentFormColorTextLabelDefault: tokens.componentFormColorTextLabelDefault,
  componentFormColorTextLabelHover: tokens.componentFormColorTextLabelHover,
  componentFormColorTextLabelActive: tokens.componentFormColorTextLabelActive,
  componentFormColorTextInvalid: tokens.componentFormColorTextInvalid,
  componentFormColorTextDisabled: tokens.componentFormColorTextDisabled,
  componentFormColorTextPlaceholder: tokens.componentFormColorTextPlaceholder,
  componentFormColorBackgroundDefault:
    tokens.componentFormColorBackgroundDefault,
  componentFormColorBackgroundDisabled:
    tokens.componentFormColorBackgroundDisabled,
  componentFormColorBorderDefault: tokens.componentFormColorBorderDefault,
  componentFormColorBorderDisabled: tokens.componentFormColorBorderDisabled,
  componentFormColorBorderFocus: tokens.componentFormColorBorderFocus,
  componentFormColorBorderActive: tokens.componentFormColorBorderActive,
  componentFormColorBorderHover: tokens.componentFormColorBorderHover,
  componentLinkDefault: tokens.componentLinkDefault,
  componentLinkHover: tokens.componentLinkHover,
  componentLinkVisited: tokens.componentLinkVisited,

  //Shadow colors
  semanticColorShadowWeak: tokens.semanticColorShadowWeak,
  semanticColorShadowMedium: tokens.semanticColorShadowMedium,
  semanticColorShadowStrong: tokens.semanticColorShadowStrong,

  //data grid colors
  componentTableBackgroundDefault: tokens.componentTableBackgroundDefault,
  componentTableBackgroundHover: tokens.componentTableBackgroundHover,
  componentTableBackgroundChecked: tokens.componentTableBackgroundChecked,
  componentTableBackgroundCheckedHover:
    tokens.componentTableBackgroundCheckedHover,
  componentTableBackgroundDefault2: tokens.componentTableBackgroundDefault2,
  componentTableBackgroundHover2: tokens.componentTableBackgroundHover2,
  componentTableBackgroundChecked2: tokens.componentTableBackgroundChecked2,
  componentTableBackgroundCheckedHover2:
    tokens.componentTableBackgroundCheckedHover2,

  //deprecated
  functionalTextPrimary: tokens.semanticColorTextPrimary,
  functionalTextSecondary: tokens.semanticColorTextSecondary,
  functionalTextInactive: tokens.componentFormColorTextPlaceholder,
  functionalTextLink: tokens.semanticColorTextLink,
  functionalTextPrimaryDarkBG: tokens.semanticColorTextPrimaryDarkBg,
  functionalTextSecondaryDarkBG: tokens.semanticColorTextSecondaryDarkBg,
  functionalTextInactiveDarkBG: tokens.componentFormColorTextPlaceholder,
  functionalTextLinkDarkBG: tokens.semanticColorTextLinkDarkBg,

  functionalBgPrimary: tokens.semanticColorBackgroundPrimary,
  functionalBgSecondary: tokens.semanticColorBackgroundSecondary,

  functionalBorderPrimary: tokens.semanticColorShadowWeak,

  sentimentDangerFaint: tokens.semanticColorSentimentNegativeFaint,
  sentimentDangerWeak: tokens.semanticColorSentimentNegativeWeak,
  sentimentDangerMedium: tokens.semanticColorSentimentNegativeMedium,
  sentimentDangerStrong: tokens.semanticColorSentimentNegativeStrong,
  sentimentDangerHeavy: tokens.semanticColorSentimentNegativeHeavy,
  sentimentDangerDense: tokens.semanticColorSentimentNegativeDense,
} as const;
