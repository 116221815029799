export const hexToRGBA = (hex: string, alpha: number): string => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  return (
    phoneNumberString.slice(0, 3) +
    '-' +
    phoneNumberString.slice(3, 6) +
    '-' +
    phoneNumberString.slice(6)
  );
};

type NextImage = {
  src: string;
};

export const isNextImage = (image: string | NextImage): image is NextImage => {
  return (image as NextImage)?.src !== undefined;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
