import { ButtonVariationProps } from '../types';
import { backgroundColors } from './backgroundColors';
import { border, colors } from '@thrivent/midwest-shared';
import { css } from 'styled-components';

export const strongDestructiveStyles = css<ButtonVariationProps>`
  background: ${backgroundColors.destructiveStrongBg};
  color: ${colors.textPrimaryDarkBG};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background-color: ${colors.componentButtonColorBackgroundStrongDestructiveHover};
      }
      &:hover {
        background: ${colors.componentButtonColorBackgroundStrongDestructiveHover};
      }
      &:active {
        background: ${colors.componentButtonColorBackgroundStrongDestructivePressed};
      }
    `}
`;

export const subtleDestructiveStyles = css<ButtonVariationProps>`
  outline: ${border.widthDefault} solid
    ${colors.componentButtonColorBorderSubtleDestructiveDefault};
  background: ${backgroundColors.destructiveSubtleBg};
  color: ${colors.componentButtonColorTextSubtleDestructive};
  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        outline-color: ${colors.componentButtonColorBorderSubtleDestructiveHover};
        background-color: ${colors.componentButtonColorBackgroundSubtleDestructiveHover};
      }
      &:active {
        background-color: ${colors.componentButtonColorBackgroundSubtleDestructivePressed};
        outline-color: ${colors.componentButtonColorBorderSubtleDestructivePressed};
      }
      &:focus-visible {
        background-color: ${colors.backgroundPrimary};
      }
    `}
`;

export const ghostDestructiveStyles = css<ButtonVariationProps>`
  outline: none;
  background: ${backgroundColors.destructiveGhostBg};
  color: ${colors.componentButtonColorTextGhostDestructive};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background: ${backgroundColors.destructiveGhostBg};
      }
      &:hover {
        background: ${colors.componentButtonColorBackgroundSubtleDestructiveHover};
      }
      &:active {
        background: ${colors.componentButtonColorBackgroundSubtleDestructivePressed};
      }
    `}
`;
