import { useConsumerId } from '@servicing/data-queries';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@thrivent-web/authentication';
import { evaluateLDBrowserFlags } from '@thrivent-web/servicing/feature-management';
import { useEffect } from 'react';

const CaptureStartupLaunchdarklyFlags = () => {
  const { isAuthenticated } = useAuth();
  const consumerId = useConsumerId();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (isAuthenticated) {
      evaluateLDBrowserFlags(consumerId, queryClient);
    }
  }, [consumerId, isAuthenticated, queryClient]);
  return null;
};

export default CaptureStartupLaunchdarklyFlags;
