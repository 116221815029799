import { logger } from '@thrivent-web/logging-utils';

/* eslint-disable @typescript-eslint/no-unsafe-call */
const ONE_TRUST_CATEGORY_ID = '2';

/**
 * * This function is used to check if the user has accepted the OneTrust cookie policy before sending data to DataDog.
 */
export const oneTrustBeforeSend = () => {
  //@ts-expect-error - OnetrustActiveGroups is a global variable; it is not defined in this file
  if (typeof OnetrustActiveGroups === 'undefined') {
    return false;
  }

  try {
    //@ts-expect-error - OnetrustActiveGroups is a global variable; it is not defined in this file
    if (OnetrustActiveGroups.indexOf(ONE_TRUST_CATEGORY_ID) === -1) {
      logger.debug('One Trust Category not found: do not send data to datadog');
      return false;
    }
    logger.debug('One Trust Category matched: sending data to datadog');
    return true;
  } catch {
    logger.info(
      'Checking one trust category threw error: do not send data to datadog'
    );
    return false;
  }
};
