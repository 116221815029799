import { ReactComponent as AppStore } from '../../../svg/download-app-store.svg';
import { ReactComponent as PlayStore } from '../../../svg/download-play-store.svg';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

const IOS_URL = 'https://apps.apple.com/us/app/thrivent-mobile/id1555328930';
const ANDROID_URL =
  'https://play.google.com/store/apps/details?id=com.thrivent.mobileapp';

const DownloadButton = styled.a`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
`;

const MobileAppTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const MobileAppSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0;
  gap: 1.5rem;
`;

export const MobileAppSection = () => {
  return (
    <MobileAppSectionContainer>
      <MobileAppTextContainer>
        <MDSText.TitleMd as={'h4'}>Thrivent&apos;s mobile app</MDSText.TitleMd>
        <MDSText.BodyRegDefault as={'p'}>
          View activity, make payments, and more on your mobile device. For iOS
          and Android.
        </MDSText.BodyRegDefault>
      </MobileAppTextContainer>
      <DownloadButtons>
        <DownloadButton
          aria-label="Apple Store - opens in a new tab"
          target="_blank"
          href={IOS_URL}
        >
          <AppStore height={38} width="auto" />
        </DownloadButton>
        <DownloadButton
          aria-label="Play Store - opens in a new tab"
          target="_blank"
          href={ANDROID_URL}
        >
          <PlayStore height={38} />
        </DownloadButton>
      </DownloadButtons>
    </MobileAppSectionContainer>
  );
};
