'use client';

import { TrackingEvent } from './types/events';
import { ReactElement, createContext, useContext } from 'react';
import { useTracking } from 'react-tracking';

const AnalyticsContext = createContext<
  { dispatch: (data: TrackingEvent) => void } | undefined
>(undefined);

type AnalyticsProviderProps = {
  children: ReactElement | ReactElement[];
  dispatch: (data: TrackingEvent) => void;
};

export function AnalyticsProvider({
  children,
  dispatch,
}: AnalyticsProviderProps) {
  return (
    <AnalyticsContext.Provider value={{ dispatch }}>
      {children}
    </AnalyticsContext.Provider>
  );
}

// accept 'any' for data to allow ad hoc data enrichment
// react tracing will union data with the event object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAnalyticsTracking(data: any = {}) {
  const context = useContext(AnalyticsContext);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return useTracking<TrackingEvent>(data, {
    dispatch: context?.dispatch,
  });
}
