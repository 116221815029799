import { BadgeSizes } from './Badge';
import { MDSText } from '@midwest/web/base';
import { border, colors, spacing } from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';

export const BaseBadgeStyle = css`
  border-radius: 1000px;
  display: inline-flex;
`;

export const PositiveBadgeStyle = css`
  color: ${colors.sentimentSuccessDense};
  background: ${colors.sentimentSuccessWeak};
  path {
    fill: ${colors.sentimentSuccessDense};
  }
`;

export const WarningBadgeStyle = css`
  color: ${colors.sentimentWarningDense};
  background: ${colors.sentimentWarningWeak};
  path {
    fill: ${colors.sentimentWarningDense};
  }
`;

export const NegativeBadgeStyle = css`
  color: ${colors.sentimentDangerHeavy};
  background: ${colors.sentimentDangerWeak};
  path {
    fill: ${colors.sentimentDangerHeavy};
  }
`;

export const NeutralBadgeStyle = css`
  color: ${colors.textPrimary};
  background: ${colors.backgroundDefault};
  box-shadow: inset 0 0 0 ${border.widthDefault} ${colors.borderDefault};
  path {
    fill: ${colors.textPrimary};
  }
`;

export const InfoBadgeStyle = css`
  color: ${colors.identityBrandPrimaryStrong};
  background: ${colors.backgroundDefault};
  box-shadow: inset 0 0 0 ${border.widthDefault} ${colors.borderDefault};
  path {
    fill: ${colors.identityBrandPrimaryStrong};
  }
`;

export const LargeLabel = styled(MDSText.BodyHeavyDefault)`
  margin: 0;
  padding: 5px ${spacing.xsm} 5px ${spacing.xsm};
  white-space: nowrap;
`;

export const SmallLabel = styled(MDSText.BodyHeavySm)`
  padding: ${spacing.xxxsm} ${spacing.xsm};
  white-space: nowrap;
`;

export const LeftIconLayout = styled.div<{
  size: BadgeSizes;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${spacing.xsm};
  margin-right: -${spacing.xxsm};
`;

export const CustomIcon = styled.div<{ size: BadgeSizes }>`
  width: ${(p) => (p.size === 'small' ? '12px' : '16px')};
  height: ${(p) => (p.size === 'small' ? '12px' : '16px')};
`;
