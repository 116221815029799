import { ERROR_AUTH_TOKEN_NOT_PRESENT } from './isMissingAuthToken';
import { getUserAccessTokenFromBrowser } from '@servicing/auth';
import { QueryFunctionContext } from '@tanstack/react-query';
import { isContextGetAccessToken } from '@thrivent-web/logging-utils';

export const getAuthorizationHeader = async (
  context?: QueryFunctionContext
) => {
  let token = '';

  if (context && isContextGetAccessToken(context?.meta)) {
    token = (await context.meta.getAccessToken()) ?? '';
  } else {
    token = await getUserAccessTokenFromBrowser();
  }

  if (token) {
    return `Bearer ${token}`;
  } else {
    throw new Error(ERROR_AUTH_TOKEN_NOT_PRESENT);
  }
};
