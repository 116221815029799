import { logger } from '@thrivent-web/logging-utils';

export const getEnvVarNumberValue = (
  environmentVariableName: string,
  defaultRate: number
): number => {
  try {
    const rate = parseInt(process.env[environmentVariableName] || '');
    if (isNaN(rate)) {
      return defaultRate;
    }
    return rate;
  } catch (e) {
    logger.error(
      `Error setting ${environmentVariableName}, defaulting to ${defaultRate}`,
      e
    );
    return defaultRate;
  }
};
