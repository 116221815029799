import NextLink from 'next/link';
import { AnchorHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export type LinkVariants = 'primary' | 'secondary';

const Anchor = styled.a<{ variant: LinkVariants }>`
  display: flex;
  font-size: 1rem;
  line-height: 1.125;
  ${(p) =>
    p.variant === 'primary' &&
    css`
      font-weight: bold;
      color: ${(p) => p.theme.colors.text_link_active};
    `}

  ${(p) =>
    p.variant === 'secondary' &&
    css`
      color: ${(p) => p.theme.colors.text_primary_dark_bg};
    `}
`;

export type LinkType = {
  variant?: LinkVariants;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * @deprecated use `MDSLink` from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/thrivent-web-components-actions-link--docs) instead
 *
 * ```tsx
 * import { MDSLink } from '@thrivent/midwest-web';
 * ```
 */
export const Link = ({
  href = '',
  children,
  variant = 'primary',
  ...props
}: LinkType) => (
  <NextLink href={href} passHref legacyBehavior>
    <Anchor variant={variant} {...props}>
      {children}
    </Anchor>
  </NextLink>
);
