export const YEAR_MONTH_DAY = 'yyyy-MM-dd';
export const MONTH_DAY_YEAR_WITH_FORWARD_SLASH = 'MM/dd/yyyy';
export const MONTH_DAY_TWO_DIGIT_YEAR_WITH_FORWARD_SLASH = 'MM/dd/yy';
export const MONTH_ABBREVIATION_DAY_COMMA_YEAR = 'MMM dd, yyyy';
export const MONTH_ABBREVIATION_DOT_DAY_COMMA_YEAR = 'MMM. dd, yyyy';
export const MONTH_NAME_DAY_WITH_LEADING_ZERO_YEAR = 'MMMM dd, yyyy';
export const MONTH_NAME_DAY_YEAR = 'LLLL d, yyyy';
export const YEAR_MONTH_DAY_HOUR_MINUTE_SECOND = 'yyyy-MM-dd HH:mm:ss';
export const MONTH_ABBREVIATION_DAY_WITH_SUFFIX_YEAR = 'MMM do, yyyy';
export const MONTH_ABBREVIATION_DAY = 'MMM d';
export const DAY_OF_WEEK_MONTH_DAY_YEAR_WITH_FORWARD_SLASH = 'EEEE, MM/dd/yyyy';
export const TODAY_MONTH_DAY_YEAR_WITH_FORWARD_SLASH = "'Today, 'MM/dd/yyyy";
export const DATE_TIMEZONE_FORMAT = 'MMMM dd, yyyy, | h:mm a zzz';
export const AS_OF_DATE_WITH_TIME = "'As of' MMMM dd, yyyy 'at' h:mm a 'ET'";
export const EASTERN_TIMEZONE = 'America/New_York';
