const DEFAULT_EMAIL = 'BoxVirtualAdvice@thrivent.com';
type FormatOptions = {
  /** To field for email address. If no value is provided default is set to BoxVirtualAdvice@thrivent.com */
  email?: string | null | undefined;
  /** Subject field for email */
  subject?: string;
};
export const formatSecureEmail = ({
  email,
  subject,
}: FormatOptions = {}): string => {
  const baseUrl = process.env['NEXT_PUBLIC_SECURE_MESSAGE_BASE_URL'] || '';
  const url = new URL(baseUrl);

  let emailParam = DEFAULT_EMAIL;

  // The thrivent.com is production and the thrivd.com is stage emails
  if (
    (email && email.toLocaleLowerCase().includes('@thrivent.com')) ||
    email?.toLocaleLowerCase().includes('@thrivd.com')
  ) {
    emailParam = email;
  }

  url.searchParams.append('to', emailParam);
  if (subject) {
    url.searchParams.append('subject', subject);
  }
  return url.href;
};
