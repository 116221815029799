import {
  ClientFinancialPlannerFragment,
  FinancialPlannersQuery,
  useFinancialPlannersQuery,
} from './financialPlanners.generated';
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
// eslint-disable-next-line no-restricted-imports -- These are schema types for use in generated files. Don’t use these directly. Use @thrivent/supergraph-types, operations fragments, or resolver types instead.
import { Maybe } from '@thrivent/graphql';

type Address = {
  address1?: Maybe<string>;
  address2?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  zip?: Maybe<string>;
};
export type Advisor = {
  avatar?: Maybe<string>;
  phone?: Maybe<string>;
  email?: Maybe<string>;
  name?: Maybe<string>;
  credentials?: Maybe<string>;
  address?: Maybe<Address>;
  id?: Maybe<string>;
};

export type AdvisorResult = UseQueryResult<FinancialPlannersQuery> & {
  advisor: Advisor;
};

export const getPlannerName = (
  planner: ClientFinancialPlannerFragment
): string => {
  const plannerFirstName = planner?.firstName;
  const plannerLastName = planner?.legalSurname;

  if (plannerFirstName && !plannerLastName) return plannerFirstName;
  if (plannerLastName && !plannerFirstName) return plannerLastName;
  if (plannerFirstName && plannerLastName)
    return `${plannerFirstName} ${plannerLastName}`;

  return 'N/A';
};

export const useFinancialAdvisor = (
  options?: Partial<UseQueryOptions<FinancialPlannersQuery>>
): AdvisorResult => {
  const result = useFinancialPlannersQuery(undefined, {
    staleTime: Infinity,
    gcTime: Infinity,
    ...options,
  });

  const planner = result.data?.client?.financialPlanners?.find(
    (p: ClientFinancialPlannerFragment) => p !== undefined
  );

  if (!planner)
    return {
      advisor: {},
      ...result,
    };

  const name = getPlannerName(planner);
  const contactInfo = planner.contactInfo;
  const credentials = planner.credentials;
  const avatar = planner.avatarUrl;
  const id = planner.id;
  const email = contactInfo?.email;
  const address = contactInfo?.address;
  const phone = contactInfo?.workPhone || contactInfo?.mobilePhone;

  return {
    advisor: {
      avatar,
      name,
      email,
      phone,
      credentials,
      address,
      id,
    },
    ...result,
  };
};
