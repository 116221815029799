import { QueryFunctionContext } from '@tanstack/react-query';
import { isContextGetTestName } from '@thrivent-web/logging-utils';

/**
 * /**
 * Get the e2e test id from context
 *
 * Note - This mirrors the work around used by `getAuthorizationHeader` to get the
 * missing context for client side mutations
 *
 * @param context
 * @returns test id string or an empty string
 */
export const getTestName = (context?: QueryFunctionContext) => {
  let getTestName: () => string;

  if (context && isContextGetTestName(context.meta)) {
    getTestName = context.meta.getTestName;
  } else {
    getTestName = () =>
      document.cookie
        .split('; ')
        .find((row) => row.startsWith('MSW_TEST_ID='))
        ?.split('=')[1] ?? '';
  }

  if (!getTestName) {
    throw new Error('getTestName is missing');
  }

  return getTestName();
};
