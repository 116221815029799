import type { OAuthClientSettings } from '@thrivent-web/authentication';

const NEXT_PUBLIC_FORGEROCK_BASE_URL =
  (typeof window !== 'undefined'
    ? process.env['NEXT_PUBLIC_FORGEROCK_BASE_URL']
    : process.env['NEXT_PUBLIC_FORGEROCK_BASE_URL_SSR']) ??
  'https://iam.stage.thrivent.com/am/oauth2';

const NEXT_PUBLIC_FORGEROCK_ISSUER =
  process.env['NEXT_PUBLIC_FORGEROCK_ISSUER'] ??
  'https://iam.stage.thrivent.com/am/oauth2';
const NEXT_PUBLIC_AUTH_REDIRECT_URI =
  process.env['NEXT_PUBLIC_AUTH_REDIRECT_URI'] ??
  'https://servicing.apps.desktop.thrivent.com:4200/authentication/callback';

const NEXT_PUBLIC_SSO_LOGOUT_URI =
  process.env['NEXT_PUBLIC_SSO_LOGOUT_URI'] ??
  'https://sso.inttst.thrivent.com/oam/server/logout?end_url=https://www.inttst.thrivent.com';

const NEXT_PUBLIC_FORGEROCK_SCOPES =
  process.env['NEXT_PUBLIC_FORGEROCK_SCOPES'] ?? 'openid profile';

const NEXT_PUBLIC_FORGEROCK_CLIENT_ID =
  process.env['NEXT_PUBLIC_FORGEROCK_CLIENT_ID'] ??
  '0ea07354-8074-4570-ad60-b15994734319';

export const oidcClientSettings: OAuthClientSettings = {
  client_id: NEXT_PUBLIC_FORGEROCK_CLIENT_ID,
  scope: NEXT_PUBLIC_FORGEROCK_SCOPES,
  redirect_uri: NEXT_PUBLIC_AUTH_REDIRECT_URI,
  post_logout_redirect_uri: NEXT_PUBLIC_SSO_LOGOUT_URI,
  authority: NEXT_PUBLIC_FORGEROCK_ISSUER,
  metadata: {
    authorization_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/authorize`,
    token_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/access_token`,
    userinfo_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/userinfo`,
    end_session_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/connect/endSession`,
    revocation_endpoint: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/token/revoke`,
    check_session_iframe: `${NEXT_PUBLIC_FORGEROCK_BASE_URL}/connect/checkSession`,
    issuer: NEXT_PUBLIC_FORGEROCK_ISSUER,
  },
};
