import { BaseTypography, createTypography } from './createTypography';
import {
  MidwestTypographyToken,
  authTextDesktopDataLg,
  authTextDesktopDataMd,
  authTextDesktopDataSm,
  authTextDesktopHero,
  authTextDesktopHeroXl,
  authTextDesktopTitleLg,
  authTextDesktopTitleMd,
  authTextDesktopTitleSm,
  authTextDesktopTitleXl,
  authTextMobileDataLg,
  authTextMobileDataMd,
  authTextMobileDataSm,
  authTextMobileHero,
  authTextMobileHeroXl,
  authTextMobileTitleLg,
  authTextMobileTitleMd,
  authTextMobileTitleSm,
  authTextMobileTitleXl,
  brandTextDesktopHeroLg,
  brandTextDesktopHeroMd,
  brandTextDesktopHeroSm,
  brandTextDesktopSubtitle,
  brandTextDesktopTitleLg,
  brandTextDesktopTitleMd,
  brandTextDesktopTitleSm,
  brandTextMobileHeroLg,
  brandTextMobileHeroMd,
  brandTextMobileHeroSm,
  brandTextMobileSubtitle,
  brandTextMobileTitleLg,
  brandTextMobileTitleMd,
  brandTextMobileTitleSm,
  textBodyHeavyDefault,
  textBodyHeavySm,
  textBodyMediumDefault,
  textBodyMediumSm,
  textBodyMediumXs,
  textBodyRegDefault,
  textBodyRegSm,
  textMiniDefault,
} from './typography';
import { TypographyToken } from '@thrivent-web/ui/tokens';
import styled from 'styled-components';

function convertMidwestTokenToTypographyToken(
  midwestToken: MidwestTypographyToken
): TypographyToken {
  return {
    font: midwestToken.font,
    rem: `${midwestToken.rem}rem`,
    weight: parseInt(midwestToken.weight),
  };
}
/**
 * @deprecated Use MDSText components from Midwest Storybook. import { MDSText } from @thrivent/midwest-web and use with `MDSText.bodyHeavyDefault`
 */
export const BodyText = {
  HeavyDefault: styled(BaseTypography).attrs({ as: 'p' })`
    ${createTypography({
      name: 'HeavyDefault',
      mobile: convertMidwestTokenToTypographyToken(textBodyHeavyDefault),
    })}
  `,
  HeavySm: styled(BaseTypography).attrs({ as: 'p' })`
    ${createTypography({
      name: 'HeavySm',
      mobile: convertMidwestTokenToTypographyToken(textBodyHeavySm),
    })}
  `,
  MediumDefault: styled(BaseTypography).attrs({ as: 'p' })`
    ${createTypography({
      name: 'MediumDefault',
      mobile: convertMidwestTokenToTypographyToken(textBodyMediumDefault),
    })}
  `,
  MediumSm: styled(BaseTypography).attrs({ as: 'p' })`
    ${createTypography({
      name: 'MediumSm',
      mobile: convertMidwestTokenToTypographyToken(textBodyMediumSm),
    })}
  `,
  MediumXs: styled(BaseTypography).attrs({ as: 'p' })`
    ${createTypography({
      name: 'MediumXs',
      mobile: convertMidwestTokenToTypographyToken(textBodyMediumXs),
    })}
  `,
  RegDefault: styled(BaseTypography).attrs({ as: 'p' })`
    ${createTypography({
      name: 'RegDefault',
      mobile: convertMidwestTokenToTypographyToken(textBodyRegDefault),
    })}
  `,
  RegSm: styled(BaseTypography).attrs({ as: 'p' })`
    ${createTypography({
      name: 'RegSm',
      mobile: convertMidwestTokenToTypographyToken(textBodyRegSm),
    })}
  `,
  MiniDefault: styled(BaseTypography).attrs({ as: 'p' })`
    ${createTypography({
      name: 'MiniDefault',
      mobile: convertMidwestTokenToTypographyToken(textMiniDefault),
    })}
  `,
};

/**
 * @deprecated Use MDSText components from Midwest Storybook. import { MDSText } from @thrivent/midwest-web and use with `MDSText.bodyHeavyDefault`
 */

export const AuthText = {
  Hero: styled(BaseTypography).attrs({ as: 'h1' })`
    ${createTypography({
      name: 'Hero',
      mobile: convertMidwestTokenToTypographyToken(authTextMobileHero),
      tablet: convertMidwestTokenToTypographyToken(authTextMobileHero),
      desktop: convertMidwestTokenToTypographyToken(authTextDesktopHero),
    })}
  `,
  HeroXl: styled(BaseTypography).attrs({ as: 'h1' })`
    ${createTypography({
      name: 'HeroXl',
      mobile: convertMidwestTokenToTypographyToken(authTextMobileHeroXl),
      tablet: convertMidwestTokenToTypographyToken(authTextMobileHeroXl),
      desktop: convertMidwestTokenToTypographyToken(authTextDesktopHeroXl),
    })}
  `,
  TitleSm: styled(BaseTypography).attrs({ as: 'h2' })`
    ${createTypography({
      name: 'TitleSm',
      mobile: convertMidwestTokenToTypographyToken(authTextMobileTitleSm),
      tablet: convertMidwestTokenToTypographyToken(authTextMobileTitleSm),
      desktop: convertMidwestTokenToTypographyToken(authTextDesktopTitleSm),
    })}
  `,
  TitleMd: styled(BaseTypography).attrs({ as: 'h2' })`
    ${createTypography({
      name: 'TitleMd',
      mobile: convertMidwestTokenToTypographyToken(authTextMobileTitleMd),
      tablet: convertMidwestTokenToTypographyToken(authTextMobileTitleMd),
      desktop: convertMidwestTokenToTypographyToken(authTextDesktopTitleMd),
    })}
  `,
  TitleLg: styled(BaseTypography).attrs({ as: 'h2' })`
    ${createTypography({
      name: 'TitleLg',
      mobile: convertMidwestTokenToTypographyToken(authTextMobileTitleLg),
      tablet: convertMidwestTokenToTypographyToken(authTextMobileTitleLg),
      desktop: convertMidwestTokenToTypographyToken(authTextDesktopTitleLg),
    })}
  `,
  TitleXl: styled(BaseTypography).attrs({ as: 'h2' })`
    ${createTypography({
      name: 'TitleXl',
      mobile: convertMidwestTokenToTypographyToken(authTextMobileTitleXl),
      tablet: convertMidwestTokenToTypographyToken(authTextMobileTitleXl),
      desktop: convertMidwestTokenToTypographyToken(authTextDesktopTitleXl),
    })}
  `,
  DataSm: styled(BaseTypography).attrs({ as: 'span' })`
    ${createTypography({
      name: 'DataSm',
      mobile: convertMidwestTokenToTypographyToken(authTextMobileDataSm),
      tablet: convertMidwestTokenToTypographyToken(authTextMobileDataSm),
      desktop: convertMidwestTokenToTypographyToken(authTextDesktopDataSm),
    })}
  `,
  DataMd: styled(BaseTypography).attrs({ as: 'span' })`
    ${createTypography({
      name: 'DataMd',
      mobile: convertMidwestTokenToTypographyToken(authTextMobileDataMd),
      tablet: convertMidwestTokenToTypographyToken(authTextMobileDataMd),
      desktop: convertMidwestTokenToTypographyToken(authTextDesktopDataMd),
    })}
  `,
  DataLg: styled(BaseTypography).attrs({ as: 'span' })`
    ${createTypography({
      name: 'DataLg',
      mobile: convertMidwestTokenToTypographyToken(authTextMobileDataLg),
      tablet: convertMidwestTokenToTypographyToken(authTextMobileDataLg),
      desktop: convertMidwestTokenToTypographyToken(authTextDesktopDataLg),
    })}
  `,
};

/**
 * @deprecated Use MDSText components from Midwest Storybook. import { MDSText } from @thrivent/midwest-web and use with `MDSText.bodyHeavyDefault`
 */

export const BrandText = {
  HeroSm: styled(BaseTypography).attrs({ as: 'h1' })`
    ${createTypography({
      name: 'HeroSm',
      mobile: convertMidwestTokenToTypographyToken(brandTextMobileHeroSm),
      tablet: convertMidwestTokenToTypographyToken(brandTextMobileHeroSm),
      desktop: convertMidwestTokenToTypographyToken(brandTextDesktopHeroSm),
    })}
  `,
  HeroMd: styled(BaseTypography).attrs({ as: 'h1' })`
    ${createTypography({
      name: 'HeroMd',
      mobile: convertMidwestTokenToTypographyToken(brandTextMobileHeroMd),
      tablet: convertMidwestTokenToTypographyToken(brandTextMobileHeroMd),
      desktop: convertMidwestTokenToTypographyToken(brandTextDesktopHeroMd),
    })}
  `,
  HeroLg: styled(BaseTypography).attrs({ as: 'h1' })`
    ${createTypography({
      name: 'HeroLg',
      mobile: convertMidwestTokenToTypographyToken(brandTextMobileHeroLg),
      tablet: convertMidwestTokenToTypographyToken(brandTextMobileHeroLg),
      desktop: convertMidwestTokenToTypographyToken(brandTextDesktopHeroLg),
    })}
  `,
  Subtitle: styled(BaseTypography).attrs({ as: 'h2' })`
    ${createTypography({
      name: 'Subtitle',
      mobile: convertMidwestTokenToTypographyToken(brandTextMobileSubtitle),
      tablet: convertMidwestTokenToTypographyToken(brandTextMobileSubtitle),
      desktop: convertMidwestTokenToTypographyToken(brandTextDesktopSubtitle),
    })}
  `,
  TitleSm: styled(BaseTypography).attrs({ as: 'h2' })`
    ${createTypography({
      name: 'TitleSm',
      mobile: convertMidwestTokenToTypographyToken(brandTextMobileTitleSm),
      tablet: convertMidwestTokenToTypographyToken(brandTextMobileTitleSm),
      desktop: convertMidwestTokenToTypographyToken(brandTextDesktopTitleSm),
    })}
  `,
  TitleMd: styled(BaseTypography).attrs({ as: 'h2' })`
    ${createTypography({
      name: 'TitleMd',
      mobile: convertMidwestTokenToTypographyToken(brandTextMobileTitleMd),
      tablet: convertMidwestTokenToTypographyToken(brandTextMobileTitleMd),
      desktop: convertMidwestTokenToTypographyToken(brandTextDesktopTitleMd),
    })}
  `,
  TitleLg: styled(BaseTypography).attrs({ as: 'h2' })`
    ${createTypography({
      name: 'TitleLg',
      mobile: convertMidwestTokenToTypographyToken(brandTextMobileTitleLg),
      tablet: convertMidwestTokenToTypographyToken(brandTextMobileTitleLg),
      desktop: convertMidwestTokenToTypographyToken(brandTextDesktopTitleLg),
    })}
  `,
};
