import { BillStatus } from '@thrivent/supergraph-types';

export const enum BillProductType {
  annuity = 'annuity',
  insurance = 'insurance',
  allBills = 'allBills',
}

export type BillStatuses = {
  hasBillsPastDue: boolean;
  hasBillsDue: boolean;
  hasBillsScheduled: boolean;
  hasBillsPending: boolean;
  billsDueCount: number;
};

export type BillProps = {
  billStatus: BillStatus;
};
