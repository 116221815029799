import { getBeforeSend } from './beforeSend/getBeforeSend';
import { outlookSafeLinkFixBeforeSend } from './beforeSend/outlookSafeLinkFixBeforeSend';
import { getEnvVarNumberValue } from './getEnvVarNumberValue';

type InitDatadogOptions = {
  useOneTrust?: boolean;
  service?: string;
  trackUserInteractions?: boolean;
};

/*
  * Initialize the DataDog RUM and enable DataDog client logging.

  * The following environment variables are use to configure DataDog:
  * NEXT_PUBLIC_TRACE_SERVICE
  * NEXT_PUBLIC_TRACE_ENV
  * NEXT_PUBLIC_TRACE_VERSION
  * NEXT_PUBLIC_DD_APP_ID
  * NEXT_PUBLIC_DD_CLIENT_TOKEN
  * NEXT_PUBLIC_DATADOG_BROWSER_LOGS
  * NEXT_PUBLIC_DATADOG_RUM
  * NEXT_PUBLIC_DD_RUM_SAMPLE_RATE default 100
  * NEXT_PUBLIC_DD_BROWSER_LOGS_SAMPLE_RATE default 100
*/
export const initDatadog = (options?: InitDatadogOptions) => {
  initRum(options);
  initClientLogging(options);
};

const checkUrlAllowed = (url: string) => {
  const loc = new URL(url);
  return (
    loc.protocol.toLowerCase() === 'https:' &&
    (loc.hostname.endsWith('aws.tfcloud.corp') ||
      loc.hostname.endsWith('aws.thrivent.com'))
  );
};

const initRum = ({
  useOneTrust = false,
  service = process.env['NEXT_PUBLIC_TRACE_SERVICE'],
  trackUserInteractions = false,
}: InitDatadogOptions = {}) => {
  if (process.env['NEXT_PUBLIC_DATADOG_RUM'] === 'true') {
    import('@datadog/browser-rum').then(({ datadogRum }) => {
      datadogRum.init({
        beforeSend: getBeforeSend(useOneTrust),
        service,
        env: process.env['NEXT_PUBLIC_TRACE_ENV'] || 'none',
        version: process.env['NEXT_PUBLIC_TRACE_VERSION'] || 'none',
        applicationId: process.env['NEXT_PUBLIC_DD_APP_ID'] || '',
        clientToken: process.env['NEXT_PUBLIC_DD_CLIENT_TOKEN'] || '',
        enableExperimentalFeatures: ['feature_flags'],
        sessionSampleRate: getEnvVarNumberValue(
          'NEXT_PUBLIC_DD_RUM_SAMPLE_RATE',
          100
        ),
        sessionReplaySampleRate: 0, // Secure baseline: Do not capture user replay data in DataDog: https://thrivent.atlassian.net/browse/DOP-360
        trackUserInteractions,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
        allowedTracingUrls: [
          { match: checkUrlAllowed, propagatorTypes: ['tracecontext'] },
        ],
        excludedActivityUrls: [
          // Exclude health/liveliness checks
          /\/mgmt\/health\/liveness$/,
          /\/mgmt\/health\/readiness$/,
          /\/metrics$/,
        ],
      });
    });
  }
};

const initClientLogging = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useOneTrust = false,
  service = process.env['NEXT_PUBLIC_TRACE_SERVICE'],
} = {}) => {
  if (process.env['NEXT_PUBLIC_DATADOG_BROWSER_LOGS'] === 'true') {
    import('@datadog/browser-logs').then(({ datadogLogs }) => {
      datadogLogs.init({
        beforeSend: (event) => outlookSafeLinkFixBeforeSend(event),
        // eslint-disable-next-line @cspell/spellchecker
        site: 'datadoghq.com',
        service,
        env: process.env['NEXT_PUBLIC_TRACE_ENV'] || 'none',
        version: process.env['NEXT_PUBLIC_TRACE_VERSION'] || 'none',
        clientToken: process.env['NEXT_PUBLIC_DD_CLIENT_TOKEN'] || '',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        sessionSampleRate: getEnvVarNumberValue(
          'NEXT_PUBLIC_DD_BROWSER_LOGS_SAMPLE_RATE',
          100
        ),
      });
    });
  }
};
