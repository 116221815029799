import '../buttonHelpers';
import { ButtonVariationProps } from '../types';
import { backgroundColors } from './backgroundColors';
import { border, colors } from '@thrivent/midwest-shared';
import { css } from 'styled-components';

export const secondaryStrongStyles = css<ButtonVariationProps>`
  outline: none;
  background: ${backgroundColors.secondaryStrongBg};
  color: ${colors.componentButtonColorTextStrongSecondary};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background-color: ${colors.componentButtonColorBackgroundSubtleSecondaryHover};
      }
      &:hover {
        background: ${colors.componentButtonColorBackgroundStrongSecondaryHover};
      }
      &:active {
        background-color: ${colors.componentButtonColorBackgroundStrongSecondaryPressed};
        outline-color: ${colors.componentButtonColorBackgroundStrongSecondaryPressed};
      }
    `}
`;

export const secondarySubtleStyles = css<ButtonVariationProps>`
  background: ${backgroundColors.secondarySubtleBg};
  outline: ${border.widthDefault} solid
    ${colors.componentButtonColorBorderSubtleSecondaryDefault};
  color: ${colors.componentButtonColorTextSubtleSecondary};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background-color: ${colors.componentButtonColorBackgroundSubtleSecondaryHover};
      }
      &:hover {
        outline-color: ${colors.componentButtonColorBorderSubtleSecondaryHover};
        background-color: ${colors.componentButtonColorBackgroundSubtleSecondaryHover};
      }
      &:active {
        outline-color: ${colors.componentButtonColorBorderSubtleSecondaryPressed};
        background-color: ${colors.componentButtonColorBackgroundSubtleSecondaryPressed};
      }
    `}
`;
