import { ArrowInclineRightIcon } from '../styles';
import { Advisor } from '@servicing/data-queries';
import { AdvisorPictureAvatar } from '@servicing/molecules';
import {
  formatPhoneNumber,
  formatSecureEmail,
} from '@servicing/util-formatter';
import { FinancialAvatar } from '@thrivent-web/ui/icons';
import { THRIVENT_CLIENT_SERVICES_NUMBER } from '@thrivent-web/ui/utils';
import { MDSLink, MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

const AdvisorDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const AdvisorBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AvatarCircleCrop = styled.div`
  border-radius: 50%;
  flex-shrink: 0;
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
  overflow: hidden;
`;

const AdvisorLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AdvisorBuilder = ({ advisor }: { advisor: Advisor }) => {
  const hasAdvisor =
    advisor.name && advisor.name !== 'Thrivent Financial Guidance Team';
  const advisorPhone = advisor.phone || THRIVENT_CLIENT_SERVICES_NUMBER;

  return (
    <>
      <MDSText.TitleSm>Your Financial Advisor</MDSText.TitleSm>
      {hasAdvisor ? null : (
        <MDSText.BodyRegDefault as={'p'}>
          When you have questions, there&apos;s a whole team ready to help you
          reach financial clarity.
        </MDSText.BodyRegDefault>
      )}
      <AdvisorBlock>
        {!!advisor?.name && (
          <AdvisorDetailSection>
            <MDSText.BodyHeavyDefault as={'p'}>
              {advisor.name}
            </MDSText.BodyHeavyDefault>
            <MDSText.BodyRegDefault
              as={'p'}
              data-testid="dashboard-advisor-address"
            >
              {advisor.address?.city}, {advisor.address?.state}
            </MDSText.BodyRegDefault>
            <MDSText.BodyRegDefault as={'p'}>
              {advisor?.credentials}
            </MDSText.BodyRegDefault>
          </AdvisorDetailSection>
        )}
        {advisor.avatar ? (
          <AvatarCircleCrop>
            <AdvisorPictureAvatar
              url={advisor.avatar}
              plannerString={`Profile photo of ${
                advisor?.name || 'your advisor'
              }`}
            />
          </AvatarCircleCrop>
        ) : (
          <FinancialAvatar />
        )}
      </AdvisorBlock>
      <AdvisorLinksContainer>
        <MDSLink
          data-testid="nav-menu-advisor-phone"
          href={`tel:${advisorPhone}`}
        >
          {formatPhoneNumber(advisorPhone)}
        </MDSLink>
        <MDSLink
          aria-label={`Message my ${
            hasAdvisor ? 'advisor' : 'guidance team'
          } - opens in a new tab`}
          href={formatSecureEmail({ email: advisor?.email })}
          target="_blank"
        >
          Message my {hasAdvisor ? 'advisor' : 'guidance team'}
          <ArrowInclineRightIcon />
        </MDSLink>
      </AdvisorLinksContainer>
    </>
  );
};
