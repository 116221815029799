import { ClientError } from 'graphql-request';

const updatePreSubmitMutation =
  'mutation UpdatePreSubmit($input: ActionTeamPreSubmitInput!)';
const updateActionTeamReportMutation =
  'mutation UpdateActionTeamReport($input: ActionTeamReportInput!)';

export const removeSensitiveErrorMessageData = (
  queryName: string,
  error: ClientError
) => {
  if (
    !error?.message ||
    (queryName !== updatePreSubmitMutation &&
      queryName !== updateActionTeamReportMutation)
  ) {
    return error;
  }

  const errorMessage = error.message;

  const errorMessageNoNewlines = errorMessage.replace(/\n/g, '');
  const errorMessagePrefix = errorMessageNoNewlines.match(/^(.*?)\{/)?.[1];
  const messageToParse = errorMessageNoNewlines.match(/{.*$/)?.[0];

  if (!messageToParse) {
    return error;
  }

  const parsedMessage = JSON.parse(messageToParse);
  const parsedInput = parsedMessage?.request?.variables?.input;

  if (!parsedInput) {
    return error;
  }

  if (
    queryName === updatePreSubmitMutation &&
    parsedInput?.affiliatedParties?.leader &&
    parsedInput?.orders?.length > 0
  ) {
    parsedInput.affiliatedParties.leader.emailAddress = '';
    parsedInput.affiliatedParties.leader.phoneNumber = '';
    parsedInput.orders[0].shipping = null;

    parsedMessage.request.variables.input = parsedInput;
    error.message = `${errorMessagePrefix}${JSON.stringify(parsedMessage)}`;
  } else if (
    queryName === updateActionTeamReportMutation &&
    parsedInput?.affiliatedParties?.participants
  ) {
    parsedInput.affiliatedParties.participants = [];

    parsedMessage.request.variables.input = parsedInput;
    error.message = `${errorMessagePrefix}${JSON.stringify(parsedMessage)}`;
  }

  return error;
};
