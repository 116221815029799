import { GlobalAnalytics } from '../analytics';
import { AdobeTracking } from '../analytics/AdobeTracking';
import { BlueConicTracking } from '../analytics/BlueConicTracking';
import CaptureStartupLaunchdarklyFlags from '../data/CaptureStartupLaunchdarklyFlags';
import QueryClientProviderServicing from '../data/QueryClientProviderServicing';
import favicon from '../public/favicon.ico';
import '../util/TopProgressBar.css';
import { datadogRum } from '@datadog/browser-rum';
import {
  AuthProvider,
  getAccessToken,
  getConsumerIdFromBrowser,
} from '@servicing/auth';
import { isMissingAuthToken } from '@servicing/data-graphql';
import { makeQueryClient } from '@servicing/data-query-client';
import { Layout } from '@servicing/template';
import { LOGIN_ROUTE } from '@servicing/util-routes';
import { HydrationBoundary } from '@tanstack/react-query';
import { AnalyticsProvider, TrackingEvent } from '@thrivent-web/analytics';
import { useAuth } from '@thrivent-web/authentication';
import { MDSIdleUserTimeout } from '@thrivent-web/authentication-ui';
import { initDatadog } from '@thrivent-web/datadog';
import { ReactQueryDevToolsLoader } from '@thrivent-web/dev-tooling';
import { DatadogLicensePlate } from '@thrivent-web/license-plate';
import { logger } from '@thrivent-web/logging-utils';
import { GlobalStyle, theme } from '@thrivent-web/ui/tokens';
import { MDSConfigContext } from '@thrivent/midwest-web';
import { setCookie } from 'cookies-next';
import { minutesToMilliseconds } from 'date-fns';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Link from 'next/link';
import { ReactElement, ReactNode, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { ModalProvider } from 'react-modal-hook';
import { useEffectOnce } from 'react-use';

/* eslint-disable import/no-named-as-default, @typescript-eslint/no-unused-vars -- needed to make sure "sharp" is included in dist version */
// noinspection ES6UnusedImports
import Sharp from 'sharp';
import { ThemeProvider } from 'styled-components';

const isInIFrame =
  typeof window !== 'undefined' &&
  window?.location !== window?.parent?.location;

initDatadog({
  service: isInIFrame ? 'servicing-iframe' : 'servicing',
  trackUserInteractions: true,
});

if (process.env['NEXT_PUBLIC_API_MOCKING'] === 'enabled') {
  const { initMocks } = await import('@thrivent-web/mock-server');
  await initMocks();
}

// taken from next docs: https://nextjs.org/docs/basic-features/layouts#with-typescript
export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

const webAnalyticsRequest = (data: TrackingEvent) => {
  datadogRum.addAction('analytics', data);
  navigator.sendBeacon('/api/analytics', JSON.stringify(data));
};

const TopProgressBar = dynamic(() => import('../util/TopProgressBar'), {
  ssr: false,
});

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const ConditionalGlobalAnalytics = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <GlobalAnalytics /> : null;
};

const datadogEnabled = process.env['NEXT_PUBLIC_DATADOG_RUM'] === 'true';
const ConditionalDatadogLicensePlate = () => {
  return datadogEnabled ? <DatadogLicensePlate /> : null;
};

const getTestName = () => {
  return (
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('MSW_TEST_ID='))
      ?.split('=')[1] ?? ''
  );
};

function CustomApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const [renderClient, setRenderClient] = useState(false);
  const [queryClient] = useState(() =>
    makeQueryClient({
      getTestName,
      getConsumerId: getConsumerIdFromBrowser,
      getAccessToken,
      onQueryError: (error, { queryKey }) => {
        // @ts-expect-error - this is coming from graphql-request but I can't find the right way to cast it to the correct error type
        if (error?.response?.status === 401) {
          logger.warn(
            `queryClient - caught 401, redirecting to /login. query: ${queryKey.toString()}`,
            { queryKey }
          );
          setCookie('post-login-url', window.location.pathname);
          window.location.href = LOGIN_ROUTE;
          return undefined;
        } else if (isMissingAuthToken(error)) {
          return undefined;
        }

        return error;
      },
    })
  );

  useEffectOnce(() => {
    setRenderClient(true);
  });

  const getLayout =
    Component.getLayout ??
    ((page) => (
      <>
        <ConditionalDatadogLicensePlate />
        <AuthProvider>
          <MDSConfigContext.Provider value={{ linkComp: Link }}>
            <QueryClientProviderServicing queryClient={queryClient}>
              <HydrationBoundary state={pageProps.dehydratedState}>
                <AnalyticsProvider dispatch={webAnalyticsRequest}>
                  <AdobeTracking />
                  <BlueConicTracking />
                  <ThemeProvider theme={theme}>
                    <CaptureStartupLaunchdarklyFlags />
                    <ConditionalGlobalAnalytics />
                    <GlobalStyle />
                    <TopProgressBar />
                    <Layout>
                      <ModalProvider>
                        <MDSIdleUserTimeout
                          disabled={false}
                          idleTimeoutMs={minutesToMilliseconds(15)}
                          promptBeforeIdleTimeoutMs={minutesToMilliseconds(5)}
                          idleTimeoutCheckIntervalMs={minutesToMilliseconds(1)}
                          redirectOnLogout={true}
                        />
                        {page}
                      </ModalProvider>
                    </Layout>
                  </ThemeProvider>
                </AnalyticsProvider>
              </HydrationBoundary>
              <ReactQueryDevToolsLoader />
            </QueryClientProviderServicing>
          </MDSConfigContext.Provider>
        </AuthProvider>
      </>
    ));

  return renderClient ? (
    <>
      <Head>
        <link rel="shortcut icon" href={favicon.src} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Thrivent</title>
      </Head>

      {getLayout(<Component {...pageProps} />)}
    </>
  ) : null;
}

export default CustomApp;
