import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { LOGIN_ROUTE } from '@servicing/util-routes';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@thrivent-web/authentication';
import { ChevronRightSmall } from '@thrivent-web/ui/icons';
import { tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import { useRouter } from 'next/router';
import styled from 'styled-components';

export const ChevronRightIcon = styled((props) => (
  <ChevronRightSmall {...props} />
))`
  height: 1.5rem;
  width: 1.5rem;
  path {
    fill: ${(p) =>
      p.theme.midwestColors.componentButtonColorBackgroundStrongPrimaryDefault};
  }
`;

const ContentLink = styled(NavigationMenu.Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(p) => p.theme.midwestColors.textLink};
  width: 100%;
  gap: 0;

  ${tablet} {
    padding: 0;
  }
`;

export const LoginLogout = () => {
  const { signoutRedirect, isAuthenticated } = useAuth();
  const router = useRouter();
  const queryClient = useQueryClient();

  const login = () => {
    router.push(LOGIN_ROUTE);
  };

  const logout = () => {
    queryClient.clear();
    signoutRedirect();
  };

  return (
    <ContentLink
      onClick={() => {
        isAuthenticated ? logout() : login();
      }}
      tabIndex={0}
    >
      <MDSText.Subtitle>
        {isAuthenticated ? 'Log out' : 'Log in'}
      </MDSText.Subtitle>
      <ChevronRightIcon />
    </ContentLink>
  );
};
