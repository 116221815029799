import { removeSensitiveErrorMessageData } from './action-teams-error-handling/removeSensitiveErrorMessageData';
import { ClientError } from 'graphql-request';

// A HACK allow list for queries or mutations that have had
// their input variables manually sanitized
export const ALWAYS_LOG_VARIABLES_FOR_QUERIES_THAT_PROMISE_TO_BEHAVE = [
  'mutation UpdatePreSubmit($input: ActionTeamPreSubmitInput!)',
  'mutation UpdateActionTeamReport($input: ActionTeamReportInput!)',
  'mutation CancelActionTeam($input: ActionTeamCancelInput!)',
  'mutation CompleteActionTeam($id: ID!)',
  'mutation CopyFromExisting($input: ActionTeamCopyInput!)',
  'mutation CreateActionTeam($input: ActionTeamPreSubmitInput!)',
  'mutation EmailActionTeamSummary($input: ActionTeamSendEmailInput!)',
  'mutation SubmitActionTeamApplication($submitId: ID!)',
];

/**
 * Converts the string syntax for a GraphQL query into a shortened
 * format including the name and parameters, e.g.
 *
 *    "\n    query Dashboard($consumerId: String!) { ..."
 *
 * is converted to:
 *
 *    "query Dashboard($consumerId: String!)"
 *
 * @param query
 * @return the simplified query syntax
 */
const simplifyQuery = (query: string) => query.split(' {')[0]?.trim() ?? '';

const simplifyQueries = (query: string | string[]) =>
  Array.isArray(query) ? query.map(simplifyQuery) : simplifyQuery(query);

const allowQuery = (query: string) =>
  ALWAYS_LOG_VARIABLES_FOR_QUERIES_THAT_PROMISE_TO_BEHAVE.includes(
    simplifyQuery(query)
  );

const allowLogVariables = (query: string | string[]) =>
  Array.isArray(query) ? query.every(allowQuery) : allowQuery(query);

/**
 * Removes `request.variables` and `response.data` from `ClientError`
 * instances (GraphQL request errors)
 *
 * @param error
 * @param queryName
 * @returns a new sanitized `ClientError` if the input is such an
 * instance, or the original error
 */
export const sanitizeClientError = (error: unknown, queryName: string) => {
  if (error instanceof ClientError) {
    const { data, ...response } = error.response;
    const { variables, ...request } = error.request;

    // Ignore data and variables
    void data, variables;

    if (allowLogVariables(queryName)) {
      return removeSensitiveErrorMessageData(queryName, error);
    }

    const sanitized = new ClientError(response, {
      ...request,
      query: simplifyQueries(request.query),
    });
    // Stack traces aren't particularly useful for these errors
    if ('stack' in sanitized) {
      delete sanitized.stack;
    }
    return sanitized;
  }
  return error;
};
