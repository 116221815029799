import {
  BaseTypography,
  type TextProps,
  TypographyToken,
  createTypography,
} from './createTypography';
import {
  MidwestTypographyToken,
  componentTableTextHeading,
  textBodyHeavyDefault,
  textBodyHeavySm,
  textBodyHeavyXs,
  textBodyMediumDefault,
  textBodyMediumSm,
  textBodyMediumXs,
  textBodyRegDefault,
  textBodyRegSm,
  textBodyRegXs,
  textDataDesktopLg,
  textDataDesktopMd,
  textDataDesktopSm,
  textDataMobileLg,
  textDataMobileMd,
  textDataMobileSm,
  textHeroDesktopLg,
  textHeroDesktopMd,
  textHeroDesktopSm,
  textHeroMobileLg,
  textHeroMobileMd,
  textHeroMobileSm,
  textHeroMobileXl,
  textInterfaceHeavyDefault,
  textInterfaceHeavySmall,
  textInterfaceHeavyXs,
  textInterfaceMediumDefault,
  textInterfaceMediumSmall,
  textInterfaceMediumXs,
  textInterfaceSemiDefault,
  textInterfaceSemiSm,
  textInterfaceSemiXs,
  textMiniDefault,
  textMiniDefaultLink,
  textRegDefaultLink,
  textRegSmLink,
  textRegXsLink,
  textSubTitleDesktopMd,
  textSubTitleMobileMd,
  textTitleDesktopLg,
  textTitleDesktopMd,
  textTitleDesktopSm,
  textTitleDesktopXl,
  textTitleMobileLg,
  textTitleMobileMd,
  textTitleMobileSm,
  textTitleMobileXl,
} from '@thrivent/midwest-shared';
import styled from 'styled-components';

function convertMidwestTokenToTypographyToken(
  midwestToken: MidwestTypographyToken
): TypographyToken {
  return {
    font: midwestToken.font,
    rem: `${midwestToken.rem}rem`,
    weight: parseInt(midwestToken.weight),
    lineHeight: `${midwestToken.lineHeight / 16}rem`,
  };
}

export const MDSText = {
  BodyHeavyDefault: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'BodyHeavyDefault',
      mobile: convertMidwestTokenToTypographyToken(textBodyHeavyDefault),
    })}
  `,
  BodyHeavySm: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'BodyHeavySm',
      mobile: convertMidwestTokenToTypographyToken(textBodyHeavySm),
    })}
  `,
  BodyHeavyXs: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'BodyHeavyXs',
      mobile: convertMidwestTokenToTypographyToken(textBodyHeavyXs),
    })}
  `,
  BodyRegXs: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'BodyRegXs',
      mobile: convertMidwestTokenToTypographyToken(textBodyRegXs),
    })}
  `,
  BodyMediumDefault: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'BodyMediumDefault',
      mobile: convertMidwestTokenToTypographyToken(textBodyMediumDefault),
    })}
  `,
  BodyMediumSm: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'BodyMediumSm',
      mobile: convertMidwestTokenToTypographyToken(textBodyMediumSm),
    })}
  `,
  BodyMediumXs: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'BodyMediumXs',
      mobile: convertMidwestTokenToTypographyToken(textBodyMediumXs),
    })}
  `,
  BodyRegDefault: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'BodyRegDefault',
      mobile: convertMidwestTokenToTypographyToken(textBodyRegDefault),
    })}
  `,
  BodyRegSm: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'BodyRegSm',
      mobile: convertMidwestTokenToTypographyToken(textBodyRegSm),
    })}
  `,
  MiniDefault: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'MiniDefault',
      mobile: convertMidwestTokenToTypographyToken(textMiniDefault),
    })}
  `,
  MiniDefaultLink: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'MiniDefaultLink',
      mobile: convertMidwestTokenToTypographyToken(textMiniDefaultLink),
    })}
  `,
  RegDefaultLink: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'RegDefaultLink',
      mobile: convertMidwestTokenToTypographyToken(textRegDefaultLink),
    })}
  `,
  RegSmLink: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'RegSmLink',
      mobile: convertMidwestTokenToTypographyToken(textRegSmLink),
    })}
  `,
  RegXsLink: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'RegXsLink',
      mobile: convertMidwestTokenToTypographyToken(textRegXsLink),
    })}
  `,
  Subtitle: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'Subtitle',
      mobile: convertMidwestTokenToTypographyToken(textSubTitleMobileMd),
      tablet: convertMidwestTokenToTypographyToken(textSubTitleMobileMd),
      desktop: convertMidwestTokenToTypographyToken(textSubTitleDesktopMd),
    })}
  `,

  TitleSm: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'h2',
  }))`
    ${createTypography({
      name: 'TitleSm',
      mobile: convertMidwestTokenToTypographyToken(textTitleMobileSm),
      tablet: convertMidwestTokenToTypographyToken(textTitleMobileSm),
      desktop: convertMidwestTokenToTypographyToken(textTitleDesktopSm),
    })}
  `,
  TitleMd: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'h2',
  }))`
    ${createTypography({
      name: 'TitleMd',
      mobile: convertMidwestTokenToTypographyToken(textTitleMobileMd),
      tablet: convertMidwestTokenToTypographyToken(textTitleMobileMd),
      desktop: convertMidwestTokenToTypographyToken(textTitleDesktopMd),
    })}
  `,
  TitleLg: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'h2',
  }))`
    ${createTypography({
      name: 'TitleLg',
      mobile: convertMidwestTokenToTypographyToken(textTitleMobileLg),
      tablet: convertMidwestTokenToTypographyToken(textTitleMobileLg),
      desktop: convertMidwestTokenToTypographyToken(textTitleDesktopLg),
    })}
  `,
  TitleXl: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'h2',
  }))`
    ${createTypography({
      name: 'TitleXl',
      mobile: convertMidwestTokenToTypographyToken(textTitleMobileXl),
      tablet: convertMidwestTokenToTypographyToken(textTitleMobileXl),
      desktop: convertMidwestTokenToTypographyToken(textTitleDesktopXl),
    })}
  `,
  DataSm: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'DataSm',
      mobile: convertMidwestTokenToTypographyToken(textDataMobileSm),
      tablet: convertMidwestTokenToTypographyToken(textDataMobileSm),
      desktop: convertMidwestTokenToTypographyToken(textDataDesktopSm),
    })}
  `,
  DataMd: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'DataMd',
      mobile: convertMidwestTokenToTypographyToken(textDataMobileMd),
      tablet: convertMidwestTokenToTypographyToken(textDataMobileMd),
      desktop: convertMidwestTokenToTypographyToken(textDataDesktopMd),
    })}
  `,
  DataLg: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'DataLg',
      mobile: convertMidwestTokenToTypographyToken(textDataMobileLg),
      tablet: convertMidwestTokenToTypographyToken(textDataMobileLg),
      desktop: convertMidwestTokenToTypographyToken(textDataDesktopLg),
    })}
  `,
  HeroSm: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'h2',
  }))`
    ${createTypography({
      name: 'HeroSm',
      mobile: convertMidwestTokenToTypographyToken(textHeroMobileSm),
      tablet: convertMidwestTokenToTypographyToken(textHeroMobileSm),
      desktop: convertMidwestTokenToTypographyToken(textHeroDesktopSm),
    })}
  `,
  HeroMd: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'h1',
  }))`
    ${createTypography({
      name: 'HeroMd',
      mobile: convertMidwestTokenToTypographyToken(textHeroMobileMd),
      tablet: convertMidwestTokenToTypographyToken(textHeroMobileMd),
      desktop: convertMidwestTokenToTypographyToken(textHeroDesktopMd),
    })}
  `,
  HeroLg: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'h1',
  }))`
    ${createTypography({
      name: 'HeroLg',
      mobile: convertMidwestTokenToTypographyToken(textHeroMobileLg),
      tablet: convertMidwestTokenToTypographyToken(textHeroMobileLg),
      desktop: convertMidwestTokenToTypographyToken(textHeroDesktopLg),
    })}
  `,
  HeroXl: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'h1',
  }))`
    ${createTypography({
      name: 'HeroXl',
      mobile: convertMidwestTokenToTypographyToken(textHeroMobileXl),
      tablet: convertMidwestTokenToTypographyToken(textHeroMobileXl),
      desktop: convertMidwestTokenToTypographyToken(textHeroMobileXl),
    })}
  `,
  TextInterfaceHeavyDefault: styled(BaseTypography).attrs(
    ({ as }: TextProps) => ({
      as: as || 'span',
    })
  )`
    ${createTypography({
      name: 'TextInterfaceHeavyDefault',
      mobile: convertMidwestTokenToTypographyToken(textInterfaceHeavyDefault),
      tablet: convertMidwestTokenToTypographyToken(textInterfaceHeavyDefault),
      desktop: convertMidwestTokenToTypographyToken(textInterfaceHeavyDefault),
    })}
  `,
  TextInterfaceHeavySmall: styled(BaseTypography).attrs(
    ({ as }: TextProps) => ({
      as: as || 'span',
    })
  )`
    ${createTypography({
      name: 'TextInterfaceHeavySmall',
      mobile: convertMidwestTokenToTypographyToken(textInterfaceHeavySmall),
      tablet: convertMidwestTokenToTypographyToken(textInterfaceHeavySmall),
      desktop: convertMidwestTokenToTypographyToken(textInterfaceHeavySmall),
    })}
  `,
  TextInterfaceHeavyXs: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'TextInterfaceHeavyXs',
      mobile: convertMidwestTokenToTypographyToken(textInterfaceHeavyXs),
      tablet: convertMidwestTokenToTypographyToken(textInterfaceHeavyXs),
      desktop: convertMidwestTokenToTypographyToken(textInterfaceHeavyXs),
    })}
  `,
  TextInterfaceSemiDefault: styled(BaseTypography).attrs(
    ({ as }: TextProps) => ({
      as: as || 'span',
    })
  )`
    ${createTypography({
      name: 'TextInterfaceSemiDefault',
      mobile: convertMidwestTokenToTypographyToken(textInterfaceSemiDefault),
      tablet: convertMidwestTokenToTypographyToken(textInterfaceSemiDefault),
      desktop: convertMidwestTokenToTypographyToken(textInterfaceSemiDefault),
    })}
  `,
  TextInterfaceSemiSm: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'TextInterfaceSemiSm',
      mobile: convertMidwestTokenToTypographyToken(textInterfaceSemiSm),
      tablet: convertMidwestTokenToTypographyToken(textInterfaceSemiSm),
      desktop: convertMidwestTokenToTypographyToken(textInterfaceSemiSm),
    })}
  `,
  TextInterfaceSemiXs: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'TextInterfaceSemiXs',
      mobile: convertMidwestTokenToTypographyToken(textInterfaceSemiXs),
      tablet: convertMidwestTokenToTypographyToken(textInterfaceSemiXs),
      desktop: convertMidwestTokenToTypographyToken(textInterfaceSemiXs),
    })}
  `,
  TextInterfaceMediumDefault: styled(BaseTypography).attrs(
    ({ as }: TextProps) => ({
      as: as || 'span',
    })
  )`
    ${createTypography({
      name: 'TextInterfaceMediumDefault',
      mobile: convertMidwestTokenToTypographyToken(textInterfaceMediumDefault),
      tablet: convertMidwestTokenToTypographyToken(textInterfaceMediumDefault),
      desktop: convertMidwestTokenToTypographyToken(textInterfaceMediumDefault),
    })}
  `,
  TextInterfaceMediumSmall: styled(BaseTypography).attrs(
    ({ as }: TextProps) => ({
      as: as || 'span',
    })
  )`
    ${createTypography({
      name: 'TextInterfaceMediumSmall',
      mobile: convertMidwestTokenToTypographyToken(textInterfaceMediumSmall),
      tablet: convertMidwestTokenToTypographyToken(textInterfaceMediumSmall),
      desktop: convertMidwestTokenToTypographyToken(textInterfaceMediumSmall),
    })}
  `,
  TextInterfaceMediumXs: styled(BaseTypography).attrs(({ as }: TextProps) => ({
    as: as || 'span',
  }))`
    ${createTypography({
      name: 'TextInterfaceMediumXs',
      mobile: convertMidwestTokenToTypographyToken(textInterfaceMediumXs),
      tablet: convertMidwestTokenToTypographyToken(textInterfaceMediumXs),
      desktop: convertMidwestTokenToTypographyToken(textInterfaceMediumXs),
    })}
  `,
  ComponentTableTextHeading: styled(BaseTypography).attrs(
    ({ as }: TextProps) => ({
      as: as || 'span',
    })
  )`
    ${createTypography({
      name: 'ComponentTableTextHeading',
      mobile: convertMidwestTokenToTypographyToken(componentTableTextHeading),
      tablet: convertMidwestTokenToTypographyToken(componentTableTextHeading),
      desktop: convertMidwestTokenToTypographyToken(componentTableTextHeading),
    })}
  `,
};
