import { ExternalLinkButton, OldTownLink } from '@servicing/molecules';
import { BodyText, Link } from '@thrivent-web/ui/atoms';
import NextLink from 'next/link';
import styled from 'styled-components';

export const NavColumnHeader = styled(BodyText.HeavyDefault)`
  display: flex;
`;

export const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1.75rem 0 0;

  li:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export type TargetProps = '_blank' | '_self';

export const Logo = styled(Link)`
  display: inline-block;
`;

export const NavColumn = styled.nav`
  display: flex;
  flex-direction: column;
  min-width: 10rem;
`;

const StyledLink = styled.a<{ color?: string }>`
  color: ${(p) => p.color || 'white'};
  font-size: 0.875rem;
`;

export const NavLink = ({
  href,
  children,
  ariaLabel,
  onClick,
  target = '_self',
}: {
  href: string;
  children: React.ReactNode;
  ariaLabel?: string;
  target?: TargetProps;
  onClick?: () => void;
}) => (
  <NextLink href={href} passHref legacyBehavior>
    <StyledLink onClick={onClick} target={target} aria-label={ariaLabel}>
      {children}
    </StyledLink>
  </NextLink>
);

export const NavListItem = ({
  href,
  target,
  title,
  onClick,
  className,
}: {
  href: string;
  target: TargetProps;
  title: string;
  className?: string;
  onClick?: () => void;
}) => (
  <li className={className}>
    <NavLink
      href={href}
      target={target}
      ariaLabel={target === '_blank' ? `${title} - opens in a new tab` : title}
      onClick={onClick}
    >
      {title}
    </NavLink>
  </li>
);

const OldTownLinkText = styled.span`
  font-size: 0.875rem;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

export const OldTownFooterLink = ({
  href,
  title,
}: {
  href: string;
  title: string;
}) => (
  <li>
    <OldTownLink href={href}>
      <ExternalLinkButton aria-label={`${title} - opens in a new tab`}>
        <OldTownLinkText>{title}</OldTownLinkText>
      </ExternalLinkButton>
    </OldTownLink>
  </li>
);
