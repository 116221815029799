import { getBadgeColor } from '../../Payments/utils/billStatusColors';
import { ProductTypes } from './ProductTypes';
import { Badge } from '@midwest/web/indicators';
import {
  BillsDetailFragment,
  OutstandingClientGroupBillFragment,
  PaymentDetailFragment,
} from '@servicing/data-queries';
import {
  BillProductType,
  useBillStatusIndicator,
} from '@servicing/transactions';

type MultipleBills = {
  bills: BillsDetailFragment[];
  groupBills?: OutstandingClientGroupBillFragment[];
  bill?: never;
  scheduledBill?: never;
  productType?: ProductTypes;
};

type SingleBill = {
  bill: BillsDetailFragment;
  productType?: ProductTypes;
  bills?: never;
  groupBills?: never;
  scheduledBill?: never;
};

type SingleScheduledPayment = {
  scheduledBill: PaymentDetailFragment;
  productType?: ProductTypes;
  bill?: never;
  bills?: never;
  groupBills?: never;
};

type Props = MultipleBills | SingleBill | SingleScheduledPayment;

const productTypeToBillProductType = {
  [ProductTypes.insurance]: BillProductType.insurance,
  [ProductTypes.annuity]: BillProductType.annuity,
  [ProductTypes.allBills]: BillProductType.allBills,
};

export const BillStatusIndicatorTag = ({
  bills = [],
  bill,
  scheduledBill,
  productType,
  groupBills = [],
}: Props) => {
  const { hasBillsDue, billStatus, statusTag } = useBillStatusIndicator({
    bills,
    singleBill: bill,
    scheduledPayment: scheduledBill,
    productType: productType && productTypeToBillProductType[productType],
    groupBills,
  });

  return (hasBillsDue || !!scheduledBill) && billStatus ? (
    <Badge
      data-testid="bill-status-badge"
      size="small"
      type={getBadgeColor(billStatus)}
      label={statusTag}
    />
  ) : null;
};
