import {
  AdvisorBuilder,
  NavMenuLink,
  NavMenuTriggerWithCaret,
} from '../shared/components';
import { NavMenuItemEnum } from '../shared/enums';
import {
  ArrowInclineRightIcon,
  ContentSection,
  ContentSectionLabel,
  LinkOutIcon,
  NavMenuContent,
  NavMenuContentSectionWithSeparator,
  NavMenuItem,
  SectionDivider,
} from '../shared/styles';
import { NavMenuProps } from '../shared/types';
import { useFinancialAdvisor } from '@servicing/data-queries';
import {
  formatPhoneNumber,
  formatSecureEmail,
} from '@servicing/util-formatter';
import { useAuth } from '@thrivent-web/authentication';
import { THRIVENT_CLIENT_SERVICES_NUMBER } from '@thrivent-web/ui/utils';
import { MDSLink, MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

export const BOX_SECURE_MESSAGE_EMAIL = 'boxsecuremessage@thrivent.com';
export const FAQ_URL = 'https://www.thrivent.com/about-us/faq';
export const MONEY_CANVAS_URL =
  'https://www.thrivent.com/generosity/money-canvas';
export const TAX_RESOURCE_CENTER_URL =
  'https://www.thrivent.com/tax-resource-center';
export const CLAIMS_URL = 'https://www.thrivent.com/claims';

const ContactItem = () => {
  return (
    <MDSLink
      aria-label="Send us a secure message - opens in a new tab"
      href={formatSecureEmail({ email: BOX_SECURE_MESSAGE_EMAIL })}
      target="_blank"
    >
      Send us a secure message
      <ArrowInclineRightIcon />
    </MDSLink>
  );
};

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const AdvisorItem = () => {
  const { advisor } = useFinancialAdvisor();
  return advisor ? <AdvisorBuilder advisor={advisor} /> : null;
};

export const SupportMenu = ({ currentMenuItem = '' }: NavMenuProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <NavMenuItem
      value={NavMenuItemEnum.SupportMenu}
      open={NavMenuItemEnum.SupportMenu === currentMenuItem}
    >
      <NavMenuTriggerWithCaret>Support</NavMenuTriggerWithCaret>
      <NavMenuContent>
        <ContentSection key="resources">
          <ContentSectionLabel>Resources</ContentSectionLabel>
          <NavMenuLink
            name="Tax resource center"
            icon={<LinkOutIcon />}
            url={TAX_RESOURCE_CENTER_URL}
            target="_blank"
          />
          <NavMenuLink
            name="Money Canvas"
            icon={<LinkOutIcon />}
            url={MONEY_CANVAS_URL}
            target="_blank"
          />
          <NavMenuLink
            name="Claims"
            icon={<LinkOutIcon />}
            url={CLAIMS_URL}
            target="_blank"
          />
          <NavMenuLink
            name="FAQ"
            icon={<LinkOutIcon />}
            url={FAQ_URL}
            target="_blank"
          />
        </ContentSection>
        <SectionDivider />
        <NavMenuContentSectionWithSeparator>
          <ContentSection key="clientServices">
            <ContentSectionLabel>Client services</ContentSectionLabel>
            <MDSText.BodyRegDefault>
              Get in touch for help with your account or to learn more about
              what we offer.
            </MDSText.BodyRegDefault>
            <ContactInfoContainer>
              <MDSLink href={`tel:${THRIVENT_CLIENT_SERVICES_NUMBER}`}>
                {formatPhoneNumber(THRIVENT_CLIENT_SERVICES_NUMBER)}
              </MDSLink>
              {isAuthenticated && <ContactItem />}
            </ContactInfoContainer>
          </ContentSection>
        </NavMenuContentSectionWithSeparator>
        {isAuthenticated && (
          <>
            <SectionDivider />
            <NavMenuContentSectionWithSeparator>
              <AdvisorItem />
            </NavMenuContentSectionWithSeparator>
            <SectionDivider />
          </>
        )}
      </NavMenuContent>
    </NavMenuItem>
  );
};
