import { oneTrustBeforeSend } from './oneTrustBeforeSend';
import { outlookSafeLinkFixBeforeSend } from './outlookSafeLinkFixBeforeSend';
import { RumInitConfiguration } from '@datadog/browser-rum';

type GetBeforeSend = (
  useOneTrust: boolean
) => RumInitConfiguration['beforeSend'];

export const getBeforeSend: GetBeforeSend = (useOneTrust) =>
  useOneTrust
    ? (event) => outlookSafeLinkFixBeforeSend(event) && oneTrustBeforeSend()
    : (event) => outlookSafeLinkFixBeforeSend(event);
