import { HelpCircle, MDSText, WarningStop } from '@midwest/web/base';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { breakpoints, colors, radius, spacing } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const AlertDialogRoot = styled(AlertDialog.Root)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.xlg};
  border-radius: ${radius.containerRadius};
  background: ${colors.backgroundPrimary};
`;

export const AlertDialogOverlay = styled(AlertDialog.Overlay)`
  background-color: rgba(54, 54, 54, 0.5);
  position: fixed;
  inset: 0;

  @media screen and (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: overlayShow 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: overlayHide 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes overlayHide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const AlertDialogContent = styled(AlertDialog.Content)<{
  $zIndex?: number;
}>`
  width: calc(100vw - 64px);
  border-radius: ${radius.containerRadius};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.backgroundPrimary};
  z-index: ${(p) => p.$zIndex};

  ${breakpoints.tablet} {
    min-width: 480px;
    max-width: 600px;
  }

  @media screen and (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: contentShow 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: contentHide 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
  @keyframes contentHide {
    from {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
  }
`;

export const AlertDialogText = styled.div`
  padding: ${spacing.md};
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoints.tablet} {
    min-width: 480px;
    max-width: 600px;
    padding: ${spacing.xlg};
  }
`;

export const AlertDialogHeaderAndDescription = styled.div`
  gap: ${spacing.xsm};
  display: flex;
  flex-direction: column;
`;

export const AlertDialogTitle = styled(MDSText.TitleLg).attrs({ as: 'p' })`
  color: ${colors.textPrimary};
  text-align: center;
  ${breakpoints.tablet} {
    text-align: left;
  }
`;

export const AlertDialogDescription = styled(AlertDialog.Description)`
  width: 100%;
  text-align: center;
  ${breakpoints.tablet} {
    text-align: left;
  }
`;

export const AlertDialogDescriptionText = styled(MDSText.BodyRegDefault)`
  color: ${colors.textPrimary};
  margin-bottom: ${spacing.xlg};
`;

const BaseLayout = styled.div`
  gap: ${spacing.xsm};
  display: flex;
  flex-direction: column;
`;

export const ButtonLayout = styled(BaseLayout)`
  margin-top: ${spacing.md};
  width: 100%;
  ${breakpoints.tablet} {
    margin-top: ${spacing.xlg};
    margin-left: auto;
    flex-direction: row-reverse;
    gap: ${spacing.md};
    width: auto;
  }

  & button {
    width: 100%;
    ${breakpoints.tablet} {
      width: auto;
    }
  }
`;

export const HeaderLayout = styled(BaseLayout)`
  margin-right: 0;
  align-items: center;
  justify-content: center;
  ${breakpoints.tablet} {
    margin-right: auto;
    flex-direction: row;
    gap: ${spacing.sm};
  }
`;

export const HelpCircleIcon = styled((props) => <HelpCircle {...props} />)`
  height: 80px;
  width: 80px;
  ${breakpoints.tablet} {
    height: 42px;
    width: 42px;
  }

  fill: ${colors.functionalTextLink};
`;

export const WarningStopIcon = styled((props) => <WarningStop {...props} />)`
  height: 80px;
  width: 80px;
  ${breakpoints.tablet} {
    height: 42px;
    width: 42px;
  }

  fill: ${colors.sentimentDangerMedium};
`;

export const StyledCustomIcon = styled.div<{ $type?: string }>`
  height: 80px;
  width: 80px;

  ${breakpoints.tablet} {
    height: 42px;
    width: 42px;
  }

  fill: ${(p) =>
    p.$type === 'confirm'
      ? colors.textLink
      : p.$type === 'warn'
      ? colors.sentimentDangerMedium
      : colors.textLink};
`;
