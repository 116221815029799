import { FlagValues, flagKeys } from '../flags';
import type { ContextWithMeta } from '../types';
import { isFlagKey } from '../types';
import { isContextMetaServer, logger } from '@thrivent-web/logging-utils';
import { getCookies } from 'cookies-next';
import type { OptionsType, TmpCookiesObj } from 'cookies-next/src/types';

export const fetchCookieFlags = async () => {
  try {
    const resp = await fetch('/api/cookieFlags');
    return await resp.json();
  } catch (error) {
    logger.error(
      'feature-management/flagCookies - Could not fetch API flags',
      error
    );
    return {};
  }
};

export const getClientCookies = async () => {
  // Hack: I'm not sure how to have TS check to make sure this response is type [key: string]: string }
  const cookies = (await fetchCookieFlags()) as TmpCookiesObj;
  return getKnownFlagsFromCookies(cookies);
};

export const getKnownFlagsFromCookies = (cookies: TmpCookiesObj) => {
  return flagKeys.reduce<FlagValues>((flags, flagKey: string) => {
    if (!isFlagKey(flagKey)) return flags;

    const value = cookies[flagKey];
    if (value === undefined || value === null) return flags;
    return {
      ...flags,
      [flagKey]: value?.toString()?.toLowerCase()?.includes('true'),
    };
  }, {});
};

const getSSRCookies = (ssrContext: OptionsType) => {
  let cookies = getCookies(ssrContext);

  // Sometimes cookies is a Map at SSR time
  if (cookies instanceof Map) {
    cookies = Object.fromEntries(cookies);
  }

  return getKnownFlagsFromCookies(cookies);
};

export const getAllCookieFlags = (context?: ContextWithMeta) => {
  const ssrContext = isContextMetaServer(context?.meta)
    ? context?.meta?.ssrContext
    : undefined;

  return ssrContext ? getSSRCookies(ssrContext) : getClientCookies();
};
