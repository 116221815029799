import { QueryFunctionContext } from '@tanstack/react-query';
import { Variables } from 'graphql-request';

// The global allow list for query and mutation variables that should always
// be logged.
//
// These should be generally useful for observability and error investigations.
// These must NOT be personally identifiable information (PII).
export const ALWAYS_LOG_VARIABLES = ['productTypeCode'] as const;

type LogVariablesMeta = {
  logVariables?: Array<string>;
};

const isLogVariablesMeta = (
  contextMeta: unknown
): contextMeta is LogVariablesMeta => {
  if (contextMeta) {
    const meta = contextMeta as LogVariablesMeta;

    return (
      Array.isArray(meta.logVariables) &&
      meta.logVariables.every((variable) => typeof variable === 'string')
    );
  }
  return false;
};

export const logVariables = (
  context?: QueryFunctionContext,
  variables?: Variables
) => {
  if (variables && Object.keys(variables).length) {
    const metaLogVariables =
      context &&
      isLogVariablesMeta(context.meta) &&
      context.meta.logVariables?.length
        ? context.meta.logVariables
        : [];

    if (ALWAYS_LOG_VARIABLES.length || metaLogVariables.length) {
      return [...ALWAYS_LOG_VARIABLES, ...metaLogVariables].reduce<Variables>(
        (allowed, name) => {
          if (name in variables) {
            allowed[name] = variables[name];
          }
          return allowed;
        },
        {}
      );
    }
  }
  return {};
};
