import { ChannelContextKind } from '../types';
import { hasTermExpiryNotification, noErrors } from '../userContext';
import {
  UserClientContextLdQuery,
  useUserClientContextLdQuery,
} from '../userContext/userContextForLaunchDarkly.generated';
import { isMissingAuthToken, isUnauthorized } from '@servicing/data-graphql';
import { QueryClient } from '@tanstack/react-query';
import { logger } from '@thrivent-web/logging-utils';

const webChannelContext: ChannelContextKind = {
  key: 'web',
  isWeb: true,
  isMobileApp: false,
};

const getUserContext = (data?: UserClientContextLdQuery) => {
  const userContext = {
    kind: 'user',
    key: data?.client.consumerId ?? 'user',
    isEligibleForPayment: noErrors(data),
    hasTermNotificationsForExperiment: hasTermExpiryNotification(data),
  };

  logger.debug(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `UserContext ${userContext?.key} in healthPaymentSegment: ${userContext?.isEligibleForPayment}`,
    userContext
  );

  return {
    kind: 'multi' as const,
    user: userContext,
    channel: webChannelContext,
  };
};

export const anonymousUserContext = {
  kind: 'multi' as const,
  user: {
    kind: 'user',
    key: 'user',
    anonymous: true,
  },
  channel: webChannelContext,
};

export const fetchUserContextLD = async (
  queryClient: QueryClient,
  consumerId: string
) => {
  try {
    if (!consumerId) {
      return anonymousUserContext;
    }

    const queryOptionsMeta = queryClient.getDefaultOptions().queries?.meta;
    const data = await queryClient.fetchQuery({
      queryKey: useUserClientContextLdQuery.getKey(),
      queryFn: useUserClientContextLdQuery.fetcher(),
      meta: {
        ...queryOptionsMeta,
        enablePartial: true,
      },
    });

    return getUserContext(data);
  } catch (error) {
    // don't log auth errors
    if (!isMissingAuthToken(error) && !isUnauthorized(error)) {
      logger.error('useUserContextLD: fetchUserContextLD error', error);
    }
    return anonymousUserContext;
  }
};
