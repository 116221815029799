import { oidcClientSettings } from './config';
import { OIDCProvider, SessionMonitor } from '@thrivent-web/authentication';
import { FC, PropsWithChildren } from 'react';

const router = {
  push: async (url: string) => {
    window.location.assign(url);
    // eslint-disable-next-line @typescript-eslint/await-thenable
    return await true;
  },
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const pathname = window.location.pathname;

  // authentication routes have their own session management logic
  const isSessionMonitorEnabled = ![
    '/authentication/login',
    '/authentication/callback',
  ].includes(pathname);

  // active session should persist when user is on these pages,
  // but the user should remain on the page if the session ends.
  const shouldRedirectToLoginOnSessionEnd = ![
    '/authentication/login',
    '/authentication/callback',
    '/error',
    '/401',
    '/404',
    '/500',
    '/flags',
    '/mgmt',
    '/setFlag',
  ].includes(pathname);

  return (
    <OIDCProvider
      {...oidcClientSettings}
      router={router}
      loginPageRoute="/authentication/login"
    >
      <SessionMonitor
        redirectOnEndSession={shouldRedirectToLoginOnSessionEnd}
        disabled={!isSessionMonitorEnabled}
      />

      {children}
    </OIDCProvider>
  );
};
