import { FlagValues, defaultFlags, flagKeys } from '../flags';
import { isFlagKey } from '../types';
import { QueryFlagsResult } from './types';

// import { logger } from '@thrivent-web/logging-utils';

// const logName = 'feature-management/flagsPrecedenceByOrigin -';

export const flagsPrecedenceByOrigin = (
  envFlags: FlagValues = {},
  cookieFlags: FlagValues = {},
  launchDarklyFlags: FlagValues = {}
): QueryFlagsResult => {
  const currentFlags = flagKeys.reduce<FlagValues>(
    (flags, flagKey) => {
      if (!isFlagKey(flagKey)) return flags;

      if (cookieFlags?.[flagKey] !== undefined) {
        // logger.debug(`${logName} cookieFlag:`, { flagKey });
        return { ...flags, [flagKey]: cookieFlags[flagKey] };
      }

      if (
        launchDarklyFlags?.[flagKey] !== undefined &&
        // eslint-disable-next-line eqeqeq
        typeof launchDarklyFlags?.[flagKey] == 'boolean'
      ) {
        // logger.debug(`${logName} launchDarklyFlag:`, { flagKey });
        return { ...flags, [flagKey]: launchDarklyFlags[flagKey] };
      }

      if (envFlags?.[flagKey] !== undefined) {
        // logger.debug(`${logName} envFlag:`, { flagKey });
        return { ...flags, [flagKey]: envFlags[flagKey] };
      }

      return flags;
    },
    { ...defaultFlags }
  );

  // @ts-expect-error $flagsState, $valid _are_ actually on this object
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { $flagsState, $valid, ...ldFlags } = launchDarklyFlags ?? {};

  const flags = {
    currentValues: currentFlags,
    origins: {
      cookieFlags,
      launchDarklyFlags: ldFlags,
      envFlags,
      defaultFlags,
    },
  };

  // logger.debug('feature-management/flagsQuery - flags', flags);
  return flags;
};
