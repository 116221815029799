import ActionTeamsAnniversaryImage from './assets/png/action-teams-anniversary.png';
import AdviceEarImage from './assets/png/advice-ear.png';
import AlarmClockImage from './assets/png/alarm-clock.png';
import ApplesInBowlImage from './assets/png/apples-in-bowl.png';
import BelongingImage from './assets/png/belonging.png';
import BrokerCheckImage from './assets/png/broker-check.png';
import CalendarStep2 from './assets/png/calendar-step-2.png';
import Divider from './assets/png/cause-kits/divider.png';
import FillFormImage from './assets/png/cause-kits/fill-form.png';
import HoldingHandsImage from './assets/png/cause-kits/holding-hands.png';
import ShipBackImage from './assets/png/cause-kits/ship-back.png';
import SolarLightKitImage from './assets/png/cause-kits/solar-light-build.png';
import Step1 from './assets/png/cause-kits/step1.png';
import Step2 from './assets/png/cause-kits/step2.png';
import Step3 from './assets/png/cause-kits/step3.png';
import WaterFilterKitImage from './assets/png/cause-kits/water-filter-build.png';
import CelebrateStep3 from './assets/png/celebrate-step-3.png';
import CloudImage from './assets/png/cloud.png';
import CompassImage from './assets/png/compass.png';
import ConfettiImage from './assets/png/confetti.png';
import FillFormImageBlue from './assets/png/fill-form-blue.png';
import FinancialAdvisorImage from './assets/png/financial-advisor.png';
import FlowerPotsImage from './assets/png/flowerpots.png';
import FullBodyHighFiveServicingImage from './assets/png/fullbody-high-five-servicing.png';
import FullBodyHighFiveImage from './assets/png/fullbody-high-five.png';
import GenerosityImage from './assets/png/generosity.png';
import GiftImage from './assets/png/gift.png';
import HandBankingImage from './assets/png/hand-banking.png';
import HandsWithHeartImage from './assets/png/hands-with-heart.png';
import HandsImage from './assets/png/hands.png';
import HighFiveImage from './assets/png/high-five.png';
import LaptopImage from './assets/png/laptop.png';
import MembershipNetworkImage from './assets/png/membership-network.png';
import ParachuteImage from './assets/png/parachute.png';
import GenerosityPeaceImage from './assets/png/peace-generosity.png';
import PieChartInHandImage from './assets/png/pie-chart-in-hand.png';
import ProtectImage from './assets/png/protect.png';
import SafeImage from './assets/png/safe.png';
import SeedsImage from './assets/png/seeds.png';
import SickPigImage from './assets/png/sick-pig.png';
import SpilledMilkImage from './assets/png/spilled-milk.png';
import TeamWorkImage from './assets/png/team-work.png';
import AdultGenerosityShirtImage from './assets/png/thrivent-action-teams/adult-generosity-shirt.png';
import DigitalToolkitImage from './assets/png/thrivent-action-teams/kit-content-materials/digital-toolkit.png';
import LeaderGuideBookImage from './assets/png/thrivent-action-teams/kit-content-materials/leader-guide-book.png';
import NameTagsImage from './assets/png/thrivent-action-teams/kit-content-materials/name-tags.png';
import PackingStickersImage from './assets/png/thrivent-action-teams/kit-content-materials/packing-stickers.png';
import SpanishKitImage from './assets/png/thrivent-action-teams/kit-content-materials/spanish-kit.png';
import ThankYouCardsImage from './assets/png/thrivent-action-teams/kit-content-materials/thank-you-cards.png';
import ThriventActionTeamsKitImage from './assets/png/thrivent-action-teams/kit-content-materials/thrivent-action-team-kit.png';
import VolunteerPocketbooksImage from './assets/png/thrivent-action-teams/kit-content-materials/volunteer-pocketbooks.png';
import YouthGenerosityShirtImage from './assets/png/thrivent-action-teams/youth-generosity-shirt.png';
import VectorImage from './assets/png/vector.png';
import VoteImage from './assets/png/vote.png';
import WholeLifeInsuranceImage from './assets/png/whole-life-insurance.png';
import WorkFromHomeImage from './assets/png/work-from-home.png';
import WrappingGiftImage from './assets/png/wrapping-gift.png';
import Image, { ImageProps as NextImageProps } from 'next/legacy/image';

export { ReactComponent as Alert } from './assets/svg/alert.svg';
export { ReactComponent as AlertFilled } from './assets/svg/alert-filled.svg';
export { ReactComponent as AlertBlack } from './assets/svg/alert-black.svg';
// eslint-disable-next-line @cspell/spellchecker
export { ReactComponent as AnonymousAvatar } from './assets/svg/anonymous-avatar.svg';
export { ReactComponent as ArrowDown } from './assets/svg/arrow-down.svg';
export { ReactComponent as ArrowLeft } from '@thrivent/assets/svg/icons/arrow-left.svg';
export { ReactComponent as ArrowUp } from './assets/svg/arrow-up.svg';
export { ReactComponent as Calendar } from './assets/svg/calendar.svg';
export { ReactComponent as Chart } from './assets/svg/chart.svg';
export { ReactComponent as Stat } from './assets/svg/stat.svg';
export { ReactComponent as ChevronRightSmall } from '@thrivent/assets/svg/icons/chevron-right-small.svg';
export { ReactComponent as ChevronRightBlue } from './assets/svg/chevron-right-blue.svg';
export { ReactComponent as ChevronLeft } from './assets/svg/chevron-left.svg';
export { ReactComponent as ChevronDown } from './assets/svg/chevron-down.svg';
export { ReactComponent as ChevronDownWhite } from './assets/svg/chevron-down-white.svg';
export { ReactComponent as ContactSupport } from './assets/svg/contact-support.svg';
export { ReactComponent as CreditCard } from './assets/svg/credit-card.svg';
export { ReactComponent as CrossWhite } from './assets/svg/cross-white.svg';
export { ReactComponent as CrossBrown } from './assets/svg/cross-brown.svg';
export { ReactComponent as CurrentStep } from './assets/svg/current-step.svg';
export { ReactComponent as Close } from './assets/svg/close.svg';
export { ReactComponent as CloseFilled } from './assets/svg/close-circle.svg';
export { ReactComponent as CalendarBlue } from './assets/svg/calendar-blue.svg';
export { ReactComponent as CalendarGray } from './assets/svg/calendar-gray.svg';
export { ReactComponent as CalendarToday } from './assets/svg/calendar-today.svg';
export { ReactComponent as CheckCircle } from '@thrivent/assets/svg/icons/check-circle.svg';
export { ReactComponent as CheckCircleDark } from './assets/svg/check-circle-dark.svg';
export { ReactComponent as Clock } from './assets/svg/clock.svg';
export { ReactComponent as Edit } from './assets/svg/edit.svg';
export { ReactComponent as EditFilled } from './assets/svg/edit-filled.svg';
export { ReactComponent as Envelope } from './assets/svg/envelope.svg';
export { ReactComponent as FileDownload } from './assets/svg/file-download.svg';
export { ReactComponent as FPO } from './assets/svg/FPO-Image.svg';
export { ReactComponent as InfoFilled } from './assets/svg/info.svg';
export { ReactComponent as IncompleteStep } from './assets/svg/incomplete-step.svg';
export { ReactComponent as InstagramLogo } from './assets/svg/instagram-logo.svg';
export { ReactComponent as Document } from './assets/svg/document.svg';
export { ReactComponent as Error } from './assets/svg/error.svg';
export { ReactComponent as ErrorThinkingFace } from './assets/svg/error-thinking-face.svg';
export { ReactComponent as ErrorCircle } from './assets/svg/error-circle.svg';
export { ReactComponent as FacebookLogo } from './assets/svg/facebook-logo.svg';
// eslint-disable-next-line @cspell/spellchecker
export { ReactComponent as FullLogo } from './assets/svg/thrivent-logo_full.svg';
export { ReactComponent as FullTRLogoLB } from './assets/svg/FullLogo-Trademarked-LightBackground.svg';
export { ReactComponent as Group } from './assets/svg/group.svg';
export { ReactComponent as Heart } from './assets/svg/heart.svg';
export { ReactComponent as Mail } from './assets/svg/mail.svg';
export { ReactComponent as Megaphone } from './assets/svg/megaphone.svg';
export { ReactComponent as MonetizationOn } from './assets/svg/monetization-on.svg';
export { ReactComponent as NoteAdd } from './assets/svg/note-add.svg';
// eslint-disable-next-line @cspell/spellchecker
export { ReactComponent as Logo } from './assets/svg/thrivent-logo.svg';
export { ReactComponent as LightBulb } from './assets/svg/light-bulb.svg';
export { ReactComponent as LinkedInLogo } from './assets/svg/linkedIn-logo.svg';
export { ReactComponent as LinkOut } from './assets/svg/link-out.svg';
export { ReactComponent as LockClosed } from './assets/svg/lock-icon-closed.svg';
export { ReactComponent as LockOpen } from './assets/svg/lock-icon-open.svg';
export { ReactComponent as Print } from './assets/svg/print.svg';
export { ReactComponent as Room } from './assets/svg/room.svg';
export { ReactComponent as Share } from './assets/svg/share.svg';
export { ReactComponent as Search } from './assets/svg/search.svg';
export { ReactComponent as Time } from './assets/svg/time.svg';
export { ReactComponent as TimeIcon } from './assets/svg/time-icon.svg';
export { ReactComponent as Trash } from './assets/svg/trash.svg';
export { ReactComponent as TrashNoX } from './assets/svg/trash-no-x.svg';
export { ReactComponent as TwitterLogo } from './assets/svg/twitter-logo.svg';
export { ReactComponent as Menu } from '@thrivent/assets/svg/icons/menu.svg';
export { ReactComponent as MenuOpen } from './assets/svg/menu-open.svg';
export { ReactComponent as WatchCompleted } from './assets/svg/watch-completed.svg';
export { ReactComponent as WatchCancelled } from './assets/svg/watch-cancelled.svg';
export { ReactComponent as WatchPending } from './assets/svg/watch-pending.svg';
export { ReactComponent as FinancialAvatar } from './assets/svg/financial-avatar.svg';
export { ReactComponent as GuidanceTeamAvatar } from './assets/svg/guidance-team-avatar.svg';
export { ReactComponent as FilterIcon } from './assets/svg/filter.svg';
export { ReactComponent as LifeInsuranceImage } from './assets/svg/life-insurance.svg';
export { ReactComponent as Visibility } from './assets/svg/visibility.svg';
export { ReactComponent as Volunteer } from './assets/svg/volunteer.svg';
export { ReactComponent as Nominate } from './assets/svg/nominate.svg';
export { ReactComponent as Order } from './assets/svg/order.svg';
export { ReactComponent as ModeEditFill } from './assets/svg/mode-edit-fill.svg';
export { ReactComponent as Person } from './assets/svg/person.svg';
export { ReactComponent as Folder } from './assets/svg/folder.svg';

type ImageProps = Omit<NextImageProps, 'src'>;

export const FlowerPots = (props: ImageProps) => (
  <Image alt="" {...props} src={FlowerPotsImage} />
);

export const Seeds = (props: ImageProps) => (
  <Image alt="" {...props} src={SeedsImage} />
);

export const FinancialAdvisor = (props: ImageProps) => (
  <Image alt="" {...props} src={FinancialAdvisorImage} />
);

export const Generosity = (props: ImageProps) => (
  <Image alt="" {...props} src={GenerosityImage} />
);

export const Compass = (props: ImageProps) => (
  <Image alt="" {...props} src={CompassImage} />
);

export const Cloud = (props: ImageProps) => (
  <Image alt="" {...props} src={CloudImage} />
);

export const Belonging = (props: ImageProps) => (
  <Image alt="" {...props} src={BelongingImage} />
);

export const WholeLifeInsurance = (props: ImageProps) => (
  <Image alt="" {...props} src={WholeLifeInsuranceImage} />
);

export const Protect = (props: ImageProps) => (
  <Image alt="" {...props} src={ProtectImage} />
);

export const SickPig = (props: ImageProps) => (
  <Image alt="" {...props} src={SickPigImage} />
);

export const SpilledMilk = (props: ImageProps) => (
  <Image alt="" {...props} src={SpilledMilkImage} />
);

export const Hands = (props: ImageProps) => (
  <Image alt="" {...props} src={HandsImage} />
);

export const BrokerCheck = (props: ImageProps) => (
  <Image alt="FINRA BrokerCheck logo" {...props} src={BrokerCheckImage} />
);

export const Parachute = (props: ImageProps) => (
  <Image alt="" {...props} src={ParachuteImage} />
);

export const ApplesInBowl = (props: ImageProps) => (
  <Image alt="" {...props} src={ApplesInBowlImage} />
);

export const HandBanking = (props: ImageProps) => (
  <Image alt="" {...props} src={HandBankingImage} />
);

export const Safe = (props: ImageProps) => (
  <Image alt="" {...props} src={SafeImage} />
);

export const TeamWork = (props: ImageProps) => (
  <Image alt="" {...props} src={TeamWorkImage} />
);

export const Laptop = (props: ImageProps) => (
  <Image alt="" {...props} src={LaptopImage} />
);

export const GenerosityPeace = (props: ImageProps) => (
  <Image alt="" {...props} src={GenerosityPeaceImage} />
);

export const Vector = (props: ImageProps) => (
  <Image alt="" {...props} src={VectorImage} />
);

export const WorkFromHome = (props: ImageProps) => (
  <Image alt="" {...props} src={WorkFromHomeImage} />
);

export const AdviceEar = (props: ImageProps) => (
  <Image alt="" {...props} src={AdviceEarImage} />
);

export const FullBodyHighFive = (props: ImageProps) => (
  <Image alt="" {...props} src={FullBodyHighFiveImage} />
);

export const HighFive = (props: ImageProps) => (
  <Image alt="" {...props} src={HighFiveImage} />
);

export const Gift = (props: ImageProps) => (
  <Image alt="" {...props} src={GiftImage} />
);

export const WrappingGift = (props: ImageProps) => (
  <Image alt="" {...props} src={WrappingGiftImage} />
);

export const AlarmClock = (props: ImageProps) => (
  <Image alt="" {...props} src={AlarmClockImage} />
);

export const PieChartInHand = (props: ImageProps) => (
  <Image alt="" {...props} src={PieChartInHandImage} />
);

export const HandsWithHeart = (props: ImageProps) => (
  <Image alt="" {...props} src={HandsWithHeartImage} />
);

export const MembershipNetwork = (props: ImageProps) => (
  <Image alt="" {...props} src={MembershipNetworkImage} />
);

/* Thrivent Action Teams */
export const AdultGenerosityShirt = (props: ImageProps) => (
  <Image alt="" {...props} src={AdultGenerosityShirtImage} />
);

export const YouthGenerosityShirt = (props: ImageProps) => (
  <Image alt="" {...props} src={YouthGenerosityShirtImage} />
);

export const DigitalToolkit = (props: ImageProps) => (
  <Image alt="" {...props} src={DigitalToolkitImage} />
);

export const LeaderGuideBook = (props: ImageProps) => (
  <Image alt="" {...props} src={LeaderGuideBookImage} />
);

export const NameTags = (props: ImageProps) => (
  <Image alt="" {...props} src={NameTagsImage} />
);
export const PackingStickers = (props: ImageProps) => (
  <Image alt="" {...props} src={PackingStickersImage} />
);
export const SpanishKit = (props: ImageProps) => (
  <Image alt="" {...props} src={SpanishKitImage} />
);
export const ThankYouCards = (props: ImageProps) => (
  <Image alt="" {...props} src={ThankYouCardsImage} />
);
export const ThriventActionTeamsKit = (props: ImageProps) => (
  <Image alt="" {...props} src={ThriventActionTeamsKitImage} />
);
export const VolunteerPocketbooks = (props: ImageProps) => (
  <Image alt="" {...props} src={VolunteerPocketbooksImage} />
);
export const ActionTeamsAnniversary = (props: ImageProps) => (
  <Image alt="" {...props} src={ActionTeamsAnniversaryImage} />
);

// Cause Kits
export const SolarLightKit = (props: ImageProps) => (
  <Image alt="" {...props} src={SolarLightKitImage} />
);
export const WaterFilterKit = (props: ImageProps) => (
  <Image alt="" {...props} src={WaterFilterKitImage} />
);
export const ShipBack = (props: ImageProps) => (
  <Image alt="" {...props} src={ShipBackImage} />
);
export const HoldingHands = (props: ImageProps) => (
  <Image alt="" {...props} src={HoldingHandsImage} />
);
export const FillForm = (props: ImageProps) => (
  <Image alt="" {...props} src={FillFormImage} />
);
export const FillFormBlue = (props: ImageProps) => (
  <Image alt="" {...props} src={FillFormImageBlue} />
);
export const Step1Image = (props: ImageProps) => (
  <Image alt="" {...props} src={Step1} />
);
export const Step2Image = (props: ImageProps) => (
  <Image alt="" {...props} src={Step2} />
);
export const Step3Image = (props: ImageProps) => (
  <Image alt="" {...props} src={Step3} />
);
export const CalendarStep2Image = (props: ImageProps) => (
  <Image alt="" {...props} src={CalendarStep2} />
);
export const CelebrateStep3Image = (props: ImageProps) => (
  <Image alt="" {...props} src={CelebrateStep3} />
);
export const DividerImage = (props: ImageProps) => (
  <Image alt="" {...props} src={Divider} />
);

export const FullBodyHighFiveServicing = (props: ImageProps) => (
  <Image alt="" {...props} src={FullBodyHighFiveServicingImage} />
);

export const Vote = (props: ImageProps) => (
  <Image alt="" {...props} src={VoteImage} />
);

export const Confetti = (props: ImageProps) => (
  <Image alt="" {...props} src={ConfettiImage} />
);
