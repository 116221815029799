import styled from 'styled-components';

export enum DividerVariants {
  primary = 'primary',
  secondary = 'secondary',
}

type DividerProps = {
  variant?: DividerVariants;
};

/**
 * @deprecated use a different divider instead
 */
export const Divider = styled.hr<DividerProps>`
  display: flex;
  margin: 1.5rem 0;
  border-top: 2px solid
    ${(p) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      p.variant === 'primary'
        ? p.theme.colors.warning_strong
        : p.theme.colors.light_heavy};
  border-bottom: none;
`;
