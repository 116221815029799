import {
  FlagValues,
  Flags,
  SERVICING_ADOBE_ENABLED,
  SERVICING_FLAGS_PAGE_ENABLED,
  flagKeys,
} from '../flags';
import { isFlagKey } from '../types';

export const isEnvVarDefined = (flagKey: Flags) =>
  // @ts-expect-error - tight coupling to this for now which is lame
  getFlagsFromEnv()[flagKey] !== undefined;

export const getEnvVarBooleanValue = (flagKey: Flags) =>
  // @ts-expect-error - tight coupling to env flags right now
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  getFlagsFromEnv()?.[flagKey]?.toLowerCase() === 'true';

/**
 * If you want to expose a flag through .env, you have to add it to the list below
 */

const getFlagsFromEnv = () => ({
  [SERVICING_FLAGS_PAGE_ENABLED]:
    process.env['NEXT_PUBLIC_SERVICING_FLAGS_PAGE_ENABLED'],
  [SERVICING_ADOBE_ENABLED]: process.env['NEXT_PUBLIC_SERVICING_ADOBE_ENABLED'],
});

export const getAllEnvFlags = () => {
  return flagKeys.reduce<FlagValues>((flags, flagKey) => {
    if (!isFlagKey(flagKey) || !isEnvVarDefined(flagKey)) return flags;
    return { ...flags, [flagKey]: getEnvVarBooleanValue(flagKey) };
  }, {});
};
