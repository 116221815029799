import styled from 'styled-components';

type Props = {
  contentTagName: string;
};

const Container = styled.div`
  z-index: 10000;
  background: white;
  color: white;
  left: 50%;
  position: absolute;
  transform: translateY(-100%);
`;
export const SkipLink = ({ contentTagName }: Props) => {
  return (
    <Container tabIndex={-1}>
      Skip to <a href={`${contentTagName}`}>content</a> or
      <a href="#footer">footer</a>
    </Container>
  );
};
