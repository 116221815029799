import { BillProps } from './billTypes';
import {
  BillsDetailFragment,
  OutstandingClientGroupBillFragment,
  PaymentDetailFragment,
} from '@servicing/data-queries';

export const getBillStatuses = (
  bills: BillProps[] | undefined,
  singleBill: BillsDetailFragment | undefined,
  scheduledPayment: PaymentDetailFragment | undefined,
  groupBills: OutstandingClientGroupBillFragment[] | undefined
) => {
  let hasBillsPastDue = false;
  let hasBillsDue = false;
  let hasBillsScheduled = false;
  let hasBillsPending = false;
  let billsDueCount = 0;
  const billList = singleBill ? [singleBill] : bills;

  if (billList) {
    hasBillsDue = billList.some((bill) => bill.billStatus === 'DUE');
    hasBillsPastDue = billList.some((bill) => bill.billStatus === 'PAST_DUE');
    hasBillsScheduled = billList.some(
      (bill) => bill.billStatus === 'SCHEDULED'
    );
    hasBillsPending = billList.some((bill) => bill.billStatus === 'PENDING');
    billList.forEach((bill) => {
      if (bill.billStatus === 'DUE' || bill.billStatus === 'PAST_DUE') {
        billsDueCount += 1;
      }
    });
  }

  if (groupBills) {
    const dueBillsWithDuplicates = groupBills.map((bill) => ({
      billId: `${bill.groupId}-${bill.subGroupId || ''}`,
      ...bill,
    }));
    const billsWithoutScheduledBills = dueBillsWithDuplicates.filter((bill) => {
      return !bill.pendingPayment;
    });
    const uniqueDueBills = [
      ...new Map(
        billsWithoutScheduledBills.map((item) => [item['billId'], item])
      ).values(),
    ];

    billsDueCount += uniqueDueBills?.length ?? 0;
    hasBillsDue ||= !!uniqueDueBills.length;
  }

  if (scheduledPayment) {
    hasBillsScheduled ||= !!scheduledPayment;
  }

  return {
    hasBillsPastDue,
    hasBillsDue,
    hasBillsScheduled,
    hasBillsPending,
    billsDueCount,
  };
};
