import {
  LoginLogout,
  NavMenuLink,
  NavMenuTriggerWithCaret,
} from '../shared/components';
import { NavMenuItemEnum } from '../shared/enums';
import { LinkOutIcon, NavMenuContent, NavMenuItem } from '../shared/styles';
import { NavMenuProps } from '../shared/types';
import { useClientProfile, useFinancialAdvisor } from '@servicing/data-queries';
import { formatSecureEmail } from '@servicing/util-formatter';
import { PROFILE_AND_SETTINGS_ROUTE } from '@servicing/util-routes';
import { useAuth } from '@thrivent-web/authentication';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const PRIVACY_AND_SECURITY_URL =
  'https://www.thrivent.com/privacy-and-security';

const UseMenuTitleDesktop = styled.span`
  display: none;

  ${tablet} {
    display: unset;
  }
`;

const UseMenuTitleMobile = styled.span`
  ${tablet} {
    display: none;
  }
`;

const UserMenuContentSection = styled(NavMenuContent)`
  ${tablet} {
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
    gap: 1.5rem;
    min-height: unset;
    height: max-content;
    max-width: 375px;
  }
`;

const LoginLogoutSectionDivider = styled.hr`
  margin: 0;
  border-bottom: 1px solid ${(p) => p.theme.midwestColors.borderDefault};
`;

const AdvisorItem = () => {
  const { advisor } = useFinancialAdvisor();
  return (
    <NavMenuLink
      name="Secure messaging"
      url={formatSecureEmail({ email: advisor?.email })}
      target="_blank"
      icon={<LinkOutIcon />}
    />
  );
};

const UserMenuTrigger = () => {
  const { data } = useClientProfile();
  const { profile } = data?.client ?? {};

  return (
    <NavMenuTriggerWithCaret
      data-alternative-text="Settings"
      data-dd-action-name="Settings"
    >
      <UseMenuTitleDesktop
        data-alternative-text="Settings"
        data-dd-action-name="Settings"
      >
        {profile?.firstName} {profile?.legalSurname || 'My Profile'}
      </UseMenuTitleDesktop>
      <UseMenuTitleMobile
        data-alternative-text="Settings"
        data-dd-action-name="Settings"
      >
        Account settings
      </UseMenuTitleMobile>
    </NavMenuTriggerWithCaret>
  );
};

export const UserMenu = ({ currentMenuItem = '' }: NavMenuProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <NavMenuItem
      data-testid="user-menu"
      data-dd-action-name="Settings"
      value={NavMenuItemEnum.UserMenu}
      open={NavMenuItemEnum.UserMenu === currentMenuItem}
    >
      {isAuthenticated && <UserMenuTrigger />}
      <UserMenuContentSection>
        <NavMenuLink
          name={'Profile and settings'}
          url={PROFILE_AND_SETTINGS_ROUTE}
          target="_self"
        />
        <NavMenuLink
          name="Privacy and security"
          url={PRIVACY_AND_SECURITY_URL}
          target="_blank"
          icon={<LinkOutIcon />}
        />
        {isAuthenticated && <AdvisorItem />}
        <LoginLogoutSectionDivider />
        <LoginLogout />
      </UserMenuContentSection>
    </NavMenuItem>
  );
};
