import { getClientCookies } from '../cookies/flagCookies';
import { getAllEnvFlags } from '../env/flagEnv';
import { flagsPrecedenceByOrigin } from '../flagPrecedenceByOrigin/flagsPrecedenceByOrigin';
import { SERVICING_FLAGS_PAGE_ENABLED, defaultFlags } from '../flags';
import { getLaunchDarklyBrowserClient } from '../launchDarkly/getLaunchDarklyBrowserClient';
import { fetchUserContextLD } from '../launchDarkly/useUserContextLD';
import { FLAGS_QUERY } from '../types';
import { UseAllFlagsFunction, UseFlagFunction } from './types';
import { useConsumerId } from '@servicing/data-queries';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

export const useFlag: UseFlagFunction = (key) => {
  const data = useAllFlags();
  return data?.currentValues[key] ?? false;
};

export const flagsQueryOptions = (
  queryClient: QueryClient,
  consumerId?: string
) => ({
  queryKey: [FLAGS_QUERY],
  queryFn: async () => {
    if (!consumerId) {
      return { currentValues: defaultFlags, origins: null };
    }

    const envFlags = getAllEnvFlags();
    const launchDarklyFlags = await getLaunchDarklyFlagsBrowser(
      queryClient,
      consumerId
    );

    const ldFlagPageEnabled = launchDarklyFlags[SERVICING_FLAGS_PAGE_ENABLED];
    // Fetch cookie flags if the flags page is enabled in LD or is the env variable is set and LD isn't explicitly false
    // We don't want to fetch client cookies when the flag page is disabled, as it can result fetch error logs https://github.com/thrivent/thrivent-web/pull/6988
    const cookieFlags =
      ldFlagPageEnabled ||
      (envFlags[SERVICING_FLAGS_PAGE_ENABLED] && ldFlagPageEnabled !== false)
        ? await getClientCookies()
        : {};

    return flagsPrecedenceByOrigin(envFlags, cookieFlags, launchDarklyFlags);
  },
  initialData: {
    currentValues: defaultFlags,
    origins: null,
  },
  // This will add extra calls - Jason is refactoring it
  staleTime: 0,
});

export const useAllFlags: UseAllFlagsFunction = () => {
  const consumerId = useConsumerId();
  const queryClient = useQueryClient();
  return useQuery(flagsQueryOptions(queryClient, consumerId)).data;
};

export const getLaunchDarklyFlagsBrowser = async (
  queryClient: QueryClient,
  consumerId: string
) => {
  const userContext = await fetchUserContextLD(queryClient, consumerId);
  const client = await getLaunchDarklyBrowserClient(userContext);

  if (!client) {
    return {};
  }

  return client.allFlags();
};
