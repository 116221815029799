import { NavColumn, NavColumnHeader } from './shared/styles';
import { BodyText } from '@thrivent-web/ui/atoms';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

const AddressesDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const AddressNavColumn = styled(NavColumn)`
  ${tablet} {
    grid-column-start: 3;
  }
`;

export const OperationsCenter = () => (
  <AddressNavColumn>
    <AddressesDiv>
      <NavColumnHeader>Operations Center</NavColumnHeader>
      <div>
        <BodyText.RegSm>4321 N. Ballard Rd.</BodyText.RegSm>
        <BodyText.RegSm>Appleton, WI</BodyText.RegSm>
        <BodyText.RegSm>54919-0001 USA</BodyText.RegSm>
      </div>
    </AddressesDiv>
  </AddressNavColumn>
);
