import { MDSLink, MDSText } from '@midwest/web/base';
import { colors } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export type NotificationProps = {
  $type?: string;
  $sticky?: boolean;
  $subtle?: boolean;
  $notificationType?: string;
};

export const SystemAlertText = styled(MDSText.Subtitle)<NotificationProps>`
  color: ${(p) =>
    p.$type === 'informative' || p.$type === 'negative'
      ? colors.backgroundPrimary
      : colors.neutralDarkStrong};
`;

export const InlineMessageText = styled(
  MDSText.BodyMediumSm
)<NotificationProps>`
  margin-top: 1px;
  color: ${(p) =>
    p.$subtle
      ? colors.neutralDarkStrong
      : p.$type === 'informative' || p.$type === 'negative'
      ? colors.backgroundPrimary
      : colors.neutralDarkStrong};
`;

export const SystemAlertInlineLinkText = styled(
  MDSText.Subtitle
)<NotificationProps>`
  color: ${(p) =>
    p.$type === 'informative' || p.$type === 'negative'
      ? `${colors.functionalBgPrimary} !important`
      : `${colors.neutralDarkStrong} !important`};

  text-decoration: underline;
`;

export const InlineMessageInlineLinkText = styled(
  MDSText.BodyMediumSm
)<NotificationProps>`
  color: ${(p) =>
    p.$subtle
      ? colors.neutralDarkStrong
      : p.$type === 'informative' || p.$type === 'negative'
      ? `${colors.functionalBgPrimary} !important`
      : `${colors.neutralDarkStrong} !important`};

  text-decoration: underline;
`;

export const StandaloneLink = styled(MDSLink)<NotificationProps>`
  color: ${(p) =>
    p.$type === 'informative' || p.$type === 'negative'
      ? `${colors.functionalBgPrimary} !important`
      : `${colors.neutralDarkStrong} !important`};
`;

export const SystemAlertStandaloneLinkText = styled(
  MDSText.Subtitle
)<NotificationProps>`
  color: ${(p) =>
    p.$type === 'informative' || p.$type === 'negative'
      ? colors.functionalBgPrimary
      : colors.neutralDarkStrong};
  text-decoration: underline;
`;

export const InlineMessageStandaloneLinkText = styled(
  MDSText.BodyMediumSm
)<NotificationProps>`
  color: ${(p) =>
    p.$subtle
      ? colors.neutralDarkStrong
      : p.$type === 'informative' || p.$type === 'negative'
      ? colors.functionalBgPrimary
      : colors.neutralDarkStrong};
  text-decoration: underline;
`;
