import {
  useAllBills,
  useOutstandingGroupBillsQuery,
} from '@servicing/data-queries';
import { BillStatusIndicatorTag, ProductTypes } from '@servicing/molecules';
import {
  SERVICING_DASHBOARD_BILL_INDICATORS_ENABLED,
  SERVICING_VIEW_GROUP_BILLS_ENABLED,
  useFlag,
} from '@thrivent-web/servicing/feature-management';

export const AllBillsStatusIndicator = () => {
  const isBillIndicatorEnabled = useFlag(
    SERVICING_DASHBOARD_BILL_INDICATORS_ENABLED
  );

  const viewGroupBillsEnabled = useFlag(SERVICING_VIEW_GROUP_BILLS_ENABLED);
  const { data: billsData } = useAllBills();
  const { data: groupBillsData } = useOutstandingGroupBillsQuery(undefined, {
    enabled: viewGroupBillsEnabled,
  });

  if (!isBillIndicatorEnabled) return null;
  return (
    <BillStatusIndicatorTag
      bills={billsData?.billsDetails?.bills ?? []}
      groupBills={
        groupBillsData?.client?.groupBillsDetail?.outstandingGroupBills || []
      }
      productType={ProductTypes.allBills}
    />
  );
};
