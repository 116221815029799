import { ButtonVariationProps } from '../types';
import { backgroundColors } from './backgroundColors';
import {
  border,
  colors,
  textInterfaceSemiDefault,
  textInterfaceSemiSm,
} from '@thrivent/midwest-shared';
import { css } from 'styled-components';

export const primaryStrongStyles = css<ButtonVariationProps>`
  background: ${backgroundColors.primaryStrongBg};
  color: ${colors.componentButtonColorTextStrongPrimary};
  font-weight: ${textInterfaceSemiDefault.weight};
  line-height: ${textInterfaceSemiDefault.lineHeight}px;
  font-size: ${textInterfaceSemiDefault.rem}rem;
  ${(p) =>
    p.size === 'small' &&
    css`
      line-height: ${textInterfaceSemiSm.lineHeight}px;
      font-size: ${textInterfaceSemiSm.rem}rem;
      font-weight: ${textInterfaceSemiSm.weight};
    `};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background-color: ${colors.componentButtonColorBackgroundStrongPrimaryHover};
      }
      &:hover {
        background: ${colors.componentButtonColorBackgroundStrongPrimaryHover};
      }
      &:active {
        background: ${colors.componentButtonColorBackgroundStrongPrimaryPressed};
      }
    `}
`;

export const primarySubtleStyles = css<ButtonVariationProps>`
  background: ${backgroundColors.primarySubtleBg};
  outline: ${border.widthDefault} solid
    ${colors.componentButtonColorBorderSubtlePrimaryDefault};
  color: ${colors.componentButtonColorTextSubtlePrimary};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background-color: ${colors.componentButtonColorBackgroundSubtlePrimaryHover};
      }
      &:hover {
        background-color: ${colors.componentButtonColorBackgroundSubtlePrimaryHover};
        outline-color: ${colors.componentButtonColorBorderSubtlePrimaryHover};
      }
      &:active {
        background-color: ${colors.componentButtonColorBackgroundSubtlePrimaryPressed};
        outline-color: ${colors.componentButtonColorBorderSubtlePrimaryPressed};
      }
    `}
`;

export const primarySecondaryGhostStyles = css<ButtonVariationProps>`
  background: ${backgroundColors.primaryGhostBg};
  outline: none;
  color: ${colors.componentButtonColorTextGhostPrimary};
  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        background-color: ${colors.componentButtonColorBackgroundSubtlePrimaryHover};
      }
      &:active {
        background-color: ${colors.componentButtonColorBackgroundSubtlePrimaryPressed};
      }
    `}
`;
