/**
 * Do not edit directly
 * Generated on Wed, 07 Aug 2024 21:09:19 GMT
 */

export const componentButtonRadiusDefault = 6;
export const componentButtonColorBackgroundStrongPrimaryDefault = '#19398C';
export const componentButtonColorBackgroundStrongPrimaryHover = '#2453CC';
export const componentButtonColorBackgroundStrongPrimaryPressed = '#0B1C45';
export const componentButtonColorBackgroundStrongSecondaryDefault = '#F1EADC';
export const componentButtonColorBackgroundStrongSecondaryHover = '#F8F5EF';
export const componentButtonColorBackgroundStrongSecondaryPressed = '#E5D8C2';
export const componentButtonColorBackgroundStrongDestructiveDefault = '#B50737';
export const componentButtonColorBackgroundStrongDestructiveHover = '#E30045';
export const componentButtonColorBackgroundStrongDestructivePressed = '#7D0B27';
export const componentButtonColorBackgroundSubtleDefault = '#FFFFFF';
export const componentButtonColorBackgroundSubtlePrimaryHover = '#F4F7FF';
export const componentButtonColorBackgroundSubtlePrimaryPressed = '#D1DEFF';
export const componentButtonColorBackgroundSubtleSecondaryHover = '#F8F5EF';
export const componentButtonColorBackgroundSubtleSecondaryPressed = '#E5D8C2';
export const componentButtonColorBackgroundSubtleDestructiveHover = '#FEF4F5';
export const componentButtonColorBackgroundSubtleDestructivePressed = '#FEE5E8';
export const componentButtonColorBorderSubtlePrimaryDefault = '#7095FF';
export const componentButtonColorBorderSubtlePrimaryHover = '#2E66FF';
export const componentButtonColorBorderSubtlePrimaryPressed = '#2A60F1';
export const componentButtonColorBorderSubtleSecondaryDefault = '#D7C49F';
export const componentButtonColorBorderSubtleSecondaryHover = '#BCA06F';
export const componentButtonColorBorderSubtleSecondaryPressed = '#9F895E';
export const componentButtonColorBorderSubtleDestructiveDefault = '#F9BAC7';
export const componentButtonColorBorderSubtleDestructiveHover = '#EF627E';
export const componentButtonColorBorderSubtleDestructivePressed = '#E30045';
export const componentButtonColorTextSubtlePrimary = '#141414';
export const componentButtonColorTextSubtleSecondary = '#141414';
export const componentButtonColorTextSubtleDestructive = '#B50737';
export const componentButtonColorTextGhostPrimary = '#19398C';
export const componentButtonColorTextGhostDestructive = '#B50737';
export const componentButtonColorTextStrongPrimary = '#FFFFFF';
export const componentButtonColorTextStrongSecondary = '#141414';
export const componentButtonColorTextStrongDestructive = '#FFFFFF';
export const componentButtonPaddingDefault = 16;
export const componentContainerBorderWidthDefault = 1;
export const componentContainerBorderWidthEmphasis = 2;
export const componentContainerBorderWidthFocus = 4;
export const componentContainerColorBackgroundDefault = '#FFFFFF';
export const componentContainerColorBackgroundHover = '#E9F0FF';
export const componentContainerColorBackgroundInfoEmphasis = '#2E66FF';
export const componentContainerColorBackgroundInfoSubtle = '#E9F0FF';
export const componentContainerColorBackgroundPositiveEmphasis = '#21DBB0';
export const componentContainerColorBackgroundPositiveSubtle = '#EDFAF7';
export const componentContainerColorBackgroundNegativeEmphasis = '#B50737';
export const componentContainerColorBackgroundNegativeSubtle = '#FDEAED';
export const componentContainerColorBackgroundWarningEmphasis = '#FFC533';
export const componentContainerColorBackgroundWarningSubtle = '#FFFAEC';
export const componentContainerColorTextHeadingSubtle = '#141414';
export const componentContainerColorTextHeadingInfoEmphasis = '#FFFFFF';
export const componentContainerColorTextHeadingNegativeEmphasis = '#FFFFFF';
export const componentContainerColorTextHeadingPositiveEmphasis = '#141414';
export const componentContainerColorTextHeadingWarningEmphasis = '#141414';
export const componentContainerColorTextParagraphSubtle = '#363636';
export const componentContainerColorTextParagraphInfoEmphasis = '#FFFFFF';
export const componentContainerColorTextParagraphNegativeEmphasis = '#FFFFFF';
export const componentContainerColorTextParagraphPositiveEmphasis = '#141414';
export const componentContainerColorTextParagraphWarningEmphasis = '#141414';
export const componentContainerColorBorderDefault = '#C7C7CA';
export const componentContainerColorBorderInfo = '#BFCFFF';
export const componentContainerColorBorderPositive = '#21DBB0';
export const componentContainerColorBorderNegative = '#F9BAC7';
export const componentContainerColorBorderWarning = '#FFC533';
export const componentContainerOpacityOverlay = 0.5;
export const componentContainerPaddingSm = 8;
export const componentContainerPaddingMd = 16;
export const componentContainerPaddingLg = 24;
export const componentContainerRadiusDefault = 6;
export const componentFormRadiusDefault = 4;
export const componentFormColorTextLabelDefault = '#363636';
export const componentFormColorTextLabelHover = '#141414';
export const componentFormColorTextLabelActive = '#2E66FF';
export const componentFormColorTextInvalid = '#B50737';
export const componentFormColorTextDisabled = '#A8ABAB';
export const componentFormColorTextPlaceholder = '#686B6B';
export const componentFormColorBackgroundDefault = '#FFFFFF';
export const componentFormColorBackgroundDisabled = '#FAFAFA';
export const componentFormColorBorderDefault = '#C7C7CA';
export const componentFormColorBorderDisabled = '#F0F0F0';
export const componentFormColorBorderFocus = '#19398C';
export const componentFormColorBorderActive = '#2E66FF';
export const componentFormColorBorderHover = '#000000';
export const componentLinkDefault = '#2453CC';
export const componentLinkHover = '#2E66FF';
export const componentLinkVisited = '#5744A6';
export const componentTableBackgroundDefault = '#FFFFFF';
export const componentTableBackgroundHover = '#F4F7FF';
export const componentTableBackgroundChecked = '#F4F7FF';
export const componentTableBackgroundCheckedHover = '#E7EDFF';
export const componentTableBackgroundDefault2 = '#FFFFFF';
export const componentTableBackgroundHover2 = '#FAFAFA';
export const componentTableBackgroundChecked2 = '#FAFAFA';
export const componentTableBackgroundCheckedHover2 = '#F0F0F0';
export const baseColorWhite = '#FFFFFF';
export const baseColorBlack = '#000000';
export const baseColorBlue1 = '#FCFCFF';
export const baseColorBlue2 = '#F4F7FF';
export const baseColorBlue3 = '#E9F0FF';
export const baseColorBlue4 = '#E7EDFF';
export const baseColorBlue5 = '#D1DEFF';
export const baseColorBlue6 = '#BFCFFF';
export const baseColorBlue7 = '#A4BBFF';
export const baseColorBlue8 = '#7095FF';
export const baseColorBlue9 = '#2E66FF';
export const baseColorBlue10 = '#2A60F1';
export const baseColorBlue11 = '#2453CC';
export const baseColorBlue12 = '#1C43A7';
export const baseColorBlue13 = '#19398C';
export const baseColorBlue14 = '#152967';
export const baseColorBlue15 = '#0B1C45';
export const baseColorRed1 = '#FFFCFC';
export const baseColorRed2 = '#FEF4F5';
export const baseColorRed3 = '#FDEAED';
export const baseColorRed4 = '#FEE5E8';
export const baseColorRed5 = '#FCCFD6';
export const baseColorRed6 = '#F9BAC7';
export const baseColorRed7 = '#F79DAD';
export const baseColorRed8 = '#EF627E';
export const baseColorRed9 = '#E30045';
export const baseColorRed10 = '#D60041';
export const baseColorRed11 = '#B50737';
export const baseColorRed12 = '#94062E';
export const baseColorRed13 = '#7D0B27';
export const baseColorRed14 = '#5C081D';
export const baseColorRed15 = '#3E0814';
export const baseColorGreen1 = '#FCFFFF';
export const baseColorGreen2 = '#F6FDFA';
export const baseColorGreen3 = '#EDFAF7';
export const baseColorGreen4 = '#EAFAF5';
export const baseColorGreen5 = '#D2F6EA';
export const baseColorGreen6 = '#C9F4E8';
export const baseColorGreen7 = '#ACEFDA';
export const baseColorGreen8 = '#7DE5C8';
export const baseColorGreen9 = '#21DBB0';
export const baseColorGreen10 = '#1ACFA6';
export const baseColorGreen11 = '#20B08D';
export const baseColorGreen12 = '#168E71';
export const baseColorGreen13 = '#137A5F';
export const baseColorGreen14 = '#115846';
export const baseColorGreen15 = '#0C3128';
export const baseColorGray1 = '#FFFFFF';
export const baseColorGray2 = '#FAFAFA';
export const baseColorGray3 = '#F2F2F2';
export const baseColorGray4 = '#F0F0F0';
export const baseColorGray5 = '#E0E0E0';
export const baseColorGray6 = '#D9D9D9';
export const baseColorGray7 = '#C7C7CA';
export const baseColorGray8 = '#A8ABAB';
export const baseColorGray9 = '#848587';
export const baseColorGray10 = '#7D7D80';
export const baseColorGray11 = '#686B6B';
export const baseColorGray12 = '#575757';
export const baseColorGray13 = '#4A4A4A';
export const baseColorGray14 = '#363636';
export const baseColorGray15 = '#141414';
export const baseColorYellow1 = '#FFFFFC';
export const baseColorYellow2 = '#FFFCF7';
export const baseColorYellow3 = '#FFFAEC';
export const baseColorYellow4 = '#FFF7E9';
export const baseColorYellow5 = '#FFF2D6';
export const baseColorYellow6 = '#FFECC5';
export const baseColorYellow7 = '#FEE5AC';
export const baseColorYellow8 = '#FFD77A';
export const baseColorYellow9 = '#FFC533';
export const baseColorYellow10 = '#F1B832';
export const baseColorYellow11 = '#D79D14';
export const baseColorYellow12 = '#D29810';
export const baseColorYellow13 = '#B17D02';
export const baseColorYellow14 = '#674E16';
export const baseColorYellow15 = '#453511';
export const baseColorGold1 = '#FFFFFC';
export const baseColorGold2 = '#FDFAF7';
export const baseColorGold3 = '#FAF7F2';
export const baseColorGold4 = '#F8F5EF';
export const baseColorGold5 = '#F1EADC';
export const baseColorGold6 = '#EFE5D4';
export const baseColorGold7 = '#E5D8C2';
export const baseColorGold8 = '#D7C49F';
export const baseColorGold9 = '#C7AB75';
export const baseColorGold10 = '#BCA06F';
export const baseColorGold11 = '#9F895E';
export const baseColorGold12 = '#836F4D';
export const baseColorGold13 = '#6E5E41';
export const baseColorGold14 = '#4E452E';
export const baseColorGold15 = '#372E1F';
export const baseColorPurple1 = '#FCFCFF';
export const baseColorPurple2 = '#F7F7FD';
export const baseColorPurple3 = '#F0EDFB';
export const baseColorPurple4 = '#EEEAF9';
export const baseColorPurple5 = '#DFD9F4';
export const baseColorPurple6 = '#D2CCF0';
export const baseColorPurple7 = '#BFB2EA';
export const baseColorPurple8 = '#9C8CDE';
export const baseColorPurple9 = '#6E56CF';
export const baseColorPurple10 = '#6751C3';
export const baseColorPurple11 = '#5744A6';
export const baseColorPurple12 = '#473787';
export const baseColorPurple13 = '#3D3072';
export const baseColorPurple14 = '#2D2352';
export const baseColorPurple15 = '#1D1738';
export const baseColorMcOrange1 = '#FFFCFC';
export const baseColorMcOrange2 = '#FEF7F4';
export const baseColorMcOrange3 = '#FFEFEA';
export const baseColorMcOrange4 = '#FDEAE7';
export const baseColorMcOrange5 = '#FDDAD2';
export const baseColorMcOrange6 = '#FCCDC0';
export const baseColorMcOrange7 = '#FAB5A2';
export const baseColorMcOrange8 = '#F7896F';
export const baseColorMcOrange9 = '#F05429';
export const baseColorMcOrange10 = '#E24F26';
export const baseColorMcOrange11 = '#C14421';
export const baseColorMcOrange12 = '#9D361D';
export const baseColorMcOrange13 = '#843016';
export const baseColorMcOrange14 = '#612212';
export const baseColorMcOrange15 = '#42170C';
export const baseColorMcBlue1 = '#FCFFFF';
export const baseColorMcBlue2 = '#F7FAFF';
export const baseColorMcBlue3 = '#EBF5FF';
export const baseColorMcBlue4 = '#E9F3FF';
export const baseColorMcBlue5 = '#D4EBFE';
export const baseColorMcBlue6 = '#C4E1FF';
export const baseColorMcBlue7 = '#AAD5FF';
export const baseColorMcBlue8 = '#77BFFF';
export const baseColorMcBlue9 = '#2EA1FF';
export const baseColorMcBlue10 = '#2799EF';
export const baseColorMcBlue11 = '#2681CD';
export const baseColorMcBlue12 = '#2067A5';
export const baseColorMcBlue13 = '#1D588B';
export const baseColorMcBlue14 = '#154166';
export const baseColorMcBlue15 = '#0F2C44';
export const baseBorderRadiusSm = 4;
export const baseBorderRadiusMd = 6;
export const baseBorderRadiusLg = 8;
export const baseBorderRadiusFull = 1000;
export const baseBorderWidthSm = 1;
export const baseBorderWidthMd = 2;
export const baseBorderWidthLg = 4;
export const baseRemXs01 = 0.125;
export const baseRemXs02 = 0.25;
export const baseRemXs03 = 0.375;
export const baseRemXs04 = 0.42500001192092896;
export const baseRemS01 = 0.5;
export const baseRemS02 = 0.625;
export const baseRemS03 = 0.75;
export const baseRemS04 = 0.875;
export const baseRemM01 = 1;
export const baseRemM02 = 1.125;
export const baseRemM03 = 1.25;
export const baseRemM04 = 1.375;
export const baseRemL01 = 1.5;
export const baseRemL02 = 1.75;
export const baseRemL03 = 2;
export const baseRemL04 = 2.25;
export const baseRemXl01 = 2.625;
export const baseRemXl02 = 3;
export const baseRemXl03 = 3.375;
export const baseRemXl04 = 3.75;
export const baseRemXxl01 = 4.25;
export const baseRemXxl02 = 4.75;
export const baseRemXxl03 = 5.25;
export const baseRemXxl04 = 6;
export const baseFontWeightHeavy = 700;
export const baseFontWeightSemi = 600;
export const baseFontWeightMedium = 500;
export const baseFontWeightRegular = 400;
export const baseSpacingXxxsm = 2;
export const baseSpacingXxsm = 4;
export const baseSpacingXsm = 8;
export const baseSpacingSm = 12;
export const baseSpacingMd = 16;
export const baseSpacingLg = 20;
export const baseSpacingXlg = 24;
export const baseSpacingXxlg = 32;
export const baseSpacingXxxlg = 40;
export const baseOpacityOverlay = 0.5;
export const baseBaseSize = 16;
export const baseLineHeightXxxxs = 12;
export const baseLineHeightXxxs = 14;
export const baseLineHeightXxs = 16;
export const baseLineHeightXs = 18;
export const baseLineHeightS = 20;
export const baseLineHeightM = 22;
export const baseLineHeightBodyMini = 14;
export const baseLineHeightBodyDefault = 16;
export const baseLineHeightL = 24;
export const baseLineHeightXl = 28;
export const baseLineHeightXxl = 32;
export const baseLineHeightXxxl = 36;
export const baseLineHeightXxxxl = 42;
export const baseLineHeightXxxxxl = 48;
export const baseLineHeightXxxxxxl = 52;
export const baseLineHeightXxxxxxxl = 72;
export const baseFontFamilyPrimary = "'Inter', sans-serif";
export const baseFontFamilySecondary = "'Libre Baskerville', serif";
export const baseFontFamilyLofi = "'Comic Neue', cursive";
export const baseFontFamilyLofiAlt = "'Redacted Script', cursive";
export const baseFontSize1 = 12;
export const baseFontSize2 = 14;
export const baseFontSize3 = 16;
export const baseFontSize4 = 18;
export const baseFontSize5 = 20;
export const baseFontSize6 = 22;
export const baseFontSize7 = 24;
export const baseFontSize8 = 28;
export const baseFontSize9 = 32;
export const baseFontSize10 = 36;
export const baseFontSize11 = 40;
export const baseFontSize12 = 48;
export const baseFontSize13 = 54;
export const baseFontSize14 = 60;
export const baseFontSize15 = 68;
export const baseFontSize16 = 76;
export const baseFontSize17 = 84;
export const baseFontSize18 = 96;
export const baseFontSizeMini = 10;
export const semanticColorShadowWeak = '#D9D9D9';
export const semanticColorShadowMedium = '#A8ABAB';
export const semanticColorShadowStrong = '#7D7D80';
export const semanticColorIdentityBrandPrimaryFaint = '#E9F0FF';
export const semanticColorIdentityBrandPrimaryWeak = '#BFCFFF';
export const semanticColorIdentityBrandPrimaryMedium = '#2E66FF';
export const semanticColorIdentityBrandPrimaryStrong = '#2453CC';
export const semanticColorIdentityBrandPrimaryHeavy = '#19398C';
export const semanticColorIdentityBrandPrimaryDense = '#0B1C45';
export const semanticColorIdentityBrandSecondary = '#21DBB0';
export const semanticColorIdentityBrandTertiary = '#C7AB75';
export const semanticColorIdentityBrandTertiaryStrong = '#9F895E';
export const semanticColorIdentityBrandTertiaryHeavy = '#6E5E41';
export const semanticColorTextPrimary = '#141414';
export const semanticColorTextSecondary = '#363636';
export const semanticColorTextTertiary = '#575757';
export const semanticColorTextDisabled = '#A8ABAB';
export const semanticColorTextLink = '#2453CC';
export const semanticColorTextPrimaryDarkBg = '#FFFFFF';
export const semanticColorTextSecondaryDarkBg = '#F0F0F0';
export const semanticColorTextTertiaryDarkBg = '#A8ABAB';
export const semanticColorTextLinkDarkBg = '#A4BBFF';
export const semanticColorBackgroundPrimary = '#FFFFFF';
export const semanticColorBackgroundSecondary = '#F2F2F2';
export const semanticColorBackgroundDisabled = '#FAFAFA';
export const semanticColorBorderDefault = '#C7C7CA';
export const semanticColorBorderDisabled = '#F0F0F0';
export const semanticColorBorderFocus = '#19398C';
export const semanticColorBorderFocusDarkbg = '#FFC533';
export const semanticColorBorderHover = '#363636';
export const semanticColorSentimentNegativeFaint = '#FDEAED';
export const semanticColorSentimentNegativeWeak = '#F9BAC7';
export const semanticColorSentimentNegativeMedium = '#E30045';
export const semanticColorSentimentNegativeStrong = '#B50737';
export const semanticColorSentimentNegativeHeavy = '#7D0B27';
export const semanticColorSentimentNegativeDense = '#3E0814';
export const semanticColorSentimentPositiveFaint = '#EDFAF7';
export const semanticColorSentimentPositiveWeak = '#C9F4E8';
export const semanticColorSentimentPositiveMedium = '#21DBB0';
export const semanticColorSentimentPositiveStrong = '#20B08D';
export const semanticColorSentimentPositiveHeavy = '#137A5F';
export const semanticColorSentimentPositiveDense = '#0C3128';
export const semanticColorSentimentWarningFaint = '#FFFAEC';
export const semanticColorSentimentWarningWeak = '#FFECC5';
export const semanticColorSentimentWarningMedium = '#FFC533';
export const semanticColorSentimentWarningStrong = '#D79D14';
export const semanticColorSentimentWarningHeavy = '#D29810';
export const semanticColorSentimentWarningDense = '#453511';
export const semanticColorSentimentInfoFaint = '#E9F0FF';
export const semanticColorSentimentInfoWeak = '#BFCFFF';
export const semanticColorSentimentInfoMedium = '#2E66FF';
export const semanticColorSentimentInfoStrong = '#2453CC';
export const semanticColorSentimentInfoHeavy = '#19398C';
export const semanticColorSentimentInfoDense = '#0B1C45';
export const semanticColorNeutralLightWeak = '#FFFFFF';
export const semanticColorNeutralLightMedium = '#FAFAFA';
export const semanticColorNeutralLightStrong = '#E0E0E0';
export const semanticColorNeutralLightHeavy = '#C7C7CA';
export const semanticColorNeutralDarkWeak = '#848587';
export const semanticColorNeutralDarkMedium = '#575757';
export const semanticColorNeutralDarkStrong = '#363636';
export const semanticColorNeutralDarkHeavy = '#141414';
export const semanticColorDisabledOpacity = '30';
