import { AdobeDataLayer, getAdobeDataLayerData } from './adobeConfig';
import { useConsumerId, useMemberId } from '@servicing/data-queries';
import { findAdobeWithRetry } from '@thrivent-web/analytics-adobe';
import { useAllFlags } from '@thrivent-web/servicing/feature-management';
import { useEffect } from 'react';

interface AdobeWindow extends Window {
  adobeDataLayer?: {
    push: (data: AdobeDataLayer) => void;
  };
  _satellite?: {
    getVisitorId?: () => {
      getMarketingCloudVisitorID: () => void;
    };
  };
}

export function GlobalAnalytics() {
  const allFlags = useAllFlags();
  const memberId = useMemberId();
  const consumerId = useConsumerId();

  useEffect(() => {
    const values = allFlags?.currentValues ?? {};
    // @ts-expect-error - we don't need to double-check the keys
    const enabledFlags = [];
    for (const key in values) {
      // @ts-expect-error - we don't need to double-check the keys
      if (values[key]) {
        enabledFlags.push(key);
      }
    }
    if (process.env['NEXT_PUBLIC_DATADOG_RUM'] === 'true') {
      import('@datadog/browser-rum').then(({ datadogRum }) => {
        // @ts-expect-error - we don't need to double-check the keys
        datadogRum.setGlobalContextProperty('enabledFlags', enabledFlags);
        datadogRum.setGlobalContextProperty('consumerId', consumerId);
      });
    }
  }, [allFlags, consumerId]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const adobeWindow = window as AdobeWindow;
      const ecId =
        adobeWindow._satellite?.getVisitorId?.().getMarketingCloudVisitorID() ??
        '';

      const adobeData = getAdobeDataLayerData({
        consumerId,
        memberId,
        ecId,
      });

      findAdobeWithRetry(5000, () => {
        adobeWindow?.adobeDataLayer?.push(adobeData);
      });
    }
  }, [consumerId, memberId]);

  return null;
}
