import { NavColumnWithLinks } from './shared/NavColumnWithLinks';
import { TargetProps } from './shared/styles';

const FORMS_URL = 'https://www.thrivent.com/forms';
const GOVERNANCE_URL = 'https://www.thrivent.com/governance';
const PRIVACY_AND_SECURITY_URL =
  'https://www.thrivent.com/privacy-and-security';
const TERMS_OF_USE_URL =
  'https://www.thrivent.com/privacy-and-security/terms-of-use';
const FINANCIAL_INFORMATION_URL =
  'https://www.thrivent.com/governance#financial-info';

export const legalLinks: { name: string; url: string; target: TargetProps }[] =
  [
    { name: 'Forms', url: FORMS_URL, target: '_blank' },
    { name: 'Governance', url: GOVERNANCE_URL, target: '_blank' },
    {
      name: 'Financial information',
      url: FINANCIAL_INFORMATION_URL,
      target: '_blank',
    },
    { name: 'Terms of use', url: TERMS_OF_USE_URL, target: '_blank' },
    {
      name: 'PRIVACY AND SECURITY',
      url: PRIVACY_AND_SECURITY_URL,
      target: '_blank',
    },
  ];

export const Legal = () => (
  <NavColumnWithLinks columnHeader="Legal" links={legalLinks} />
);
