import { decodeAccessToken } from '@servicing/data-graphql';
import { useQuery } from '@tanstack/react-query';
import { isContextGetAccessToken } from '@thrivent-web/logging-utils';

export const useConsumerId = () => {
  const { data: consumerId } = useQuery({
    queryKey: ['consumer-id'],
    staleTime: 0,
    gcTime: 0,

    queryFn: async (context) => {
      if (!context || !isContextGetAccessToken(context?.meta)) {
        throw new Error('useConsumerId - Missing getAccessToken');
      }

      const accessToken = await context.meta.getAccessToken();

      if (accessToken) {
        const { consumerId } = decodeAccessToken(accessToken);
        return consumerId;
      }
      return '';
    },
  });

  return consumerId ?? '';
};
