import { useMDSAlertModal } from '@midwest/web/modal';
import {
  idleUserTimeoutDefaults,
  useIdleUserTimeout,
} from '@thrivent-web/authentication';

export interface MDSIdleUserTimeoutProps {
  /**
   * The duration of idle time in milliseconds before triggering the timeout.
   * This value cannot exceed 900_000 (15 minutes).
   *
   * @default 300_000 (5 minutes)
   * @maximum 900_000 (15 minutes)
   */
  idleTimeoutMs?: number;

  /**
   * The duration of time in milliseconds before prompting the user before the idle timeout.
   * This value must be less than idleTimeoutMs.
   *
   * @default 60_000 (1 minute)
   */
  promptBeforeIdleTimeoutMs?: number;

  /**
   * The interval duration in milliseconds for checking the idle timeout.
   *
   * @default 10_000 (10 seconds)
   */
  idleTimeoutCheckIntervalMs?: number;

  /**
   * Determines if the idle timeout functionality is disabled.
   */
  disabled: boolean;

  /**
   * Throttle in milliseconds for activity events.
   *
   * @default 2_000 (2 seconds)
   */
  eventsThrottleMs?: number;

  /**
   * Determines if the user should be redirected on logout.
   */
  redirectOnLogout: boolean;

  /**
   * The URL to redirect to after logout.
   *
   * @default OAuthClientSettings.post_logout_redirect_uri
   */
  logoutRedirectUrl?: string;
}

export const MDSIdleUserTimeout = ({
  idleTimeoutMs = idleUserTimeoutDefaults.idleTimeoutMs,
  promptBeforeIdleTimeoutMs = idleUserTimeoutDefaults.promptBeforeIdleTimeoutMs,
  idleTimeoutCheckIntervalMs = idleUserTimeoutDefaults.idleTimeoutCheckIntervalMs,
  disabled,
  eventsThrottleMs = idleUserTimeoutDefaults.eventsThrottleMs,
  redirectOnLogout,
  logoutRedirectUrl,
}: MDSIdleUserTimeoutProps) => {
  const modal = useMDSAlertModal({
    title: 'Still here?',
    content:
      'You’ve been inactive. For account security, we’ll log you out soon.',
    type: 'confirm',
    primaryButtonText: 'Stay logged in',
    secondaryButtonText: 'Log out',
    onClickPrimary: () => {
      activate();
    },
    onClickSecondary: () => {
      logout();
    },
  });

  const handlePrompt = () => {
    modal.showModal();
  };

  const handlePromptCancelled = () => {
    modal.hideModal();
  };

  const { activate, logout } = useIdleUserTimeout({
    idleTimeoutMs,
    promptBeforeIdleTimeoutMs,
    idleTimeoutCheckIntervalMs,
    disabled,
    eventsThrottleMs,
    redirectOnLogout,
    logoutRedirectUrl,
    onPrompt: handlePrompt,
    onPromptCancelled: handlePromptCancelled,
  });

  return null;
};
