import { getLicensePlateCookie, setLicensePlateCookie } from './cookieHelper';
import { addHours } from 'date-fns';
import { customAlphabet } from 'nanoid';

// Omitting O, 0, I, and 1 for readability
// eslint-disable-next-line @cspell/spellchecker
export const alphabet = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ';
const webLicensePlatePrefix = 'W';

const generateLicensePlateCore = customAlphabet(alphabet, 6);
const generateLicensePlate = () =>
  webLicensePlatePrefix + generateLicensePlateCore();

const hoursToExpire = 4;
const getCookieExpiration = () => {
  const now = new Date();
  return addHours(now, hoursToExpire);
};

/**
 * The "license plate" is intended to be a human readable external session id that
 * customers can provide to support to aid in troubleshooting issues.
 *
 * Attach the license plate to the Datadog global context to make it easy to
 * search for sessions, tracing, and logs.
 */
export const getLicensePlate = (): string => {
  let licensePlate = getLicensePlateCookie();

  if (!licensePlate) {
    licensePlate = generateLicensePlate();
    const cookieExpiration = getCookieExpiration();
    setLicensePlateCookie(licensePlate, cookieExpiration);
  }

  return licensePlate;
};
