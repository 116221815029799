import {
  MidwestColors,
  colors,
  primaryFont,
  secondaryFont,
} from '@thrivent/midwest-shared';
import { DefaultTheme } from 'styled-components';

export * from './globalStyles';

declare module 'styled-components' {
  export interface DefaultTheme {
    font: {
      primary: string;
      secondary: string;
    };

    /**
     * @deprecated These color tokens should no longer be used. Use `midwestColors` instead
     */
    colors: {
      danger_dense: string;
      danger_heavy: string;
      danger_strong: string;
      danger_medium: string;
      danger_weak: string;
      danger_faint: string;

      success_dense: string;
      success_heavy: string;
      success_strong: string;
      success_medium: string;
      success_weak: string;
      success_faint: string;

      warning_dense: string;
      warning_heavy: string;
      warning_strong: string;
      warning_medium: string;
      warning_weak: string;
      warning_faint: string;

      brand_primary_dense: string;
      brand_primary_heavy: string;
      brand_primary_strong: string;
      brand_primary_medium: string;
      brand_primary_weak: string;
      brand_primary_faint: string;
      brand_primary_secondary: string;
      brand_primary_tertiary: string;

      dark_heavy: string;
      dark_strong: string;
      dark_medium: string;
      dark_weak: string;

      light_heavy: string;
      light_strong: string;
      light_medium: string;
      light_weak: string;

      text_primary: string;
      text_secondary: string;
      text_inactive: string;
      text_link_active: string;

      text_primary_dark_bg: string;
      text_secondary_dark_bg: string;
      text_inactive_dark_bg: string;
      text_link_active_dark_bg: string;

      neutral_active: string;
    };

    midwestColors: MidwestColors;
  }
}

const uiLibColors = {
  danger_dense: '#4D1928',
  danger_heavy: '#851033',
  danger_strong: '#A60536',
  danger_medium: '#E30045',
  danger_weak: '#FABED0',
  danger_faint: '#FFEEF3',

  warning_dense: '#4E4618',
  warning_heavy: '#B09311',
  warning_strong: '#DBB924',
  warning_medium: '#F0CB29',
  warning_weak: '#FAEFBE',
  warning_faint: '#FFFCEE',

  success_dense: '#003226',
  success_heavy: '#00755A',
  success_strong: '#12A17F',
  success_medium: '#21DBB0',
  success_weak: '#B4DFD4',
  success_faint: '#E9F9F5',

  brand_primary_dense: '#101E46',
  brand_primary_heavy: '#254BB2',
  brand_primary_strong: '#2F5EDE',
  brand_primary_medium: '#2E66FF',
  brand_primary_weak: '#BFD0FF',
  brand_primary_faint: '#F0F5FF',
  brand_primary_secondary: '#21DBB0',
  brand_primary_tertiary: '#C7AB75',

  dark_heavy: '#11181C',
  dark_strong: '#3D4245',
  dark_medium: '#70787D',
  dark_weak: '#889096',

  light_heavy: '#C1C8CD',
  light_strong: '#D7DBDF',
  light_medium: '#F2F2F2',
  light_weak: '#FFFFFF',

  text_primary: '#11181C',
  text_secondary: '#3D4245',
  text_inactive: '#70787D',
  text_link_active: '#2F5EDE',

  text_primary_dark_bg: '#FFFFFF',
  text_secondary_dark_bg: '#ECEEF0',
  text_inactive_dark_bg: '#F1F2F2',
  text_link_active_dark_bg: '#A1BAFF',

  neutral_active: '#151515',
};

/**
 * @deprecated Use theme from Midwest-Web instead, e.g. import { theme } from '@thrivent/midwest-web';.
 *
 * ```tsx
 *  import { theme } from '@thrivent/midwest-web';
 * ```
 */
export const theme: DefaultTheme = {
  font: {
    primary: primaryFont,
    secondary: secondaryFont,
  },
  colors: uiLibColors,
  midwestColors: colors,
};
