import { getLaunchDarklyBrowserClient } from './getLaunchDarklyBrowserClient';
import { fetchUserContextLD } from './useUserContextLD';
import { QueryClient } from '@tanstack/react-query';
import { logger } from '@thrivent-web/logging-utils';

let flagsEvaluated = false;

// We have to run the client.variation on each flag to send analytics data to DD
export const evaluateLDBrowserFlags = async (
  consumerId: string,
  queryClient: QueryClient
) => {
  if (flagsEvaluated) return;

  try {
    const userContext = await fetchUserContextLD(queryClient, consumerId);
    const client = await getLaunchDarklyBrowserClient(userContext);

    if (!client) {
      return;
    }

    const allFlags = client.allFlags();
    const flagKeys = Object.keys(allFlags);

    const results = await Promise.all(
      flagKeys.map(async (flagKey) => {
        const variationResult = await client.variation(flagKey);
        return { flagKey, variationResult };
      })
    );

    flagsEvaluated = true;

    return results;
  } catch (e) {
    logger.error('Failed to evaluate Launch Darkly flags', e);
    return;
  }
};
