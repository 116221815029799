import {
  EPHEMERAL_REDIRECT_COOKIE_NAME,
  POST_LOGIN_RETURN_TO_COOKIE_NAME,
  THRIVENT_LOGGED_IN_COOKIE_NAME,
  THRIVENT_TLD_COOKIE_OPTIONS,
  USER_LAST_ACTIVE_TIME_COOKIE_NAME,
} from '../constants';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';

export const setEphemeralRedirectCookie = () =>
  setCookie(
    EPHEMERAL_REDIRECT_COOKIE_NAME,
    window.location.origin,
    THRIVENT_TLD_COOKIE_OPTIONS
  );

// DO NOT MERGE THIS CHANGE. Troubleshooting ephemeral environment
export const deleteEphemeralRedirectCookie = () =>
  deleteCookie(EPHEMERAL_REDIRECT_COOKIE_NAME, THRIVENT_TLD_COOKIE_OPTIONS);

export const setThriventLoggedInCookie = () =>
  setCookie(
    THRIVENT_LOGGED_IN_COOKIE_NAME,
    'true',
    THRIVENT_TLD_COOKIE_OPTIONS
  );

export const deleteThriventLoggedInCookie = () =>
  deleteCookie(THRIVENT_LOGGED_IN_COOKIE_NAME, THRIVENT_TLD_COOKIE_OPTIONS);

export const setPostLoginReturnToCookie = (returnTo: string) =>
  setCookie(POST_LOGIN_RETURN_TO_COOKIE_NAME, encodeURIComponent(returnTo));

export const getPostLoginReturnToCookie = () =>
  getCookie(POST_LOGIN_RETURN_TO_COOKIE_NAME);

export const deletePostLoginReturnToCookie = () =>
  deleteCookie(POST_LOGIN_RETURN_TO_COOKIE_NAME);

export const setUserLastActiveTimeCookie = (userLastActiveTime: number) =>
  setCookie(
    USER_LAST_ACTIVE_TIME_COOKIE_NAME,
    userLastActiveTime,
    THRIVENT_TLD_COOKIE_OPTIONS
  );

export const getUserLastActiveTimeCookie = () =>
  getCookie(USER_LAST_ACTIVE_TIME_COOKIE_NAME);

export const deleteUserLastActiveTimeCookie = () =>
  deleteCookie(USER_LAST_ACTIVE_TIME_COOKIE_NAME);
